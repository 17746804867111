import React from 'react';
import Styled from './Loader.style';
import Typography from '../Typography';

interface LoaderProps {
  text?: string;
}

export const Loader: React.FC<LoaderProps> = (props) => {
  return (
    <Styled.Wrap>
      {props.text && (
        <Typography
          size="p"
          text={props.text}
          style={{ marginBottom: '0px' }}
        />
      )}
      <Styled.Loader>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Styled.Loader>
    </Styled.Wrap>
  );
};

export default Loader;
