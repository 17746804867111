import styled, { keyframes } from 'styled-components';

const introSubmenuAnimation = keyframes`
 to {
  opacity:1;
  transform:translate(0)
}
`;

const Wrap = styled.div`
  display: flex;
  transition: all 0.1s ease-out;
  flex-direction: column;
  padding: 24px;
  position: fixed;
  height: 100vh;
`;
const Logo = styled.div`
  justify-content: center;
  display: flex;
  width: 100%;
  padding-bottom: 24px;
`;

const Nav = styled.div`
  margin-bottom: auto;
  a {
    text-decoration: none;
  }
`;
const Line = styled.hr`
  background: rgba(216, 216, 216, 0.22);
  border: 1px solid rgba(216, 216, 216, 0.22);
  width: 100%;
  padding: 0 16px;
`;
const NavElement = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 12px 16px;
  color: white;
  cursor: pointer;
  border-radius: 6px;
  margin-bottom: 16px;
  transition: all 200ms ease-in-out;
  z-index: 1;
  &:hover {
    background-color: #1090b8;
    transition: all 200ms ease-in-out;
  }
  &:focus {
    background-color: #1090b8;
    transition: all 200ms ease-in-out;
  }
  &.active {
    background-color: #1090b8;
    transition: all 200ms ease-in-out;
  }
`;
const Arrow = styled.div`
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  margin-right: 1.25rem;
  margin-left: auto;
  height: 24px;
  &.transform {
    transform: translate(0, 0) rotate(180deg) skew(0) skewY(0) scaleX(1)
      scaleY(1);
  }
`;
const SubNav = styled.ul`
  position: relative;
  border-radius: 0.75rem;

  list-style: none;
  margin-bottom: 16px;
  padding: 0;
  transition-property: height;
  transition-duration: 2s;
  li {
    list-style-type: none;
    position: relative;
    margin-bottom: 0.25rem;
    display: flex;
    height: 50px;
    align-items: center;
    border-radius: 0.75rem;
    opacity: 1;
    color: #ffffffb3;
    cursor: pointer;
    &.active-subnav {
      color: white;
    }
    &:hover {
      color: white;
    }
    &:nth-child(1) {
      opacity: 0;
      transform: translate(50px);
      animation: 0.2s ${introSubmenuAnimation} ease-in-out 0.33333s;
      animation-fill-mode: forwards;
      animation-delay: 0.1s;
    }
    &:nth-child(2) {
      opacity: 0;
      transform: translate(50px);
      animation: 0.2s ${introSubmenuAnimation} ease-in-out 0.33333s;
      animation-fill-mode: forwards;
      animation-delay: 0.2s;
    }
    &:nth-child(3) {
      opacity: 0;
      transform: translate(50px);
      animation: 0.2s ${introSubmenuAnimation} ease-in-out 0.33333s;
      animation-fill-mode: forwards;
      animation-delay: 0.3s;
    }
    &:nth-child(4) {
      opacity: 0;
      transform: translate(50px);
      animation: 0.2s ${introSubmenuAnimation} ease-in-out 0.33333s;
      animation-fill-mode: forwards;
      animation-delay: 0.4s;
    }
    &:nth-child(5) {
      opacity: 0;
      transform: translate(50px);
      animation: 0.2s ${introSubmenuAnimation} ease-in-out 0.33333s;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;
    }
    &:nth-child(6) {
      opacity: 0;
      transform: translate(50px);
      animation: 0.2s ${introSubmenuAnimation} ease-in-out 0.33333s;
      animation-fill-mode: forwards;
      animation-delay: 0.6s;
    }
  }
`;
const Selector = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  label {
    color: white;
    padding-bottom: 8px;
  }
  select {
    padding: 8px;
    border-radius: 6px;
    font-size: 16px;
  }
`;
export default {
  Wrap,
  Logo,
  Line,
  Nav,
  NavElement,
  SubNav,
  Arrow,
  Selector,
};
