import * as React from 'react';

const bell = (props: any) => {
  const { colorPrimary } = props;
  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(9.510730, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M13.4964234,26.9928469 C13.4964234,19.833894 16.3402998,12.9681734 21.4024484,7.9060249 C26.4645969,2.84387637 33.3303175,0 40.4892703,0 C47.6482232,0 54.5138538,2.84387637 59.5759124,7.9060249 C64.6384208,12.9681734 67.4821172,19.833894 67.4821172,26.9928469 C67.4821172,58.4845016 80.9785407,67.4821172 80.9785407,67.4821172 L0,67.4821172 C0,67.4821172 13.4964234,58.4845016 13.4964234,26.9928469 Z"></path>
        <path d="M32.8412971,85.4773485 C33.5943975,86.8467856 34.7015541,87.9890329 36.0466976,88.784872 C37.392291,89.5802612 38.9263845,90 40.4892703,90 C42.0526061,90 43.5866995,89.5802612 44.9322929,88.784872 C46.2774365,87.9890329 47.3845931,86.8467856 48.1372436,85.4773485"></path>
      </g>
    </g>
  );
};
export default bell;
