import * as React from 'react';

const box = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M25,0 L10,0 C7.34785,0 4.8043,1.05355 2.92895,2.92895 C1.05355,4.8043 0,7.34785 0,10 L0,25"></path>
        <path d="M90,25 L90,10 C90,7.34785 88.9465,4.8043 87.071,2.92895 C85.1955,1.05355 82.652,0 80,0 L65,0"></path>
        <path d="M0,65 L0,80 C0,82.652 1.05355,85.1955 2.92895,87.071 C4.8043,88.9465 7.34785,90 10,90 L25,90"></path>
        <path d="M65,90 L80,90 C82.652,90 85.1955,88.9465 87.071,87.071 C88.9465,85.1955 90,82.652 90,80 L90,65"></path>
      </g>
    </g>
  );
};
export default box;
