import axios, { AxiosResponse } from 'axios';
import { getMicroServiceUrl } from '../helpers';

export function logIn({ username, password }) {
  return (dispatch) => {
    dispatch(logInPending());
    return axios
      .post(`${getMicroServiceUrl('sponsor-portal')}login`, {
        username,
        password,
      })
      .then((resp) => {
        dispatch(logInFulfilled(resp));
      })
      .catch((error) => {
        dispatch(logInRejected(error));
      });
  };
}

export const logInPending = () => {
  return {
    type: 'LOG_IN_PENDING',
  };
};
export const logInFulfilled = (resp) => {
  return {
    type: 'LOG_IN_FULFILLED',
    payload: resp,
  };
};
export const logInRejected = (resp) => {
  return {
    type: 'LOG_IN_REJECTED',
    payload: resp,
  };
};

export function register({ email, password, company }) {
  return (dispatch) => {
    dispatch(registerPending());
    return axios
      .post(`${getMicroServiceUrl('sponsor-portal')}registration`, {
        email,
        password,
        company,
      })
      .then((resp) => {
        dispatch(registerFulfilled(resp));
      })
      .catch((error) => {
        dispatch(registerRejected(error));
      });
  };
}

export const registerPending = () => {
  return {
    type: 'REGISTER_PENDING',
  };
};
export const registerFulfilled = (resp) => {
  return {
    type: 'REGISTER_FULFILLED',
    payload: resp,
  };
};
export const registerRejected = (resp) => {
  return {
    type: 'REGISTER_REJECTED',
    payload: resp,
  };
};

export const logOut = () => {
  return (dispatch) => {
    return axios
      .get(`${getMicroServiceUrl('sponsor-portal')}logout`)
      .then((resp) => {
        localStorage.removeItem('swsmUser');
        localStorage.removeItem('user');
        localStorage.removeItem('metadata');
        localStorage.removeItem('campaignId');
        localStorage.removeItem('type');
        dispatch(clearUserdata(resp));
      })
      .catch((error) => {
        localStorage.removeItem('swsmUser');
        localStorage.removeItem('metadata');
        localStorage.removeItem('user');
        localStorage.removeItem('campaignId');
        localStorage.removeItem('type');
      });
  };
};
export const clearUserdata = (resp) => {
  return {
    type: 'CLEAR_USERDATA',
    payload: resp,
  };
};

export const resetPasswordRequest = (oldPassword, newPassword) => {
  return {
    type: 'RESET_PASSWORD',
    payload: axios.post(
      `${getMicroServiceUrl('sponsor-portal')}password/reset`,
      {
        oldPassword,
        newPassword,
      }
    ),
  };
};

export const resetPasswordRequestRepeat = () => {
  return {
    type: 'RESET_PASSWORD_REPEAT',
  };
};

export const resetPasswordUpdate = (password) => {
  return (
    dispatch: (arg0: {
      type: string;
      payload: Promise<AxiosResponse<any, any>>;
    }) => any
  ) => {
    const response = dispatch({
      type: 'RESET_PASSWORD_UPDATE',
      payload: axios.post(`${getMicroServiceUrl('user')}reset/password`, {
        password,
      }),
    });

    response
      .then((res: any) => {
        console.log(res);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
};
