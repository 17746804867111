import { connect } from 'react-redux';
import { updateBusiness } from '../../actions/updateBusiness';
import { getUser } from '../../actions/user';
import { AppState } from '../../interfaces';
import Credentials from './Credentials';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    business: state.updateBusinessReducer,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBusiness: (data, onSuccess, onFailed) =>
      dispatch(updateBusiness(data, onSuccess, onFailed)),
    getUser: () => dispatch(getUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Credentials);
