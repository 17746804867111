import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Input,
  Button,
  Card,
  Typography,
  Notification,
} from '../../../components';
import { Row, Col } from 'react-styled-flexboxgrid';

interface CreateUserProps {
  admin: any;
  createAdmin: Function;
  getBusinessUser: Function;
}

export const CreateUser: React.FC<CreateUserProps> = (props) => {
  const { admin, createAdmin, getBusinessUser } = props;

  const { error, done, loading } = admin.createAdmin.status;

  const CreateBannerSchema = Yup.object().shape({
    company: Yup.string()
      .required('Required')
      .max(64, 'Maximum allowed is 64 characters.'),
    email: Yup.string().required('Required'),
  });
  const [isAlertVisible, setIsAlertVisible] = React.useState(false);

  const handleButtonClick = () => {
    setIsAlertVisible(true);

    setTimeout(() => {
      setIsAlertVisible(false);
    }, 3000);
  };

  const formik = useFormik({
    initialValues: {
      company: '',
      email: '',
    },
    validationSchema: CreateBannerSchema,
    onSubmit: (values, { setSubmitting }) => {
      createAdmin(values);
      setSubmitting(false);
      handleButtonClick();

      setTimeout(() => {
        getBusinessUser();
      }, 300);
    },
  });
  return (
    <Card style={{ marginBottom: '24px' }}>
      <Typography size="2" text="Create user" />
      <form onSubmit={formik.handleSubmit}>
        <Row>
          <Col sm={12} xs={12} md={5}>
            <Input
              id="company"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              label="Company name"
              placeholder="Company name"
              style={{ marginBottom: '8px' }}
              value={formik.values.company}
              error={
                formik.errors.company && formik.touched.company
                  ? formik.errors.company
                  : undefined
              }
            />
          </Col>
          <Col sm={12} xs={12} md={5}>
            <Input
              id="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              label="Email"
              placeholder="Email"
              style={{ marginBottom: '8px' }}
              value={formik.values.email}
              error={
                formik.errors.email && formik.touched.email
                  ? formik.errors.email
                  : undefined
              }
            />
          </Col>
          <Col
            sm={12}
            xs={12}
            md={2}
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginTop: '8px',
            }}
          >
            <Button disabled={formik.isSubmitting || loading} type="submit">
              Create business
            </Button>
          </Col>
          {error && isAlertVisible ? (
            <Col md={12} sm={12} xs={12}>
              <Notification isClose title={error} theme="Error" />
            </Col>
          ) : null}
          {done && isAlertVisible ? (
            <Col md={12} sm={12} xs={12}>
              <Notification
                isClose
                title={'Success, your stall has been created.'}
                theme="Success"
              />
            </Col>
          ) : null}
        </Row>
      </form>
    </Card>
  );
};

export default CreateUser;
