import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const updateBusiness = (data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'UPDATE_BUSINESS',
      payload: axios.post(
        `${getMicroServiceUrl('sponsor-portal')}business/update`,
        data,
        { headers: { 'Content-type': 'multipart/form-data' } }
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const deleteCompanyLogo = () => {
  return {
    type: 'DELETE_BUSINESS_LOGO',
    payload: axios.delete(
      `${getMicroServiceUrl('sponsor-portal')}business/deletelogo`
    ),
  };
};
