import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const createAdmin = (credentials) => {
  return {
    type: 'CREATE_ADMIN',
    payload: axios.post(
      `${getMicroServiceUrl('sponsor-portal')}admin/createUser`,
      credentials
    ),
  };
};

export const getBusinessUser = () => {
  return {
    type: 'GET_BUSINESS_USER',
    payload: axios.get(
      `${getMicroServiceUrl('sponsor-portal')}admin/listUsers`
    ),
  };
};

export const switchUser = (email) => {
  return {
    type: 'SWITCH_USER',
    payload: axios.get(
      `${getMicroServiceUrl(
        'sponsor-portal'
      )}user/profile?_switch_user=${email}`
    ),
  };
};

export const exitUser = () => {
  return {
    type: 'EXIT_USER',
    payload: axios.get(
      `${getMicroServiceUrl('sponsor-portal')}user/profile?_switch_user=_exit`
    ),
  };
};
