import styled from 'styled-components';
import { colors } from '../../assets/variable';
const TopNav = styled.div`
  height: 70px;
  position: fixed;
  flex: 1;
  transition: all 0.1s ease-out;
  display: flex;
  top: 0;
  right: 0;
  background: #f1f5f9;
  z-index: 10001;
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
  padding: 0 24px;
`;
const NavMenu = styled.ul`
  list-style-type: none;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  align-items: center;
  display: flex;
  width: 100%;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
  & > li {
    &.hamburger {
      cursor: pointer;
      margin-right: 16px;
      transition: all 100ms ease-in-out;
      &:hover {
        scale: 1.1;
        transition: all 100ms ease-in-out;
      }
    }
  }
`;
const Avatar = styled.div`
  clip-path: circle(50% at 50% 50%);
  background: #d2dae4;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FullScreen = styled.div`
  cursor: pointer;
  height: 70px;
  line-height: 70px;
  padding-right: 16px;
  transition: all 100ms ease-in-out;
  &:hover {
    scale: 1.1;
    transition: all 100ms ease-in-out;
  }
`;
const NavContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const Dropdown = styled.ul`
  background-color: #fff;
  position: absolute;
  top: 80px;
  transition: all 0.3s linear;
  z-index: 8;
  box-shadow: 0 0 2px 2px rgb(241, 245, 249);
  opacity: 0;
  transform: translateY(30px);
  visibility: hidden;
  top: 73px;
  list-style-type: none;
  right: 16px;
  width: 290px;
  border-radius: 6px;
  padding-inline-start: 0px;
  & > .top {
    border-bottom: 1px solid black;
    padding-bottom: 8px;
    cursor: default;
    div {
      overflow: hidden; /* make sure it hides the content that overflows */
      white-space: nowrap; /* don't break the line */
      text-overflow: ellipsis; /* give the beautiful '...' effect */
      width: 250px;
    }
    &:hover {
      color: black;
    }
  }
  &.active {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
  }
  &:before {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: '';
    height: 0;
    right: 18px;
    position: absolute;
    top: -7px;
    width: 0;
    z-index: 2;
    border-bottom: 7px solid #fff;
  }
  li {
    cursor: pointer;
    font-size: 16px;
    padding: 16px 20px;
    &:hover {
      color: ${colors.action};
    }
  }
`;
export default { TopNav, NavMenu, Avatar, FullScreen, NavContainer, Dropdown };
