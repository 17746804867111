import * as React from 'react';
import 'react-dates/initialize';
import { DayPickerRangeController } from 'react-dates';
import moment from 'moment';
import Styled from './CustomDatePicker.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Button, Icon, Checkbox, Select, Loader } from '../index';
import store from 'store2';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { getFeature } from '../../helpers';
interface CustomDatePickerProps {
  metadata: any;
  campaign: any;
  setMeta: Function;
  getAllCampaigns: Function;
  setPickerPeriod: Function;
  children: any;
  user: any;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = (props) => {
  const {
    metadata,
    campaign,
    setMeta,
    getAllCampaigns,
    setPickerPeriod,
    user,
  } = props;
  const { initialDate, startDate, endDate } = metadata;
  const [focusedInput, setFocuseInput] = React.useState(null);
  const [isInitialdate, setInitialdate] = React.useState(initialDate);
  const [isStartDate, setStartDate] = React.useState(startDate);
  const userData = _.get(user, 'data');
  const getStorage = store('campaignId');
  const getType = store('dataType');

  const [showSet, setSettings] = React.useState(false);
  const [isType, setType] = React.useState(getType ? getType : 'region');
  const campaignLoading = _.get(campaign, 'getCampaign.status.loading');

  const [campaignId, setCampaignId] = React.useState(
    getStorage ? getStorage : null
  );

  const [isEndDate, setEndDate] = React.useState(
    endDate || moment().subtract(1, 'days').format('YYYY-MM-DD')
  );

  const getInitialDate = _.get(metadata, 'initialDate');
  const metaSlug = _.get(props, 'metadata.regionSlug');
  const currentRegion = getFeature(userData, metaSlug);
  const hasCampaigns =
    currentRegion.length > 0 &&
    currentRegion[0].features.find((o) => o.name === 'CREATE_CAMPAIGNS');

  React.useEffect(() => {
    if (hasCampaigns) {
      getAllCampaigns();
    }
    if (getStorage) {
      store('dataType', 'campaign');
    } else {
      store('dataType', 'region');
    }

    setEndDate(endDate);
    setStartDate(startDate);
    setInitialdate(getInitialDate);
  }, [metadata.regionSlug]);

  const location = useLocation();
  const pathname = location.pathname.substring(1);

  const campaignData = _.get(campaign, 'getCampaign.data');
  const campaignLength = _.get(campaignData, 'length');

  const handleDateFilterChange = (e) => {
    const { value } = e.target;
    setPickerPeriod(value); //call action

    switch (value) {
      case 'today':
        setStartDate(moment().format('YYYY-MM-DD'));
        setEndDate(moment().format('YYYY-MM-DD'));
        return;

      case 'everything':
        setStartDate(moment(getInitialDate).format('YYYY-MM-DD'));
        setEndDate(moment().endOf('day').format('YYYY-MM-DD'));
        return;

      case 'yesterday':
        setStartDate(
          moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DD')
        );
        setEndDate(
          moment().endOf('day').subtract(1, 'days').format('YYYY-MM-DD')
        );

        return;

      case 'this_week':
        setStartDate(moment().startOf('week').format('YYYY-MM-DD'));
        setEndDate(moment().endOf('day').format('YYYY-MM-DD'));

        return;

      case 'last_week':
        setStartDate(
          moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD')
        );
        setEndDate(
          moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
        );

        return;

      case 'this_month':
        setStartDate(moment().startOf('month').format('YYYY-MM-DD'));
        setEndDate(moment().endOf('day').format('YYYY-MM-DD'));

        return;

      case 'last_month':
        setStartDate(
          moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD')
        );
        setEndDate(
          moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD')
        );

        return;

      default:
        return false;
    }
  };

  const onClickApply = () => {
    setMeta(false, isStartDate, isEndDate);
    setSettings(!showSet);
  };

  const onFocusChange = (focusedInput) => {
    setFocuseInput(!focusedInput ? 'startDate' : focusedInput);
  };

  const onDatesChange = (startDate, endDate) => {
    setStartDate(startDate.format('YYYY-MM-DD'));
    setEndDate(
      endDate ? endDate.format('YYYY-MM-DD') : startDate.format('YYYY-MM-DD')
    );

    if (
      (isStartDate !== startDate.format('YYYY-MM-DD') ||
        (endDate && isEndDate !== endDate.format('YYYY-MM-DD'))) &&
      metadata.datePickerPeriod !== 'custom'
    ) {
      setPickerPeriod('custom');
    }
  };

  const dateRange = (day) => {
    return !day.isBetween(initialDate, moment().endOf('day'));
  };

  const renderFilterDropdown = () => {
    return _.map(campaignData, (el) => {
      return { label: el.name, value: el.id };
    });
  };

  const setCampaign = (e) => {
    setCampaignId(e.target.value);

    store('campaignId', e.target.value);
    store('dataType', 'campaign');
    setType('campaign');
  };

  const renderFilterList = () => {
    return _.map(campaignData, (el, index) => {
      return (
        <Col
          key={index}
          md={6}
          xs={12}
          sm={12}
          style={{ marginBottom: '6px' }}
          onClick={() => {
            store('campaignId', el.id);
            store('dataType', 'campaign');
            setCampaignId(el.id);
            setType('campaign');
          }}
        >
          <Checkbox
            id={el.id}
            name={el.id}
            style={{ width: '100px' }}
            theme="rounded"
            checked={el.id === campaignId}
            onChange={() => {
              setCampaignId(el.id);
              store('campaignId', el.id);
              store('dataType', 'campaign');
              setType('campaign');
            }}
          >
            <span
              style={{
                whiteSpace: 'nowrap',
                width: '10px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {el.name}
            </span>
          </Checkbox>
        </Col>
      );
    });
  };

  const options = [
    { value: 'custom', label: 'Custom' },
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'this_week', label: 'This week' },
    { value: 'last_week', label: 'Last week' },
    { value: 'this_month', label: 'This month' },
    { value: 'last_month', label: 'Last month' },
    { value: 'everything', label: 'Everything' },
  ];

  return pathname.includes('campaigns/') ? null : (
    <Styled.Wrap>
      {props.children}
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          setSettings(!showSet);
          onFocusChange('endDate');
        }}
      >
        <Icon icon="settings" size={30} />
      </div>
      <Styled.Overlay className={showSet ? 'active' : ''} />
      <Styled.Settings className={showSet ? 'active' : ''}>
        <div
          style={{ marginBottom: '32px', cursor: 'pointer' }}
          onClick={() => setSettings(!showSet)}
        >
          <Icon icon="times" size={30} />
        </div>
        {campaignLoading ? (
          <Loader />
        ) : (
          <Styled.SettingsWrap className={showSet ? 'active' : ''}>
            <DayPickerRangeController
              onDatesChange={({ startDate, endDate }) =>
                onDatesChange(startDate, endDate)
              } // PropTypes.func.isRequired,
              onFocusChange={(focusedInput) => onFocusChange(focusedInput)} // PropTypes.func.isRequired,
              focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
              startDate={moment(isStartDate)}
              endDate={isEndDate ? moment(isEndDate) : null}
              isOutsideRange={dateRange}
              minimumNights={0}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel={true}
              initialVisibleMonth={() => moment(isStartDate)}
            />
            <Styled.Info>
              <Row>
                <Col xs={12} sm={12} md={12}>
                  <Select
                    value={metadata.datePickerPeriod}
                    onChange={handleDateFilterChange}
                    className="form-control"
                    options={options}
                    name="filterSelectDate"
                    id="filterSelectDate"
                  />
                </Col>
              </Row>
            </Styled.Info>
            {location.pathname.includes('myshop/') ? null : (
              <Row>
                <Col md={6} xs={12} sm={12}>
                  <Checkbox
                    name="region"
                    theme="rounded"
                    id="region"
                    onChange={() => {
                      setCampaignId(null);
                      store('campaignId', null);
                      store('dataType', 'region');
                      setType('region');
                    }}
                    checked={isType === 'region'}
                  >
                    National
                  </Checkbox>
                </Col>
                <Col md={6} xs={12} sm={12}>
                  <Checkbox
                    name="campaign"
                    theme="rounded"
                    id="campaign"
                    onChange={() => {
                      setCampaignId(null);
                      store('campaignId', null);
                      store('dataType', 'campaign');
                      setType('campaign');
                    }}
                    checked={isType === 'campaign' && campaignId === null}
                  >
                    All campaigns
                  </Checkbox>
                </Col>
              </Row>
            )}
            {location.pathname.includes('myshop/') ? null : (
              <div style={{ marginBottom: '32px' }}>
                {campaignLength > 10 ? (
                  <Row>
                    {' '}
                    <Col md={12} sm={12} xs={12} style={{ marginTop: '16px' }}>
                      <Select
                        label="Campaigns"
                        placeholder="Select your campaign"
                        value={campaignId}
                        onChange={setCampaign}
                        options={renderFilterDropdown()}
                        name="selectCamapign"
                        id="selectCamapign"
                      />
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col md={12} xs={12} sm={12}>
                      <p style={{ textDecoration: 'underline' }}>Campaigns</p>
                    </Col>
                    {renderFilterList()}
                  </Row>
                )}
              </div>
            )}

            <Row style={{ marginTop: 'auto', marginBottom: '8px' }}>
              <Col
                md={12}
                xs={12}
                sm={12}
                style={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <Button
                  style={{ height: '30px', marginRight: '16px' }}
                  theme="WHITE"
                  onClick={() => onClickApply()}
                >
                  Apply
                </Button>
                <Button
                  style={{ height: '30px' }}
                  onClick={() => setSettings(!showSet)}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Styled.SettingsWrap>
        )}
      </Styled.Settings>
    </Styled.Wrap>
  );
};

export default CustomDatePicker;
