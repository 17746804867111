import * as React from 'react';

const coins = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(4.995598, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M27.0107851,54.0215701 C41.9282564,54.0215701 54.0214351,41.9283915 54.0214351,27.0107851 C54.0214351,12.0931336 41.9282564,0 27.0107851,0 C12.0931336,0 0,12.0931336 0,27.0107851 C0,41.9283915 12.0931336,54.0215701 27.0107851,54.0215701 Z"></path>
        <path d="M72.4337869,37.6800452 C76.6893361,39.2665686 80.4762482,41.9004803 83.4438331,45.3385031 C86.4118682,48.7765259 88.465138,52.9069251 89.4132166,57.3488487 C90.3617453,61.7903221 90.1749207,66.3992624 88.8698496,70.7493493 C87.5652287,75.0998864 85.184228,79.0506639 81.9478858,82.2370362 C78.7115435,85.4234085 74.7238513,87.7422844 70.3539564,88.9789282 C65.9836114,90.2160221 61.3724202,90.330818 56.9462529,89.3134117 C52.5200856,88.2955553 48.4216492,86.1783599 45.0304451,83.1572036 C41.639241,80.1360473 39.0647981,76.3086191 37.5448562,72.0287602"></path>
        <polyline points="22.5089876 18.00719 27.0107851 18.00719 27.0107851 36.0143801"></polyline>
        <polyline points="66.2213063 53.4813544 69.3725646 56.6776307 56.6774956 69.3726996"></polyline>
      </g>
    </g>
  );
};
export default coins;
