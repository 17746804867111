import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const getAnalytics = (bannerId, startDate, endDate) => {
  return {
    type: 'GET_AD_ANALYTICS',
    payload: axios.post(
      `${getMicroServiceUrl('sponsor-portal')}marketplace/banner/aa`,
      {
        adId: bannerId,
        dateStart: startDate,
        dateEnd: endDate,
      }
    ),
  };
};
