import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Card, Typography, CustomDatePicker } from '../../components/index';
import { useLocation } from 'react-router-dom';

interface WrapProps {
  title?: string;
}

export const Wrap: React.FC<WrapProps> = (props) => {
  const { title } = props;
  const location = useLocation();
  const pathname = location.pathname.substring(1);

  return (
    <Card
      style={{
        padding: '16px 32px',
        height: 'auto',
        marginBottom: '16px',
        marginTop: '16px',
      }}
    >
      <Row>
        <Col
          md={6}
          sm={6}
          xs={12}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Typography
            size="1"
            text={title}
            style={{
              margin: '0',
              flex: '1',
            }}
          />
        </Col>
        <Col
          md={6}
          sm={6}
          xs={12}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          {pathname === 'campaigns' ||
          pathname === 'utm-branding' ||
          pathname === 'myshop' ||
          pathname === 'my-profile' ||
          pathname === 'downloads' ||
          pathname.includes('campaigns/') ? null : (
            <CustomDatePicker />
          )}
        </Col>
      </Row>{' '}
    </Card>
  );
};

export default Wrap;
