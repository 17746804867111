import * as React from 'react';

const link = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 5.045150)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M35.9488064,49.480398 C37.892526,52.0785106 40.3720695,54.2281422 43.2195394,55.7840229 C46.0665567,57.3399037 49.2154276,58.2649241 52.452094,58.4966317 C55.6883078,58.7283394 58.9367405,58.2613037 61.9765459,57.1272033 C65.0168038,55.9935555 67.7773834,54.2186386 70.0713797,51.9241897 L83.6480003,38.347569 C87.7698624,34.0797583 90.0507346,28.3639558 89.9991435,22.4310631 C89.9475523,16.4981703 87.5675707,10.8228261 83.3723949,6.62746932 C79.1772192,2.43211252 73.5017392,0.0524024508 67.5687559,0.000856547742 C61.6357727,-0.0507346107 55.9200154,2.23004715 51.652431,6.35186392 L43.8685018,14.0905377"></path>
        <path d="M54.0509673,40.4293175 C52.1077003,37.8312049 49.6281569,35.6811207 46.780687,34.125421 C43.933217,32.5697213 40.7843462,31.6446104 37.5481324,31.4128575 C34.3115565,31.1810593 31.063169,31.6480046 28.0230921,32.7820597 C24.9830152,33.9161148 22.2223904,35.6906696 19.9284846,37.9855258 L6.35186392,51.5621465 C2.23004715,55.8297309 -0.0507346107,61.5454882 0.000856547742,67.4784714 C0.0524024508,73.4114546 2.43211252,79.0864821 6.62746932,83.2821104 C10.8228261,87.4772862 16.4981703,89.8572678 22.4310631,89.908859 C28.3639558,89.9599976 34.0798036,87.6795778 38.3473428,83.5577158 L46.0860165,75.819042"></path>
      </g>
    </g>
  );
};
export default link;
