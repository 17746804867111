import Login from './Login';
import { connect } from 'react-redux';
import { logIn } from '../../../actions/auth';
import { AppState } from '../../../interfaces';

const mapStateToProps = (state: AppState) => ({
  auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
  logIn: (credentials) => dispatch(logIn(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
