import { isFinite as _isFinite } from 'lodash';

export const numberFormatter = (
  number,
  fractionDigits = 0,
  thousandSeperator = ',',
  fractionSeperator = '.'
) => {
  if ((number !== 0 && !number) || !_isFinite(number)) return number;
  const frDigits = _isFinite(fractionDigits)
    ? Math.min(Math.max(fractionDigits, 0), 7)
    : 0;
  const num = number.toFixed(frDigits).toString();

  const parts = num.split('.');
  const digits = parts[0].split('').reverse();
  let sign = '';
  if (num < 0) {
    sign = digits.pop();
  }
  const final: any = [];
  let pos = 0;

  while (digits.length > 1) {
    final.push(digits.shift());
    pos++;
    if (pos % 3 === 0) {
      final.push(thousandSeperator);
    }
  }
  final.push(digits.shift());
  return `${sign}${final.reverse().join('')}${
    frDigits > 0 ? fractionSeperator : ''
  }${frDigits > 0 && parts[1] ? parts[1] : ''}`;
};

export const prettifyIfNumber = (
  stringOrNumber: string | number
): string | number => {
  if (typeof stringOrNumber === 'number') {
    return numberFormatter(stringOrNumber);
  }
  return stringOrNumber;
};

export const capitalizeFirstLetter = (string: any): any => {
  if (typeof string === 'string' || string instanceof String) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return string;
};
