import store from 'store2';
export const initialState = {};

let stateFromStore = initialState;
if (store('genericPage')) {
  stateFromStore = store('genericPage');
}

export const toConstName = (string: string): string => {
  if (!string) {
    return '';
  }
  return string.toUpperCase().replace('-', '_');
};

const genericPageReducer = (state = stateFromStore, action) => {
  const { endpointName } = action;
  const endpointAction = toConstName(action.endpointName);

  switch (action.type) {
    case 'CLEAR_USERDATA':
      return {
        ...initialState,
      };

    case `GET_${endpointAction}_DATA_PENDING`:
      // console.log('pending...');
      return {
        ...state,
        [endpointName]: {
          status: {
            loading: true,
            error: false,
            done: false,
          },
          data: null,
          meta: null,
        },
      };
    case `GET_${endpointAction}_DATA_FULFILLED`:
      // console.log('fulfilled...');

      return {
        ...state,
        [endpointName]: {
          status: {
            loading: false,
            error: false,
            done: true,
          },
          data: action.payload.data,
          meta: action.payload.config.params,
        },
      };
    case `GET_${endpointAction}_DATA_REJECTED`:
      return {
        ...state,
        [endpointName]: {
          status: {
            loading: false,
            error: action.payload.response.data.detail,
            done: true,
          },
          data: null,
          meta: null,
        },
      };
    default:
      return state;
  }
};

export default genericPageReducer;
