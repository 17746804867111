import * as _ from 'lodash';
import { AuthReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
import { LocalStorage } from 'ttl-localstorage';
import store from 'store2';
import moment from 'moment';

export const initialState: AuthReducer = {
  loginRequest: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

let stateFromStore = initialState.loginRequest.data;
if (store('user')) {
  stateFromStore = store('user');
}

const authReducer = createReducer(initialState, {
  LOG_IN_FULFILLED: (state, action) => {
    const firstSite = moment(action.payload.data.createdAt).format(
      'YYYY-MM-DD'
    );
    const dateMonthBefore = moment().subtract(2, 'month').format('YYYY-MM-DD');
    const lessThanMonthData = moment(dateMonthBefore).isBefore(firstSite);

    const metadata = {
      initialDate: firstSite,
      startDate: lessThanMonthData ? firstSite : dateMonthBefore,
      endDate: moment().endOf('day').format('YYYY-MM-DD'),
      regionSlug:
        action.payload.data.validFeatures.length === 0
          ? null
          : action.payload.data.validFeatures[0].regionSlug,
      datePickerPeriod: 'custom',
      campaignId: 99999999,
    };

    state.loginRequest.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.loginRequest.data = _.get(action, 'payload');

    store('metadata', metadata);
    LocalStorage.put('swsmUser', true, action.payload.data.sessionLifetime);
    store('user', action.payload.data);
    store('campaignId', null);
  },
  LOG_IN_PENDING: (state) => {
    state.loginRequest.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  LOG_IN_REJECTED: (state, action) => {
    state.loginRequest.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default authReducer;
