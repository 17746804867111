export const setPickerPeriod = (period) => {
  return {
    type: 'SET_PICKER_PERIOD',
    period,
  };
};

export const setMeta = (
  regionSlug,
  startDate,
  endDate,
  datePickerPeriod,
  initialDate,
  campaignId,
  focus
) => {
  return {
    type: 'SET_META',
    regionSlug,
    startDate,
    endDate,
    datePickerPeriod,
    initialDate,
    campaignId,
    focus,
  };
};
