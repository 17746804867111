import styled, { css } from 'styled-components';
import { Button } from '../../../../components';

const Back = styled.div`
  a {
    display: flex;
    align-items: center;
    text-decoration: underline;
    transition: all 100ms ease-in-out;
    &:hover {
      transition: all 100ms ease-in-out;
      & > div {
        background-color: #6c6c6c;
        svg {
          stroke: #fff;
        }
      }
    }
  }
  div {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #6c6c6c;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      transform: translate(0, 0) rotate(90deg) skew(0) skewY(0) scaleX(1)
        scaleY(1);
    }
  }
  p {
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 6px;
    color: #6c6c6c;
  }
`;

const AdvBanner = styled.div`
  background: linear-gradient(
    0deg,
    rgba(215, 241, 255, 1) 0%,
    rgba(227, 245, 255, 1) 1%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 500px;
  padding: 16px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 16px;
    width: 300px;
  }
`;
const UtmList = styled.div`
  display: flex;
  text-transform: uppercase;
  font-weight: 600;
  padding: 16px 8px;
  width: 100%;
  .id {
    flex-basis: 5%;
    text-align: left;
  }
  .source {
    flex-basis: 19%;
    text-align: center;
  }
  .term {
    flex-basis: 19%;
    text-align: center;
  }
  .description {
    flex-basis: 19%;
    text-align: center;
  }
  .medium {
    flex-basis: 19%;
    text-align: center;
  }
  .expires {
    flex-basis: 19%;
    text-align: center;
  }
`;
const PreviewBanner = styled.div`
  display: flex;
  font-family: 'Open Sans', sans-serif;
  margin-top: 16px;
  border-radius: 10px;
  overflow: hidden;
  min-height: 309px;
  border: 2px solid rgb(215, 241, 255);
  flex-direction: row;
  width: 100%;
  color: rgb(11, 38, 65);
  background-color: rgb(215, 241, 255);
  ${(props) => {
    switch (props.theme) {
      case 'darkTheme':
        return css`
          color: #fff;
          background-color: rgb(11, 38, 65);
        `;
      default:
        return css`
          color: rgb(11, 38, 65);
          background-color: rgb(215, 241, 255);
        `;
    }
  }};
`;
const Img = styled.div`
  display: flex;
  flex: 1 0 auto;
  /* background-image: url('https://cdn.getwaterfit.co.uk/static/files/images/develop/component/free-products.jpg'); */
  background-size: cover;
  margin: 0px;
  min-height: 309px;
  max-width: 460px;
`;
const TextWrap = styled.div`
  flex-basis: 60%;
  padding: 4em 2em;
`;
const Title = styled.h4`
  font-size: 24px;
  min-height: 62px;
  margin: 0px 0px 10px;
  font-weight: 600;
  padding: 5px 0px;
  font-family: 'Open Sans', sans-serif;
`;

const Description = styled.p`
  font-size: 18px;
  min-height: auto;
  margin: 0px 0px 10px;
  line-height: 1.5;
  font-family: 'Open Sans', sans-serif;
`;
const ButtonWrap = styled(Button)`
  font-family: 'Open Sans', sans-serif;
  display: inline-block;
  border: none;
  padding: 0px;
  margin: 10px 0px 0px;
  color: inherit;
  background: none;
  cursor: pointer;
  appearance: none;
  text-decoration: underline 2px rgb(37, 179, 235);
  text-underline-offset: 8px;
  min-height: 35px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  text-transform: none;
  &:hover {
    background: none;
    border: none;
  }
`;
const Error = styled.div`
  color: red;
  font-size: 14px;
  padding: 4px 10px;
`;
export default {
  Back,
  AdvBanner,
  UtmList,
  ButtonWrap,
  Description,
  Title,
  TextWrap,
  PreviewBanner,
  Img,
  Error,
};
