import * as _ from 'lodash';
import { CategoriesReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: CategoriesReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const categoriesReducer = createReducer(initialState, {
  GET_CATEGORIES_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: _.get(action, 'payload.data.message'),
    };
    state.data = _.get(action, 'payload.data');
  },
  GET_CATEGORIES_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_CATEGORIES_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.detail'),
      done: false,
    };
  },
});

export default categoriesReducer;
