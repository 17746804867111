import { connect } from 'react-redux';
import {
  deleteUtmTag,
  updateUtmTag,
  publishUtmTag,
  getUtmTagList,
} from '../../../../../actions/utmTag';
import UtmList from './UtmList';
import { AppState } from '../../../../../interfaces';

const mapStateToProps = (state: AppState) => {
  return {
    utmTags: state.utmTagReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteUtmTag: (utmTagId) => dispatch(deleteUtmTag(utmTagId)),
    updateUtmTag: (utmTagId, data, onSuccess, onFailed) =>
      dispatch(updateUtmTag(utmTagId, data, onSuccess, onFailed)),
    publishUtmTag: (utmTagId, data, onSuccess, onFailed) =>
      dispatch(publishUtmTag(utmTagId, data, onSuccess, onFailed)),
    getUtmTagList: () => dispatch(getUtmTagList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtmList);
