import * as React from 'react';
import Styled from './ErrorDisplay.style';
import Typography from '../Typography';

interface ErrorDisplayProps {
  text?: string;
}

export const ErrorDisplay: React.FC<ErrorDisplayProps> = (props) => {
  return (
    <Styled.Wrap>
      <Styled.TextWrap>
        <Typography size="1" text="Ooopps!" style={{ marginTop: '0px' }} />
      </Styled.TextWrap>
      <Styled.ImgWrap>
        <img
          src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/error.svg"
          alt="Error"
          width="500px"
        />
      </Styled.ImgWrap>
      <Styled.TextWrap>
        <Typography
          size="3"
          text={props.text ? props.text : 'Something has gone wrong'}
          style={{ marginTop: '0px' }}
        />
      </Styled.TextWrap>
    </Styled.Wrap>
  );
};

export default ErrorDisplay;
