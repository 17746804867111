import styled, { keyframes } from 'styled-components';

const Wrap = styled.div`
  margin-bottom: 16px;
  font-family: 'Open Sans', sans-serif;
  textarea {
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    width: 100%;
    height: 123px;
    resize: none;
    font-family: 'Open Sans', sans-serif;
    margin-top: 6px;
    padding: 8px 16px;
  }
  label {
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 14px;
  }
`;

export default {
  Wrap,
};
