import { connect } from 'react-redux';
import { getCampaigns } from '../../actions/campaigns';
import { AppState } from '../../interfaces';
import Filter from './Filter';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    campaign: state.campaignReducer,
    metadata: state.metadataReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCampaigns: (regionSlug) => dispatch(getCampaigns(regionSlug)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
