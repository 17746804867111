import styled, { css } from 'styled-components';
import { colors } from '../../../../../assets/variable';
const iconStyle = css`
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
const Wrap = styled.div`
  overflow-x: auto;
  .no-wrap {
    white-space: nowrap;
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  .extraClass {
    max-width: 300px;
    text-align: center;
    background-color: #0784ab;
  }
  table {
    thead {
      tr {
        td {
          padding: 0.75rem 1.25rem;
          font-weight: 600;
          border-bottom: 1px solid #0000001f;
          &.no-wrap {
            white-space: nowrap;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 0.75rem 0rem;
          border-bottom: 1px solid #0000001f;
          &:first-child {
            padding-left: 1.25rem;
          }
          &:last-child {
            padding-right: 1.25rem;
          }
        }
        &:last-child {
          td {
            border-bottom: 0;
          }
        }
      }
    }
  }
`;
const NameWrap = styled.div`
  display: flex;
`;
const IconWrap = styled.div`
  ${iconStyle}
  background: rgb(254, 243, 199);
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  p {
    margin: 0;
    &:first-child {
      font-size: 18px;
      font-weight: 600;
    }
    &:last-child {
      font-weight: 400;
    }
  }
`;
const IconDelete = styled.div`
  ${iconStyle}
  background: rgb(255, 216, 216);
  &:hover {
    transition: all 100ms ease-in-out;
    background: rgb(218, 181, 181);
  }
`;
const IconCopy = styled.div`
  ${iconStyle}
  background: rgb(219, 234, 254);
  &:hover {
    transition: all 100ms ease-in-out;
    background: rgb(177, 193, 213);
  }
`;
const IconEdit = styled.div`
  ${iconStyle}
  background: rgb(220, 252, 231);
  &:hover {
    transition: all 100ms ease-in-out;
    background: rgb(186, 218, 197);
  }
`;
const IconPublish = styled.div`
  ${iconStyle}
  background: rgb(252, 231, 243);
  &:hover {
    transition: all 100ms ease-in-out;
    background: rgb(228, 203, 218);
  }
`;
const ValueWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  color: rgb(100, 116, 139);
`;
const ValueItem = styled.div``;

const PaginationWrap = styled.div`
  & > .paginationBttns {
    display: flex;
    width: auto;
    justify-content: flex-end;
    border-radius: 9999px;
    padding-inline-start: 0px;
    background-color: #e9eef5;
    color: #475569;
  }

  li {
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    border-radius: 9999px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
    }
    &:hover {
      background-color: #cbd5e1;
      transition-duration: 0.15s;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    &.paginationDisabled {
      cursor: not-allowed;
      background-color: #e9eef5;
      color: #bfbebe;
    }
    &.paginationActive {
      color: white;
      background-color: ${colors.primary};
      &:hover {
        background-color: ${colors.primary};
      }
    }
  }
`;
export default {
  Wrap,
  NameWrap,
  IconWrap,
  TextWrap,
  IconDelete,
  IconEdit,
  IconPublish,
  IconCopy,
  ValueItem,
  ValueWrap,
  PaginationWrap,
};
