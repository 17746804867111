import styled, { css } from 'styled-components';
import { Button } from '../index';
interface PriceProps {
  price: number;
}
const Card = styled.div<PriceProps>`
  background: white;
  border: 1px solid #0784ab;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  transition: 200ms all ease-in-out;
  &:hover {
    scale: 1.04;
    transition: 200ms all ease-in-out;
  }
  ${(props) => {
    if (props.price === 70) {
      return orangeStyles;
    }
    if (props.price === 40) {
      return redStyles;
    }
  }}
`;
const Name = styled.div`
  font-weight: 600;
  font-size: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;
`;
const Price = styled.div`
  font-weight: 400;
  font-size: 26px;
  color: #0784ab;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
`;
const SubPrice = styled.div`
  color: #0784ab;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;
`;
const FeatureItem = styled.div`
  display: flex;
  padding: 10px 32px 4px 32px;
  div {
    &:first-child {
      flex: 1;
    }
  }
`;
const StyleButton = styled(Button)`
  margin-top: 16px;
`;
const orangeStyles = css`
  border: 1px solid #ff5a0e;
  ${Price} {
    color: #ff5a0e;
  }
  ${SubPrice} {
    color: #ff5a0e;
  }
  ${StyleButton} {
    background: #ff5a0e;
    border-color: #ff5a0e;
    &:hover {
      background-color: #fa6f2e;
      border: 1px solid #fa6f2e;
    }
  }
`;
const redStyles = css`
  border: 1px solid #be004a;
  ${Price} {
    color: #be004a;
  }
  ${SubPrice} {
    color: #be004a;
  }
  ${StyleButton} {
    background: #be004a;
    border-color: #be004a;
    &:hover {
      background-color: #b91f5a;
      border: 1px solid #b91f5a;
    }
  }
`;
export default { Card, Name, Price, FeatureItem, SubPrice, StyleButton };
