import * as React from 'react';

const info = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="7"
      >
        <path d="M45,90 C69.8526,90 90,69.8526 90,45 C90,20.147175 69.8526,0 45,0 C20.147175,0 0,20.147175 0,45 C0,69.8526 20.147175,90 45,90 Z"></path>
        <line x1="45" y1="63" x2="45" y2="45"></line>
        <line x1="45" y1="27" x2="45.045" y2="27"></line>
      </g>
    </g>
  );
};
export default info;
