import * as React from 'react';
const creditCard = (props: any) => {
  const { colorPrimary } = props;
  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M86,18.5 L14,18.5 C9.029435,18.5 5,22.529435 5,27.5 L5,72.5 C5,77.4707 9.029435,81.5 14,81.5 L86,81.5 C90.9707,81.5 95,77.4707 95,72.5 L95,27.5 C95,22.529435 90.9707,18.5 86,18.5 Z"
        stroke={colorPrimary}
        strokeWidth="5"
      ></path>
      <line
        x1="5"
        y1="41"
        x2="95"
        y2="41"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
    </g>
  );
};
export default creditCard;
