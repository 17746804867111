import * as React from 'react';

const eye = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 18.500000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M0,31.5 C0,31.5 13.5,0 45,0 C76.5,0 90,31.5 90,31.5 C90,31.5 76.5,63 45,63 C13.5,63 0,31.5 0,31.5 Z"></path>
        <path d="M45,45 C52.45605,45 58.5,38.95605 58.5,31.5 C58.5,24.04395 52.45605,18 45,18 C37.54395,18 31.5,24.04395 31.5,31.5 C31.5,38.95605 37.54395,45 45,45 Z"></path>
      </g>
    </g>
  );
};
export default eye;
