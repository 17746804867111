import * as React from 'react';

const calendar = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(9.500000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M72,9 L9,9 C4.029435,9 0,13.029435 0,18 L0,81 C0,85.9707 4.029435,90 9,90 L72,90 C76.9707,90 81,85.9707 81,81 L81,18 C81,13.029435 76.9707,9 72,9 Z"></path>
        <line x1="58.5" y1="0" x2="58.5" y2="18"></line>
        <line x1="22.5" y1="0" x2="22.5" y2="18"></line>
        <line x1="0" y1="36" x2="81" y2="36"></line>
        <line x1="22.5" y1="54" x2="22.545" y2="54"></line>
        <line x1="40.5" y1="54" x2="40.545" y2="54"></line>
        <line x1="58.5" y1="54" x2="58.545" y2="54"></line>
        <line x1="22.5" y1="72" x2="22.545" y2="72"></line>
        <line x1="40.5" y1="72" x2="40.545" y2="72"></line>
        <line x1="58.5" y1="72" x2="58.545" y2="72"></line>
      </g>
    </g>
  );
};
export default calendar;
