import * as React from 'react';
import Styled from './Footer.style';
import { Link } from 'react-router-dom';
import { Row, Col, Grid } from 'react-styled-flexboxgrid';
export const Footer: React.FC = () => {
  const date = new Date();

  return (
    <Styled.Wrap>
      <Grid fluid>
        <Row>
          <Col md={6} xs={12} sm={12}>
            {' '}
            <Link
              to="/terms-and-conditions"
              className="introY-bottom"
              target="_blank"
              style={{
                color: 'black',
                textDecoration: 'underline',
                marginRight: '16px',
              }}
              rel="noopener noreferrer"
            >
              Terms and Conditions
            </Link>
            <Link
              to="/privacy-policy"
              className="introY-bottom"
              target="_blank"
              style={{ color: 'black', textDecoration: 'underline' }}
              rel="noopener noreferrer"
            >
              Privacy Policy
            </Link>
          </Col>
          <Col md={6} xs={12} sm={12}>
            {' '}
            <Styled.TextRight>
              {date.getFullYear()} &copy; Copyright. GetWaterFit
            </Styled.TextRight>
          </Col>
        </Row>
      </Grid>
    </Styled.Wrap>
  );
};

export default Footer;
