import styled from 'styled-components';
import { colors } from '../../assets/variable';
const CircleProgress = styled.svg`
  width: 100%;
  height: auto;

  path {
    stroke-linecap: round;
    transition: stroke-dashoffset 0.5s ease 0s;
  }
  .trail {
    stroke: ${colors.c4};
  }
  text {
    fill: ${colors.c5};
    font-size: 22px;
    font-weight: 700;
    dominant-baseline: middle;
    text-anchor: middle;
  }
`;

const Subtext = styled.div`
  padding-top: 10px;
  text-align: center;
  color: #787878;
`;

export default { CircleProgress, Subtext };
