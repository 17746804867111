import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const getDownloadCsvData = (bannerId) => ({
  type: 'GET_DOWNLOAD_CSV',
  payload: axios
    .get(
      `${getMicroServiceUrl(
        'sponsor-portal'
      )}marketplace/banner/optedInUsers/${bannerId}`,
      {
        responseType: 'arraybuffer',
      }
    )
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const filename = response.headers['content-disposition']
        .split('filename=')[1]
        .split('.')[0];
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.csv`); //or any other extension
      document.body.appendChild(link);
      link.click();
    }),
});
