import * as React from 'react';

const settings = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line
        x1="90.5"
        y1="14"
        x2="59"
        y2="14"
        id="Path"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
      <line
        x1="41"
        y1="14"
        x2="9.5"
        y2="14"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
      <line
        x1="90.5"
        y1="50"
        x2="50"
        y2="50"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
      <line
        x1="32"
        y1="50"
        x2="9.5"
        y2="50"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
      <line
        x1="90.5"
        y1="86"
        x2="68"
        y2="86"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
      <line
        x1="50"
        y1="86"
        x2="9.5"
        y2="86"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
      <line
        x1="59"
        y1="5"
        x2="59"
        y2="23"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
      <line
        x1="32"
        y1="41"
        x2="32"
        y2="59"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
      <line
        x1="68"
        y1="77"
        x2="68"
        y2="95"
        stroke={colorPrimary}
        strokeWidth="5"
      ></line>
    </g>
  );
};
export default settings;
