import { connect } from 'react-redux';
import { getBranding } from '../../../actions/branding';
import UTMBranding from './UTMBranding';
import { AppState } from '../../../interfaces';
// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    user: state.userReducer,
    branding: state.brandingReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranding: () => dispatch(getBranding()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UTMBranding);
