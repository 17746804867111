import * as React from 'react';

const upload = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M90,60 L90,80 C90,82.652 88.9465,85.1955 87.071,87.071 C85.1955,88.9465 82.652,90 80,90 L10,90 C7.34785,90 4.8043,88.9465 2.92895,87.071 C1.05355,85.1955 0,82.652 0,80 L0,60"></path>
        <polyline points="70 25 45 0 20 25"></polyline>
        <line x1="45" y1="0" x2="45" y2="60"></line>
      </g>
    </g>
  );
};
export default upload;
