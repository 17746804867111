import React from 'react';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import {
  Login,
  Page404,
  Page500,
  Unauthorized,
  ResetPassword,
  Overview,
  EasyWins,
  Leaks,
  Products,
  Downloads,
  Users,
  Marketplace,
  Profile,
  SingleBanner,
  UTMBranding,
} from '../../views/Pages';
import { PrivateRoute, DefaultLayout } from '../index';
import GenericMarkdown from '../../views/Pages/GenericMarkdown';
import { ThemeProvider } from 'styled-components';
import WebFont from 'webfontloader';
import * as _ from 'lodash';

const theme = {
  flexboxgrid: {
    outerMargin: 1,
    container: {
      sm: 46,
      md: 61,
      lg: 74,
    },
  },
};

const App: React.FC = () => {
  const routes = [
    { path: '/easy-wins', element: <EasyWins /> },
    { path: '/', element: <Overview /> },
    { path: '/leaks', element: <Leaks /> },
    { path: '/users', element: <Users /> },
    { path: '/products', element: <Products /> },
    { path: '/downloads', element: <Downloads /> },
    { path: '/myshop', element: <Marketplace /> },
    { path: '/utm-branding', element: <UTMBranding /> },
    { path: '/myshop/:bannerId', element: <SingleBanner /> },
    { path: '/my-profile', element: <Profile /> },
  ];

  const renderPages = () => {
    return _.map(routes, (el, index) => {
      return <Route key={index} path={el.path} element={el.element} />;
    });
  };

  React.useEffect(() => {
    WebFont.load({
      google: {
        families: ['Open Sans:300,400,500,600,700'],
      },
    });
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          element={
            <PrivateRoute>
              <DefaultLayout />
            </PrivateRoute>
          }
        >
          {renderPages()}
        </Route>
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/forgot-password" element={<ResetPassword />} />
        <Route path="/*" element={<Page404 />} />
        <Route path="/500" element={<Page500 />} />
        <Route path="/terms-and-conditions" element={<GenericMarkdown />} />
        <Route path="/privacy-policy" element={<GenericMarkdown />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
      </Routes>
    </ThemeProvider>
  );
};

export default App;
