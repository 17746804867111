import React from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { Formik } from 'formik';
import * as Yup from 'yup';

import { Button, Input, Notification, Typography } from '../../../components';

interface FormAccountSettingsProps {
  setPreferences: Function;
  errorMessage: string;
  doneMessage: boolean;
}

const FormAccountSettings: React.FC<FormAccountSettingsProps> = (props) => {
  const { setPreferences, errorMessage, doneMessage } = props;
  const [isClicked, setClicked] = React.useState(false);

  if (isClicked) {
    setTimeout(() => {
      setClicked(false);
    }, 4000);
  }
  const AccountSettingsSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Password too short')
      .max(50, 'Password too long'),
    password2: Yup.string().when('password', {
      is: (password) => password !== undefined,
      then: Yup.string()
        .required('Required')
        .oneOf([Yup.ref('password'), null], 'Passwords should match'),
      otherwise: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords should match'
      ),
    }),
  });

  return (
    <div>
      <Typography size="2" text="Reset password" />
      <Typography
        size="p"
        style={{ fontSize: '15px' }}
        text="You can reset your password here. After you submit your new password you will be logged off."
      />
      <Formik
        className="aa"
        initialValues={{
          password: '',
          password2: '',
          passwordOld: '',
        }}
        validationSchema={AccountSettingsSchema}
        onSubmit={(values, { setSubmitting }) => {
          setPreferences(values.passwordOld, values.password);
          setClicked(true);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Row>
              <Col md={12} xs={12} sm={12}>
                <Input
                  label="Old password"
                  name="passwordOld"
                  id="passwordOld"
                  style={{ marginBottom: '8px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.passwordOld}
                  autoComplete="off"
                  theme="true"
                  error={
                    errors.passwordOld && touched.passwordOld
                      ? errors.passwordOld
                      : undefined
                  }
                />
              </Col>
            </Row>
            <Row>
              <Col md={6} xs={12} sm={12}>
                <Input
                  theme="true"
                  name="password"
                  id="password"
                  label="New password"
                  style={{ marginBottom: '8px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  autoComplete="off"
                  error={
                    errors.password && touched.password
                      ? errors.password
                      : undefined
                  }
                />
              </Col>
              <Col md={6} xs={12} sm={12}>
                <Input
                  label="Repeat password"
                  theme="true"
                  name="password2"
                  id="password2"
                  style={{ marginBottom: '8px' }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password2}
                  autoComplete="off"
                  error={
                    errors.password2 && touched.password2
                      ? errors.password2
                      : undefined
                  }
                />
              </Col>
            </Row>

            <Row>
              <Col
                xs={12}
                md={12}
                sm={12}
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '16px',
                  marginBottom: '16px',
                }}
              >
                <Button disabled={isSubmitting} type="submit">
                  Submit
                </Button>
              </Col>
            </Row>
            {doneMessage || errorMessage ? (
              <Row>
                <Col md={12} xs={12} sm={12} style={{ marginTop: '16px' }}>
                  {doneMessage && isClicked && (
                    <Notification
                      isClose
                      title={'Your password hass been reset'}
                      theme="Success"
                    />
                  )}
                  {errorMessage && isClicked && (
                    <Notification isClose title={errorMessage} theme="Error" />
                  )}
                </Col>
              </Row>
            ) : null}
          </form>
        )}
      </Formik>
    </div>
  );
};

export default FormAccountSettings;
