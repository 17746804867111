import * as React from 'react';

const medal = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(6.770022, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M21.6847944,58.5 L1.20979438,23.13 C0.326894377,21.603915 -0.0883206235,19.852155 0.0156743765,18.09216 C0.119669377,16.332165 0.738284377,14.64147 1.79479438,13.23 L9.03979438,3.6 C9.87809938,2.482245 10.9651644,1.575 12.2148594,0.950175 C13.4645544,0.325305 14.8425894,0 16.2397944,0 L70.2399744,0 C71.6367744,0 73.0151244,0.325305 74.2647744,0.950175 C75.5144244,1.575 76.6016244,2.482245 77.4399744,3.6 L84.6399744,13.23 C85.7033244,14.636925 86.3297244,16.32528 86.4417744,18.085365 C86.5538244,19.84545 86.1461244,21.59964 85.2699744,23.13 L64.7949744,58.5"></path>
        <line x1="38.7404244" y1="45" x2="12.2805144" y2="0.899775"></line>
        <line x1="47.7399744" y1="45" x2="74.1999744" y2="0.899775"></line>
        <line x1="25.2399744" y1="22.5" x2="61.2399744" y2="22.5"></line>
        <path d="M43.2399744,90 C55.6662744,90 65.7399744,79.9263 65.7399744,67.5 C65.7399744,55.0737 55.6662744,45 43.2399744,45 C30.8135844,45 20.7399744,55.0737 20.7399744,67.5 C20.7399744,79.9263 30.8135844,90 43.2399744,90 Z"></path>
        <polyline points="43.2399744 72 43.2399744 63 40.9899744 63"></polyline>
      </g>
    </g>
  );
};
export default medal;
