import styled from 'styled-components';

const Banner = styled.div`
  background-color: white;
  display: flex;
  border-radius: 6px;
`;
const ImgWrap = styled.div`
  flex-basis: 500px;
  padding: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
`;
const TextWrap = styled.div`
  color: white;
  flex: 1;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(227, 245, 255, 1) 31%,
    rgba(215, 241, 255, 1) 100%
  );
  padding: 32px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
export default { Banner, ImgWrap, TextWrap };
