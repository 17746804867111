import styled from 'styled-components';

const Wrap = styled.div`
  background: white;
  min-height: 100%;
  width: 100%;
  border-radius: 16px;
  display: flex;
  overflow: hidden;
`;
const SideNav = styled.div`
  width: 250px;
  min-width: 250px;
  overflow: hidden;
  border-radius: 16px 0px 0px 16px;
  border-right: 1px solid #cbd5e1;
`;
const IconWrap = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #cbd5e1;
  margin-right: 8px;
`;
const NavItem = styled.div`
  padding: 16px 24px;
  cursor: pointer;
  border-bottom: 1px solid #eaeef2;
  display: flex;
  align-items: center;
  transition: all 100ms ease-in-out;

  &.active {
    box-shadow: inset 6px 0 #0784ab;
    &:hover {
      box-shadow: inset 6px 0 #0784ab;
    }
    & > ${IconWrap} {
      background: #0784ab;
      svg {
        g {
          stroke: white;
        }
      }
    }
  }
  &:hover {
    box-shadow: inset 6px 0 #cbd5e1;
  }
`;

const Content = styled.div`
  padding: 32px;
  overflow-y: auto;
  height: 650px;
  width: 100%;
  border-radius: 16px 16px 16px 16px;
`;
const List = styled.ul`
  list-style-type: none;
  padding-inline-start: 0px;
`;
const ListItem = styled.li`
  border-bottom: 1px solid #eaeef2;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  & > .paid {
    color: green;
    font-weight: 500;
    margin-right: 16px;
    min-width: 100px;
    text-align: left;
  }
  & > .unpaid {
    color: red;
    text-align: left;
    font-weight: 500;
    margin-right: 16px;
    min-width: 100px;
  }
  & > .money {
    min-width: 80px;
  }
  & > div {
    &:first-child {
      flex: 1;
    }
    &:nth-child(3) {
      font-weight: 600;
      margin-right: 16px;
    }
  }
  &:last-child {
    border-bottom: 0px;
  }
`;
const NoPayment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
`;
const PayWrap = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  margin-bottom: 48px;
`;
const CancelSub = styled.div`
  color: red;
  font-size: 14px;
  justify-content: flex-end;
  display: flex;
  cursor: pointer;
  margin-left: 16px;
  &:hover {
    text-decoration: underline;
  }
`;
const CardItem = styled.div`
  width: 100%;
  border: 1px solid #cbd5e1;
  padding: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  p {
    margin: 0px;
  }
  &:hover {
    border: 1px solid #0784ab;
  }
`;
const DeleteCard = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: red;
  cursor: pointer;
`;
const DefaultCard = styled.div`
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #0784ab;
  cursor: pointer;
  margin-right: 8px;
`;
const CardDetails = styled.div`
  display: flex;
  flex: 1;
  div {
    margin-right: 16px;
  }
`;
const PaymentButtons = styled.div`
  margin-top: auto;
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;
const PlansProfile = styled.div`
  border: 1px solid #cbd5e1;
  border-radius: 10px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  &:hover {
    border: 1px solid #0784ab;
  }
  &.active {
    border: 1px solid #0784ab;
  }
  .sub-title {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    padding-bottom: 16px;
    span {
      &:first-child {
        flex: 1;
      }
    }
  }
  .sub-banners {
    padding-bottom: 16px;
  }
  .sub-button {
    display: flex;
    justify-content: center;
  }
`;
export default {
  Wrap,
  SideNav,
  NavItem,
  Content,
  IconWrap,
  List,
  ListItem,
  NoPayment,
  CancelSub,
  PayWrap,
  CardItem,
  DeleteCard,
  CardDetails,
  PaymentButtons,
  PlansProfile,
  DefaultCard,
};
