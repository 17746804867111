import React, { Component } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import {
  Card,
  ErrorDisplay,
  Loader,
  Typography,
  Icon,
  Counter,
  ListUser,
  CreateUser,
} from '../../../components';
import { MetadataReducer } from '../../../interfaces';
import {
  isMetaChanged,
  updateIfRequired,
  getMarketplaceFeature,
} from '../../../helpers';
import * as _ from 'lodash';
import RenderComponent from '../../../components/RenderComponent';
import Styled from './Overview.style';
import { Link } from 'react-router-dom';

interface OverviewProps {
  getEndpointData: Function;
  genericPage: any;
  metadata: MetadataReducer;
  userData: any;
  listMarketplaceBanner: Function;
  marketplace: any;
  getUser: Function;
}

class Overview extends Component<OverviewProps, any> {
  componentDidMount() {
    // console.log('DID MOUNT');
    this.updateData();
    this.props.getUser();
    this.props.listMarketplaceBanner();
  }

  componentDidUpdate(prevProps) {
    if (isMetaChanged(this.props.metadata, prevProps.metadata)) {
      // console.log('META CHANGED');
      this.updateData(true);
    }
  }

  updateData = (forceUpdate = false) => {
    updateIfRequired(
      'overview',
      this.props.getEndpointData,
      this.props,
      forceUpdate
    );
  };
  renderLayout = (layout) => {
    return _.map(layout, (row, index) => {
      const components = _.map(row.components, (component) => {
        return <RenderComponent key={component.id} {...component} />;
      });

      if (row.title) {
        return (
          <Col xs={12} sm={12} md={12} key={index}>
            <Row>{components}</Row>
          </Col>
        );
      }

      return (
        <Col xs={12} sm={12} md={12} key={index}>
          {components}
        </Col>
      );
    });
  };
  render() {
    const { genericPage, userData, marketplace } = this.props;
    const user = _.get(userData, 'data');
    const marketplaceLicence = getMarketplaceFeature(
      user,
      'MARKETPLACE_BANNERS'
    );

    const adminRole = user && user.roles.find((o) => o === 'ROLE_SUPER_ADMIN');

    const marketplaceLength = _.get(
      marketplace,
      'listMarketplaceBanner.data.length'
    );

    const features = _.get(user, 'validFeatures');
    const featuresLength = _.get(features, 'length');
    const feature = _.get(features, '[0].features');
    const createCampaigns =
      featuresLength && feature.find((o) => o.name === 'CREATE_CAMPAIGNS');

    const loading = _.get(genericPage, 'overview.status.loading');
    const error = _.get(genericPage, 'overview.status.error');

    const done = _.get(genericPage, 'overview.status.done');
    const data = _.get(genericPage, 'overview.data.layout');

    const name = _.get(userData, 'data.email');
    const title = `Hello, ${name}`;

    if (error && createCampaigns) {
      if (error === 'no.data.available.for.campaign') {
        return (
          <ErrorDisplay text="No data has been found for this campaign." />
        );
      } else if (error.includes('tags.not.found.for.campaign')) {
        const text = `No UTM tag(s) have been created for this campaign.`;
        return <ErrorDisplay text={text} />;
      }
      return <ErrorDisplay text={error} />;
    }

    if (loading && !done) return <Loader />;
    return (
      <div style={{ width: '100%' }}>
        <Row style={{ marginBottom: '16px' }}>
          <Col md={4} xs={12} sm={12}>
            <Card theme="intro">
              <Typography
                size="3"
                text={title}
                style={{
                  marginBottom: '4px',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  width: '250px',
                }}
              >
                {title}
              </Typography>
              <Typography
                size="p"
                text="Welcome to the GetWaterFit Dashboard"
                style={{ marginBottom: '0px' }}
              >
                Welcome to the GetWaterFit Dashboard
              </Typography>
              <img
                style={{
                  position: 'absolute',
                  width: '40%',
                  height: 'auto',
                  bottom: -20,
                  right: 0,
                }}
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/line.svg"
                alt=""
              />
            </Card>
          </Col>{' '}
          {adminRole === 'ROLE_SUPER_ADMIN' || !marketplaceLicence ? null : (
            <Col md={4} xs={12} sm={12}>
              <Card theme="green">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ flex: '1' }}>
                    <span style={{ fontSize: '20px', fontWeight: 600 }}>
                      MySh
                      <Icon icon="leaf" size={14} colorPrimary="#86B049" />p
                    </span>
                    <Styled.Button>
                      <Link to="/myshop">Take me there</Link>
                    </Styled.Button>
                  </div>
                  <Typography
                    size="p"
                    text="3"
                    style={{
                      color: '#86B049',
                      fontWeight: 600,
                      fontSize: '64px',
                      marginBottom: '0px',
                      lineHeight: '64px',
                    }}
                  >
                    <Counter
                      type="none"
                      end={marketplaceLength}
                      start={0}
                      timer={130}
                    />
                  </Typography>
                </div>
              </Card>
            </Col>
          )}
        </Row>
        {adminRole === 'ROLE_SUPER_ADMIN' && (
          <>
            <CreateUser />
            <ListUser />
          </>
        )}
        {data === undefined && adminRole !== 'ROLE_SUPER_ADMIN' ? null : (
          <Row style={{ marginBottom: '16px' }}>{this.renderLayout(data)}</Row>
        )}
      </div>
    );
  }
}

export default Overview;
