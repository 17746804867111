import React from 'react';
import DropdownItem from './DropdownItem';
import Styled from './Dropdown.style';
import { Icon } from '../index';
import { useNavigate } from 'react-router-dom';
interface DropdownProps {
  logOut: Function;
}

export const Dropdown: React.FC<DropdownProps> = (props) => {
  const { logOut } = props;
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  return (
    <Styled.DropdownToggle
      onMouseOver={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
    >
      <Icon style={{ justifyContent: 'flex-end' }} icon="menu" size={24} />

      {open && (
        <Styled.DropdownItemWrap>
          <DropdownItem header={true}>You are logged in as ...</DropdownItem>
          <DropdownItem
            header={false}
            onClick={() => {
              logOut();
              navigate('/login');
            }}
          >
            <Icon icon="power" size="18" style={{ marginRight: '8px' }} />
            Logout
          </DropdownItem>
        </Styled.DropdownItemWrap>
      )}
    </Styled.DropdownToggle>
  );
};

export default Dropdown;
