import styled from 'styled-components';
import { colors } from '../../../assets/variable';
const Wrap = styled.div`
  overflow-x: hidden;
  padding-bottom: 8px;
  @media screen and (max-width: 1700px) {
    overflow-x: auto;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .text-center {
    text-align: center;
  }
  table {
    thead {
      tr {
        td {
          padding: 0.75rem 1.25rem;
          font-weight: 600;
          border-bottom: 1px solid #0000001f;
          &.no-wrap {
            white-space: nowrap;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 0.75rem 1.25rem;
          border-bottom: 1px solid #0000001f;
        }
        &:last-child {
          td {
            border-bottom: 1px solid #0000001f;
          }
        }
      }
    }
  }
`;
const PaginationWrap = styled.div`
  & > .paginationBttns {
    display: flex;
    width: auto;
    justify-content: flex-end;
    border-radius: 9999px;
    padding-inline-start: 0px;
    background-color: #e9eef5;
    color: #475569;
  }

  li {
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    border-radius: 9999px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
    }
    &:hover {
      background-color: #cbd5e1;
      transition-duration: 0.15s;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    &.paginationDisabled {
      cursor: not-allowed;
      background-color: #e9eef5;
      color: #bfbebe;
    }
    &.paginationActive {
      color: white;
      background-color: ${colors.primary};
      &:hover {
        background-color: ${colors.primary};
      }
    }
  }
`;
export default { Wrap, PaginationWrap };
