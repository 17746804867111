import React from 'react';
import Styled from './Notification.style';
import { Icon } from '../index';

export interface NotificationProps {
  theme: string;
  isClose?: boolean;
  title: string;
}

export const Notification: React.FC<NotificationProps> = (props) => {
  const { theme, isClose, title } = props;
  const [closed, setClosed] = React.useState(false);

  return closed ? null : (
    <Styled.Wrap theme={theme}>
      <Styled.Title>{title}</Styled.Title>
      {isClose ? (
        <Styled.Close onClick={() => setClosed(true)}>
          <Icon icon="times" size={16} colorPrimary="#fff" />
        </Styled.Close>
      ) : null}
    </Styled.Wrap>
  );
};

export default Notification;
