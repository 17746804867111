import * as React from 'react';
const invoices = (props: any) => {
  const { colorPrimary } = props;
  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(11.749235, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M56.251125,0 L25.200504,0 C23.400468,0 21.600432,0.900018 20.250405,2.250045 C18.900378,3.600072 18.00036,5.400108 18.00036,7.200144 L18.00036,64.801296 C18.00036,66.601332 18.900378,68.401368 20.250405,69.751395 C21.600432,71.101422 23.400468,72.00144 25.200504,72.00144 L69.301386,72.00144 C71.101422,72.00144 72.901458,71.101422 74.251485,69.751395 C75.601512,68.401368 76.50153,66.601332 76.50153,64.801296 L76.50153,20.250405 L56.251125,0 Z"></path>
        <path d="M0,25.198749 L0,82.799856 C0,84.599892 0.900018,86.399928 2.250045,87.749955 C3.600072,89.099982 5.400108,90 7.200144,90 L51.301026,90"></path>
        <polyline points="54.00108 0 54.00108 22.50045 76.50153 22.50045"></polyline>
      </g>
    </g>
  );
};
export default invoices;
