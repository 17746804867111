import * as React from 'react';
import Styled from './Href.style';
import Icon from '../Icon';
interface HrefProps {
  href?: string;
  text: string;
  style?: React.CSSProperties;
  onClick?: (e: any) => void;
  className?: string;
  icon?: string;
}

export const Href: React.FC<HrefProps> = (props) => {
  const { text, href, icon, style, className, onClick } = props;

  return href ? (
    <Styled.Link
      className={className}
      style={style}
      rel="noreferrer"
      target="_blank"
      href={href}
    >
      {text}
      {icon ? (
        <Icon icon={icon} size={18} style={{ marginLeft: '4px' }} />
      ) : null}
    </Styled.Link>
  ) : (
    <Styled.Span className={className} style={style} onClick={onClick}>
      {text}
      {icon ? (
        <Icon icon={icon} size={18} style={{ marginLeft: '4px' }} />
      ) : null}
    </Styled.Span>
  );
};

export default Href;
