/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import { Card, Typography, Button } from '../../../components';
import { useNavigate } from 'react-router-dom';

interface SuccessCardProps {
  getUser: Function;
}
const SuccessCard: React.FC<SuccessCardProps> = (props) => {
  const navigate = useNavigate();
  const { getUser } = props;

  React.useEffect(() => {
    setTimeout(() => {
      getUser();
    }, 200);
  }, []);
  return (
    <div style={{ width: '100%' }}>
      <Row>
        <Col md={12} xs={12} sm={12}>
          <Card
            style={{
              height: '80vh',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              size="2"
              text="Thank you!"
              style={{ marginTop: '32px' }}
            />
            <Typography
              size="p"
              text="You have successfully added a new card."
            />
            <img
              src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/payment.svg"
              alt="Success payment"
              width="400px"
              style={{ marginBottom: '16px' }}
            />
            <Button
              onClick={() => {
                navigate('/');
              }}
            >
              Home
            </Button>
          </Card>
        </Col>
      </Row>
    </div>
  );
};
export default SuccessCard;
