import styled from 'styled-components';
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-basis: 100%;
  flex: 1;
  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;
const Icon = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 200px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-width: 1281px) {
    margin: 0 auto 10px;
  }
`;

const Subtitle = styled.div`
  color: #787878;
  padding-bottom: 5px;
`;

const Paragraph = styled.div`
  padding-bottom: 10px;
  padding-right: 10px;
  font-size: 16px;
`;

const CircleContainer = styled.div`
  width: 170px;
  min-width: 170px;
  @media screen and (max-width: 1281px) {
    margin: 0 auto 16px auto;
  }
`;

const LegendWrap = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  font-size: 18px;
  font-weight: 400;
  margin-top: 32px;
  margin-left: 32px;
  width: 100%;
  @media screen and (max-width: 1281px) {
    margin-left: 0px;
  }
`;

const LegendItem = styled.div`
  display: flex;
  padding-bottom: 16px;
  align-items: center;
`;

const LegendDot = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  border-radius: 5px;
  margin-right: 12px;
  flex: 0 1 22px;
`;
const LegendText = styled.div`
  display: flex;
  text-align: left;
  flex: 1;
  padding-right: 10px;
`;
const LegendValue = styled.div`
  display: flex;
  font-weight: 600;
  margin-left: 8px;
`;

export default {
  Wrapper,
  Icon,
  Subtitle,
  Paragraph,
  CircleContainer,
  LegendWrap,
  LegendItem,
  LegendDot,
  LegendText,
  LegendValue,
};
