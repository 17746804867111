import { connect } from 'react-redux';
import { getBusinessUser, switchUser } from '../../../actions/admin';
import { AppState } from '../../../interfaces';
import ListUser from './ListUser';
import {
  listLicences,
  getBusinessLicence,
  createLicence,
  updateLicence,
} from '../../../actions/licence';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    admin: state.adminReducer,
    licence: state.licenceReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBusinessUser: () => dispatch(getBusinessUser()),
    listLicences: () => dispatch(listLicences()),
    getBusinessLicence: (businessId) =>
      dispatch(getBusinessLicence(businessId)),
    createLicence: (data, onSuccess, onFailed) =>
      dispatch(createLicence(data, onSuccess, onFailed)),
    updateLicence: (licenceId, data, onSuccess) =>
      dispatch(updateLicence(licenceId, data, onSuccess)),
    switchUser: (email) => dispatch(switchUser(email)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListUser);
