import * as React from 'react';

const downloadFile = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(14.000000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M47.25,0 L9,0 C6.613065,0 4.32387,0.948195 2.636055,2.636055 C0.948195,4.32387 0,6.613065 0,9 L0,81 C0,83.3868 0.948195,85.67595 2.636055,87.3639 C4.32387,89.05185 6.613065,90 9,90 L63,90 C65.3868,90 67.67595,89.05185 69.3639,87.3639 C71.05185,85.67595 72,83.3868 72,81 L72,24.75 L47.25,0 Z"></path>
        <polyline points="45 0 45 27 72 27"></polyline>
        <line x1="36" y1="72" x2="36" y2="45"></line>
        <polyline points="22.5 58.5 36 72 49.5 58.5"></polyline>
      </g>
    </g>
  );
};
export default downloadFile;
