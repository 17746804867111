import styled from 'styled-components';
import { colors } from '../../assets/variable';
const Wrap = styled.ol`
  background-color: #e9eef5;
  display: flex;
  color: #475569;
  width: auto;
  justify-content: flex-end;
  border-radius: 9999px;
  padding-inline-start: 0px;
  li {
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    border-radius: 9999px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #cbd5e1;
      transition-duration: 0.15s;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    &.active {
      background-color: ${colors.primary};
      color: #fff;
    }
    svg {
      width: 1rem;
      height: 1rem;
    }
  }
`;
export default { Wrap };
