import styled, { css } from 'styled-components';

const Popup = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(11, 38, 65, 0.16);
  border-radius: 10px;
  position: fixed;
  transform: scale(0);
  z-index: 99999;
  padding: 16px;
  margin: 16px;
  border-radius: 10px;
  max-width: 560px;
  &.open {
    position: relative;
    transition: transform 0.3s;
    transition-timing-function: ease-in-out;
    transform: scale(1);
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }
`;

const PopupBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0);
  &.open {
    transform: scale(1);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(37, 179, 235, 0.6);
    right: 0;
    z-index: 99998;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;
  overflow-y: none;
  height: auto;
  @media screen and (max-height: 414px) {
    overflow-y: scroll;
    height: auto;
  }
`;

const CloseWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 30px;
`;
const Close = styled.div`
  height: 30px;
  width: 30px;
  border-radius: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  position: absolute;
  top: 3px;
  left: 0;
`;

export default {
  Popup,
  Close,
  CloseWrap,
  Container,
  Icon,
  PopupBackground,
};
