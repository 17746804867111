import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const listLicences = () => {
  return {
    type: 'LIST_LICENCES',
    payload: axios.get(
      `${getMicroServiceUrl('sponsor-portal')}admin/licence/available`
    ),
  };
};

export const getBusinessLicence = (businessId) => {
  return {
    type: 'GET_BUSINESS_LICENCE',
    payload: axios.get(
      `${getMicroServiceUrl(
        'sponsor-portal'
      )}admin/licence/listByBusiness/${businessId}?showExpired=1`
    ),
  };
};

export const createLicence = (data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'CREATE_LICENCE',
      payload: axios.post(
        `${getMicroServiceUrl('sponsor-portal')}admin/licence/create`,
        data
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const updateLicence = (licenceId, data, onSuccess) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'UPDATE_LICENCE',
      payload: axios.post(
        `${getMicroServiceUrl(
          'sponsor-portal'
        )}admin/licence/update/${licenceId}`,
        data
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        console.log('Error');
      });
  };
};
