/* eslint-disable no-case-declarations */
import store from 'store2';
import moment from 'moment';
export const initialState = {
  initialDate: null,
  startDate: null,
  endDate: null,
  regionSlug: null,
  datePickerPeriod: 'custom',
  campaignId: null,
  focus: null,
};

let stateFromStore = initialState;
if (store('metadata')) {
  stateFromStore = store('metadata');
}

const metadataReducer = (state = stateFromStore, action) => {
  switch (action.type) {
    case 'CLEAR_USERDATA':
      return {
        ...initialState,
      };
    case 'LOG_IN_FULFILLED':
      const firstSite = moment(action.payload.data.createdAt).format(
        'YYYY-MM-DD'
      );

      const dateMonthBefore = moment()
        .subtract(2, 'month')
        .format('YYYY-MM-DD');

      const getInitialDate = moment(firstSite)
        .subtract(2, 'month')
        .format('YYYY-MM-DD');

      const initialMetaState = {
        ...state,
        initialDate: getInitialDate,
        startDate: dateMonthBefore,
        regionSlug:
          action.payload.data.validFeatures.length === 0
            ? null
            : action.payload.data.validFeatures[0].regionSlug,
        endDate: moment().endOf('day').format('YYYY-MM-DD'),
        campaignId: null,
        focus: 'region',
      };

      store('metadata', initialMetaState);
      return initialMetaState;
    case 'SET_META':
      const getCampaignId = store('campaignId');
      const getFocus = store('dataType');
      const updatedState = {
        ...state,
        regionSlug: action.regionSlug ? action.regionSlug : state.regionSlug,
        startDate: action.startDate ? action.startDate : state.startDate,
        endDate: action.endDate ? action.endDate : state.endDate,
        initialDate: action.initialDate
          ? action.initialDate
          : state.initialDate,
        campaignId: getCampaignId,
        focus: getFocus,
      };

      store('metadata', updatedState);
      return updatedState;

    case 'SET_PICKER_PERIOD':
      return {
        ...state,
        datePickerPeriod: action.period,
      };

    default:
      return state;
  }
};

export default metadataReducer;
