import React from 'react';
import { string } from 'yup';
import Styled from './Dropdown.style';

export interface DropdownItemProps {
  children: any;
  onClick?: (e: any) => void;
  header: boolean;
}

export const DropdownItem: React.FC<DropdownItemProps> = (props) => {
  return props.header ? (
    <Styled.Header>{props.children}</Styled.Header>
  ) : (
    <Styled.DropdownItem className="button" onClick={props.onClick}>
      {props.children}
    </Styled.DropdownItem>
  );
};

export default DropdownItem;
