import styled, { css } from 'styled-components';
type Type = {
  isMobile: boolean;
};
const Wrap = styled.div<Type>`
  height: 100%;
  overflow: hidden;
  width: 100%;
  ${(props) => {
    if (props.isMobile) {
      return desktopStyles;
    }
  }}
`;

const Main = styled.div`
  background: #f1f5f9;
  margin-left: 100px;
  margin-top: 70px;
  overflow: hidden;
  min-height: 100vh;
  z-index: 999999;
  transition: all 0.1s ease-out;
  display: flex;
  flex-direction: column;
`;
const MobileMain = styled.div`
  background: #f1f5f9;
  width: 100%;
  min-height: 100vh;

  padding: 0 10px;
`;
const TopNav = styled.div`
  height: 70px;
  position: fixed;
  margin-left: 300px;
  display: flex;
  flex: 1;
  width: calc(100% - 300px);
  display: flex;
  top: 0;
  left: 0;
  background: #f1f5f9;
  z-index: 10001;
  box-shadow: 0 0 10px 1px rgba(68, 102, 242, 0.05);
`;

const NavContainer = styled.div`
  display: flex;
  padding: 0 16px;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const Menu = styled.nav`
  padding: 0 1rem;
  .navbar-nav {
    width: 100%;
  }
`;
const Header = styled.div`
  display: flex;
  border-radius: 6px;
  background: white;
  flex-direction: column;
  padding: 16px 32px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
  width: 100%;
`;
const MobHeader = styled.div`
  display: flex;
  border-radius: 6px;
  background: white;
  flex-direction: column;
  padding: 16px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
  margin-bottom: 16px;
  width: 100%;
`;
const Selector = styled.div`
  display: flex;
  flex-direction: column;

  select {
    padding: 4px;
    border-radius: 6px;
    font-size: 16px;
  }
`;
const Logo = styled.div`
  height: 70px;
  width: 300px;
  background-color: #0784ab;
  float: left;
  transition: all 0.1s ease-out;
  text-align: center;
  img {
    vertical-align: middle;
  }
`;
const LogOut = styled.div`
  height: 70px;
  line-height: 70px;
`;
const Content = styled.div`
  min-height: 84vh;
`;
const Dropdown = styled.div`
  background-color: #fff;
  position: absolute;
  top: 80px;
  transition: all 0.3s linear;
  z-index: 8;
  box-shadow: 0 0 2px 2px #f6f7fb;
  opacity: 0;
  transform: translateY(30px);
  visibility: hidden;
  top: 63px;
  right: 16px;
  width: 150px;
  &.active {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }
`;
const desktopStyles = css``;
export default {
  Wrap,
  Main,
  MobileMain,
  TopNav,
  Menu,
  Header,
  MobHeader,
  Selector,
  Logo,
  LogOut,
  Content,
  NavContainer,
  Dropdown,
};
