import React from 'react';
import Checkbox from '../Checkbox/Checkbox';
import * as _ from 'lodash';
import { Field } from 'formik';

function CheckboxGroup({ label, name, options, ...rest }) {
  return (
    <div>
      <label>{label}</label>
      <Field name={name} {...rest}>
        {({ field }) => {
          return options.map((option) => {
            console.log(option.value);
            return (
              <React.Fragment key={option.key}>
                <Checkbox
                  type="checkbox"
                  id={option.value}
                  label={option.value}
                  {...field}
                  value={option.value}
                  checked={field.value === option.value}
                />
              </React.Fragment>
            );
          });
        }}
      </Field>
    </div>
  );
}

export default CheckboxGroup;
