import * as _ from 'lodash';
import { AdminReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: AdminReducer = {
  createAdmin: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  getBusinessUser: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const adminReducer = createReducer(initialState, {
  CREATE_ADMIN_FULFILLED: (state, action) => {
    state.createAdmin.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.createAdmin.data = _.get(action, 'payload.data');
  },
  CREATE_ADMIN_PENDING: (state) => {
    state.createAdmin.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  CREATE_ADMIN_REJECTED: (state, action) => {
    console.log(action.payload);
    state.createAdmin.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.detail'),
      done: false,
    };
  },
  GET_BUSINESS_USER_FULFILLED: (state, action) => {
    state.getBusinessUser.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getBusinessUser.data = _.get(action, 'payload.data');
  },
  CGET_BUSINESS_USER_PENDING: (state) => {
    state.getBusinessUser.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_BUSINESS_USER_REJECTED: (state, action) => {
    state.getBusinessUser.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default adminReducer;
