import * as React from 'react';

const home = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(9.500068, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M0,31.5 L40.499865,0 L80.999865,31.5 L80.999865,81 C80.999865,83.3868 80.051715,85.67595 78.363765,87.3639 C76.676265,89.05185 74.387115,90 71.999865,90 L9,90 C6.613065,90 4.32387,89.05185 2.636055,87.3639 C0.94824,85.67595 0,83.3868 0,81 L0,31.5 Z"></path>
        <polyline points="27 90 27 45 53.999865 45 53.999865 90"></polyline>
      </g>
    </g>
  );
};
export default home;
