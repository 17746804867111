import React from 'react';
import Typography from '../Typography';
import Styled from './Card.style';

interface CardProps {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  theme?: string;
}

export const Card: React.FC<CardProps> = (props) => {
  const { style, children, theme } = props;
  return (
    <Styled.Wrap theme={theme} style={style}>
      {children ? children : null}
    </Styled.Wrap>
  );
};

export default Card;
