import * as React from 'react';

const droplets = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(7.387906, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M18.9385408,62.8758608 C29.3547383,62.8758608 37.8770816,54.2114784 37.8770816,43.7005883 C37.8770816,38.2084114 35.1783396,33.0005494 29.7808554,28.5973387 C24.3833713,24.1941279 20.311585,17.6603314 18.9385408,10.7951103 C17.5654966,17.6603314 13.5410567,24.2414743 8.0962262,28.5973387 C2.65139571,32.9532031 0,38.2557578 0,43.7005883 C0,54.2114784 8.52234337,62.8758608 18.9385408,62.8758608 Z"></path>
        <path d="M45.263586,16.949994 C48.5195947,11.7471981 50.8282028,6.00844146 52.0814607,0 C54.4487783,11.836588 61.5507311,23.1997125 71.0200015,30.7751288 C80.489272,38.3504505 85.2241883,47.3462574 85.2241883,56.8155278 C85.2508946,63.360214 83.3343142,69.765702 79.7165795,75.2195283 C76.0988447,80.6733546 70.9433004,84.9307385 64.9033263,87.4509849 C58.8633522,89.9717046 52.2111897,90.6416555 45.7900775,89.3770345 C39.3684917,88.1124134 33.4672424,84.9695626 28.8343545,80.3466647"></path>
      </g>
    </g>
  );
};
export default droplets;
