import * as _ from 'lodash';
import { UserReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: UserReducer = {
  status: {
    loading: false,
    error: false,
    done: false,
  },
  data: null,
};

const userReducer = createReducer(initialState, {
  GET_USER_FULFILLED: (state, action) => {
    state.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.data = _.get(action, 'payload.data');
  },
  GET_USER_PENDING: (state) => {
    state.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_USER_REJECTED: (state, action) => {
    state.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default userReducer;
