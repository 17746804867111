import * as React from 'react';

const power = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(9.505863, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M69.1324895,20.8770178 C74.7944685,26.5407516 78.6499594,33.7561754 80.211233,41.6108664 C81.7725067,49.4658275 80.969823,57.606947 77.9044175,65.0056742 C74.8394619,72.4039514 69.6490146,78.7277845 62.9899801,83.1767394 C56.3309457,87.6256943 48.5025308,90 40.494142,90 C32.4857532,90 24.6571134,87.6256943 17.9981689,83.1767394 C11.3392245,78.7277845 6.14900206,72.4039514 3.08368653,65.0056742 C0.0184159952,57.606947 -0.784267616,49.4658275 0.77714098,41.6108664 C2.33854958,33.7561754 6.19390555,26.5407516 11.8557946,20.8770178"></path>
        <line x1="40.5166388" y1="0" x2="40.5166388" y2="44.9934759"></line>
      </g>
    </g>
  );
};
export default power;
