import React from 'react';
import Styled from './Typography.style';

interface TypographyProps {
  size: '1' | '2' | '3' | '4' | '5' | '6' | 'p' | 'icon' | 'sectionSeparator';
  text?: string | number;
  subText?: string;
  icon?: string;
  weight?: number;
  style?: any;
  textAlign?: string;
  className?: string;
  children?: any;
}
export class Typography extends React.Component<TypographyProps, any> {
  render() {
    const { size, text, style, className, children } = this.props;

    if (!text) {
      return null;
    }

    const renderTypography = (size: any) => {
      switch (size) {
        case '1':
          return (
            <Styled.HeadOne className={className} style={style}>
              {children ? children : text}
            </Styled.HeadOne>
          );
        case '2':
          return (
            <Styled.HeadTwo className={className} style={style}>
              {children ? children : text}
            </Styled.HeadTwo>
          );
        case '3':
          return (
            <Styled.HeadThree className={className} style={style}>
              {children ? children : text}
            </Styled.HeadThree>
          );
        case '4':
          return (
            <Styled.HeadFour className={className} style={style}>
              {children ? children : text}
            </Styled.HeadFour>
          );
        case '5':
          return (
            <Styled.HeadFive className={className} style={style}>
              {children ? children : text}
            </Styled.HeadFive>
          );
        case '6':
          return (
            <Styled.HeadSix className={className} style={style}>
              {children ? children : text}
            </Styled.HeadSix>
          );
        case 'p':
          return (
            <Styled.Paragraph className={className} style={style}>
              {children ? children : text}
            </Styled.Paragraph>
          );

        default:
          return null;
      }
    };

    return renderTypography(size);
  }
}
export default Typography;
