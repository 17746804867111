import React from 'react';
import Styled from './SideNav.style';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Icon } from '../../components';
import _ from 'lodash';
import { getFeature } from '../../helpers';

interface SideNavProps {
  metadata: any;
  campaign: any;
  user: any;
  setMeta: Function;
  getCampaigns: Function;
  setPickerPeriod: Function;
  children: any;
  maximise?: boolean;
}

export const SideNav: React.FC<SideNavProps> = (props) => {
  const { maximise, user } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [subNav, setSubNav] = React.useState(false);
  const pathname = location.pathname;
  const userData = _.get(user, 'data');
  const marketplace = getFeature(userData, 'none');

  const metaSlug = _.get(props, 'metadata.regionSlug');
  const currentRegion = getFeature(userData, metaSlug);
  const roles = _.get(user, 'data.roles');
  const adminRole = roles && roles.find((o) => o === 'ROLE_SUPER_ADMIN');

  const hasCharts =
    currentRegion.length > 0 &&
    currentRegion[0].features.find((o) => o.name === 'LICENCE_LEVEL');

  const navMenu = [
    {
      link: '/',
      label: 'Overview',
      icon: 'home',
      type: 'nav',
      display: true,
    },
    {
      link: '/myshop',
      label: 'MyShop',
      icon: 'market',
      type: 'nav',
      display: getFeature(userData, 'none') && marketplace.length > 0,
    },
    {
      link: '/utm-branding',
      label: 'UTM Branding',
      icon: 'coins',
      type: 'nav',
      display: !adminRole,
    },

    {
      link: '/savings',
      label: 'Savings',
      icon: 'chart',
      type: 'nav',
      display: hasCharts,
      hasSubnav: [
        {
          link: '/users',
          label: 'Users',
          icon: 'users',
          display: true,
          type: 'subnav',
        },
        {
          link: '/easy-wins',
          label: 'Easy wins',
          icon: 'medal',
          display: true,
          type: 'subnav',
        },
        {
          link: '/leaks',
          label: 'Leaks',
          icon: 'droplets',
          display: true,
          type: 'subnav',
        },
        {
          link: '/products',
          label: 'Products',
          icon: 'box',
          display: true,
          type: 'subnav',
        },
      ],
    },
    {
      link: '/my-profile',
      label: 'My profile',
      icon: 'profile',
      type: 'nav',
      display: adminRole !== 'ROLE_SUPER_ADMIN',
    },
  ];

  const renderNav = () => {
    return _.map(navMenu, (el) => {
      if (el.display) {
        if (el.hasSubnav) {
          return (
            <div key={el.link}>
              {maximise ? (
                <Styled.NavElement
                  onClick={() => setSubNav(!subNav)}
                  className={
                    pathname === 'easy-wins' ||
                    pathname === 'leaks' ||
                    pathname === 'products'
                      ? 'active'
                      : ''
                  }
                >
                  <Icon
                    icon="chart"
                    size={24}
                    colorPrimary="#fff"
                    style={{ marginRight: maximise ? '16px' : '0px' }}
                  />
                  <span>Savings</span>

                  <Styled.Arrow className={subNav ? 'transform' : ''}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#fff"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide"
                    >
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </Styled.Arrow>
                </Styled.NavElement>
              ) : null}
              <Styled.SubNav
                className={subNav ? 'is-active' : ''}
                style={{ display: subNav ? 'none' : 'block' }}
              >
                {_.map(el.hasSubnav, (elem) => {
                  return (
                    <li
                      key={elem.link}
                      onClick={() => navigate(elem.link)}
                      style={{
                        justifyContent: maximise ? 'flex-start' : 'center',
                        paddingLeft: maximise ? '1.25rem' : '0px',
                      }}
                      className={elem.link === pathname ? 'active-subnav' : ''}
                    >
                      <Icon
                        icon={elem.icon}
                        size={24}
                        colorPrimary={
                          pathname === elem.link ? '#fff' : '#ffffffb3'
                        }
                        style={{ marginRight: maximise ? '16px' : '0px' }}
                      />
                      {maximise ? elem.label : null}
                    </li>
                  );
                })}
              </Styled.SubNav>
            </div>
          );
        }
        return (
          <Styled.NavElement
            style={
              maximise
                ? { justifyContent: 'flex-start' }
                : { justifyContent: 'center' }
            }
            onClick={() => navigate(el.link)}
            className={pathname === el.link ? 'active' : ''}
            key={el.link}
          >
            <Icon
              icon={el.icon}
              size={24}
              colorPrimary="#fff"
              style={{ marginRight: maximise ? '16px' : '0px' }}
            />
            {maximise ? <span>{el.label}</span> : null}
          </Styled.NavElement>
        );
      }
    });
  };

  return (
    <Styled.Wrap
      style={
        maximise
          ? { width: '300px', minWidth: '300px', padding: '24px' }
          : { width: '70px', minWidth: '70px', padding: '0px' }
      }
    >
      <Styled.Logo>
        <span style={{ lineHeight: '70px' }}>
          {maximise ? (
            <img
              src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/www-white.svg"
              alt="GetWaterFit"
            />
          ) : (
            <Icon icon="gwf" size={44} colorPrimary="#fff" />
          )}
        </span>
      </Styled.Logo>
      <Styled.Nav>{renderNav()}</Styled.Nav>
    </Styled.Wrap>
  );
};

export default SideNav;
