import React from 'react';
import {
  ChartDonut,
  ChartLine,
  ChartBar,
  InfoBox,
  InfoBoxDetailed,
  Card,
} from '../../components';
import { ChartData } from '../../interfaces';
import { Col } from 'react-styled-flexboxgrid';
interface RenderComponentProps {
  icon: string;
  id: number;
  size: 'small' | 'medium' | 'large' | 'full';
  text: string;
  theme: string;
  title: string;
  total: number;
  valueText: string;
  totalText: string;
  type: string;
  value: number;
  valueType: 'time' | 'number' | 'currency';
  chartData: ChartData;
  chartkey: string;
  description: string;
  data: any;
}

const RenderComponent: React.FC<RenderComponentProps> = (props) => {
  const {
    title,
    text,
    value,
    total,
    valueText,
    totalText,
    icon,
    id,
    size,
    theme,
    chartkey,
    type,
    valueType,
    chartData,
    description,
    data,
  } = props;

  const sizeProps = (size) => {
    switch (size) {
      case 'small':
        return {
          xs: 6,
          sm: 6,
          md: 3,
        };
      case 'semi-medium':
        return {
          xs: 6,
          sm: 4,
          md: 4,
        };
      case 'medium':
        return {
          xs: 12,
          sm: 12,
          md: 6,
          xl: 6,
        };
      case 'large':
        return {
          xs: 12,
          sm: 6,
          md: 6,
          xl: 6,
        };
      case 'full':
        return {
          xs: 12,
          sm: 12,
          md: 12,
          xl: 12,
        };
      default:
        return {
          xs: 12,
          sm: 12,
        };
    }
  };

  switch (type) {
    case 'info-box-detailed':
      return (
        <Col {...sizeProps(size)} style={{ marginBottom: '16px' }}>
          <InfoBoxDetailed
            id={id}
            title={title}
            theme={theme}
            description={description}
            total={total}
            value={value}
            text={text}
            totalText={totalText}
            valueText={valueText}
            chartKey={chartkey}
          />
        </Col>
      );
    case 'info-box-slim':
      return (
        <Col {...sizeProps(size)} style={{ marginBottom: '16px' }}>
          <InfoBox
            id={id}
            layout="horizontal"
            value={value}
            subtitle={title}
            icon={icon}
            theme={theme}
            valueType={valueType}
            chartKey={chartkey}
          />
        </Col>
      );
    case 'chart-line':
      return (
        <Col {...sizeProps(size)} style={{ marginBottom: '16px' }}>
          <ChartLine
            icon={icon}
            id={id}
            title={title}
            theme={theme}
            chartData={chartData}
            chartKey={chartkey}
          />
        </Col>
      );
    case 'chart-bar':
      return (
        <Col {...sizeProps(size)} style={{ marginBottom: '16px' }}>
          <ChartBar
            id={id}
            theme={theme}
            title={title}
            chartData={chartData}
            chartKey={chartkey}
          />
        </Col>
      );
    case 'chart-donut':
      return (
        <Col {...sizeProps(size)} style={{ marginBottom: '16px' }}>
          <ChartDonut
            chartKey={chartkey}
            id={id}
            title={title}
            theme={theme}
            icon={icon}
            chartData={chartData}
            description={description}
          />
        </Col>
      );
    default:
      return (
        <Col xs={12} sm={6} md={6} style={{ marginBottom: '16px' }}>
          <Card>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '32px',
              }}
            >
              <img
                style={{ width: '120px' }}
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/DonutChart.svg"
                alt="Not found"
              />
              <div style={{ marginLeft: '60px' }}>
                <p>This component doesnt have the correct theme name</p>
                <p>
                  Component id: <b>{id}</b>
                </p>
              </div>
            </div>
          </Card>
        </Col>
      );
  }
};

export default RenderComponent;
