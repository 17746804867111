import styled, { keyframes } from 'styled-components';

const ldsEllipsis1 = keyframes`
0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const ldsEllipsis2 = keyframes`
 0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;
const ldsEllipsis3 = keyframes`
 0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;
const Wrap = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
`;
const Loader = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  & > div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #0784ab;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 8px;
      animation: ${ldsEllipsis1} 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: ${ldsEllipsis2} 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: ${ldsEllipsis2} 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: ${ldsEllipsis3} 0.6s infinite;
    }
  }
`;

export default { Wrap, Loader };
