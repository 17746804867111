import * as _ from 'lodash';
import axios from 'axios';

export function setupInterceptors() {
  axios.defaults.withCredentials = true;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status } = error.response;
      if (status === 401) {
        localStorage.removeItem('swsmUser');
        localStorage.removeItem('user');
        localStorage.removeItem('metadata');
        localStorage.removeItem('campaignId');
        localStorage.removeItem('profileKey');
        localStorage.removeItem('type');
        window.location.reload();
      }
      return Promise.reject(error);
    }
  );
}
