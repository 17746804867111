import { connect } from 'react-redux';
import {
  createMarketplaceBanner,
  updateMarketplaceBanner,
  deleteMarketplaceBanner,
  listMarketplaceBanner,
} from '../../../actions/marketplace';
import { getDownloadCsvData } from '../../../actions/downloadCSV';
import { getBannerTag } from '../../../actions/banner';
import { getCategories } from '../../../actions/categories';
import Marketplace from './Marketplace';
import { AppState } from '../../../interfaces';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => ({
  marketplace: state.marketplaceReducer,
  user: state.userReducer,
  banner: state.bannerReducer,
  categories: state.categoriesReducer,
});
const mapDispatchToProps = (dispatch) => {
  return {
    createMarketplaceBanner: (data, onSuccess, onFailed) =>
      dispatch(createMarketplaceBanner(data, onSuccess, onFailed)),
    updateMarketplaceBanner: (bannerId, data, onSuccess, onFailed) =>
      dispatch(updateMarketplaceBanner(bannerId, data, onSuccess, onFailed)),
    deleteMarketplaceBanner: (bannerId) =>
      dispatch(deleteMarketplaceBanner(bannerId)),
    listMarketplaceBanner: () => dispatch(listMarketplaceBanner()),
    getDownloadCsvData: (bannerId) => dispatch(getDownloadCsvData(bannerId)),
    getBannerTag: () => dispatch(getBannerTag()),
    getCategories: () => dispatch(getCategories()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
