import styled, { css } from 'styled-components';
import { colors } from '../../assets/variable';
const Wrap = styled.div`
  width: 100%;
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 16px;
  color: white;
  display: flex;
  align-items: center;
  ${(props) => {
    if (props.theme === 'Error') {
      return css`
        background-color: #fb837d;
        border: 2px solid ${colors.error};
      `;
    } else if (props.theme === 'Notification') {
      return css`
        background-color: ${colors.primary};
        border: 2px solid ${colors.primary};
      `;
    } else if (props.theme === 'Success') {
      return css`
        background-color: ${colors.success};
        border: 2px solid ${colors.success};
      `;
    }
  }}
`;
const Title = styled.div`
  color: white;
  flex: 1;
`;

const Close = styled.div`
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 100ms ease-in-out;
  &:hover {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transition: all 100ms ease-in-out;
  }
`;
export default { Wrap, Close, Title };
