import * as React from 'react';

const chart = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(4.988604, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M86.4669571,62.5196776 C83.6038349,69.2915174 79.1248334,75.2585683 73.4224447,79.8996079 C67.7200559,84.5406474 60.9675706,87.7138924 53.7555275,89.1425278 C46.5430342,90.5707131 39.0909047,90.2106286 32.0499917,88.0937816 C25.0091238,85.9764845 18.5939927,82.1672402 13.3655652,76.9977766 C8.13709274,71.8287631 4.25448123,65.4575173 2.05715537,58.4412701 C-0.140161492,51.425023 -0.585293495,43.9775745 0.760670995,36.7494176 C2.10666699,29.5213957 5.20276386,22.733082 9.77831305,16.9780759 C14.3538622,11.2230248 20.2695561,6.67641741 27.0081781,3.73583205"></path>
        <path d="M90.0227919,45.010567 C90.0227919,39.0996894 88.8583685,33.2466953 86.5965875,27.7857882 C84.3343564,22.3248362 81.0193282,17.3629163 76.8396469,13.18328 C72.6599656,9.00368878 67.6980007,5.68821041 62.2368686,3.42620436 C56.7761866,1.16424333 50.9230125,0 45.0122248,0 L45.0122248,45.010567 L90.0227919,45.010567 Z"></path>
      </g>
    </g>
  );
};
export default chart;
