import * as React from 'react';

const eyeOff = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M35.46,35.45946 C34.13367,36.69525 33.069825,38.18565 32.33196,39.84165 C31.594095,41.49765 31.19733,43.2855 31.16538,45.0981 C31.133385,46.9107 31.466835,48.71115 32.145795,50.39235 C32.8248,52.0731 33.83541,53.6004 35.117325,54.882 C36.39915,56.16405 37.92645,57.17475 39.6072,57.8538 C41.2884,58.53285 43.08885,58.8663 44.90145,58.8339 C46.71405,58.80195 48.5019,58.4055 50.1579,57.6675 C51.8139,56.9295 53.3043,55.8657 54.54,54.53955"></path>
        <path d="M39.285,13.86 C41.18085,13.623795 43.0893,13.503555 45,13.5 C76.5,13.5 90,45 90,45 C87.98805,49.30695 85.4649,53.35605 82.485,57.06"></path>
        <path d="M20.745,20.745495 C11.795625,26.841285 4.634415,35.214165 0,45.00045 C0,45.00045 13.5,76.5006054 45,76.5006054 C53.62155,76.5234 62.0586,74.0034 69.255,69.25545"></path>
        <line x1="0" y1="0" x2="90" y2="90"></line>
      </g>
    </g>
  );
};
export default eyeOff;
