import styled from 'styled-components';

const Card = styled.div`
  display: flex;
  border-radius: 16px;
  background: white;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
`;
const CardHeader = styled.div`
  display: flex;
  h2 {
    flex: 1;
  }
  @media screen and (max-width: 1281px) {
    flex-direction: column;
  }
`;
export default {
  Card,
  CardHeader,
};
