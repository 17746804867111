import { connect } from 'react-redux';
import { createBranding, updateBranding } from '../../actions/branding';
import { AppState } from '../../interfaces';
import LogoUpload from './LogoUpload';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    business: state.updateBusinessReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBranding: (data, onSuccess, onFailed) =>
      dispatch(updateBranding(data, onSuccess, onFailed)),
    createBranding: (data, onSuccess, onFailed) =>
      dispatch(createBranding(data, onSuccess, onFailed)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoUpload);
