import { connect } from 'react-redux';
import { getAllCampaigns } from '../../../actions/campaigns';
import { getDownloadCsvData } from '../../../actions/downloadCSV';
import { AppState } from './../../../interfaces';
import Downloads from './Downloads';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    campaign: state.campaignReducer,
    metadata: state.metadataReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllCampaigns: () => dispatch(getAllCampaigns()),
    getDownloadCsvData: (campaignId) =>
      dispatch(getDownloadCsvData(campaignId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Downloads);
