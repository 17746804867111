import * as React from 'react';
import Styled from './Popup.style';
import Icon from '../Icon';

interface PopupProps {
  id?: number;
  handleClick: Function;
  open?: boolean;
  children: React.ReactNode;
}

export const Popup: React.FC<PopupProps> = (props) => {
  const { handleClick, open, children } = props;

  return (
    <Styled.PopupBackground className={open ? 'open' : undefined}>
      <Styled.Popup className={open ? 'open' : undefined}>
        <Styled.Container>{children}</Styled.Container>
      </Styled.Popup>
    </Styled.PopupBackground>
  );
};

export default Popup;
