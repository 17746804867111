import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Typography,
  AddImage,
  Input,
  Notification,
  ErrorDisplay,
  Loader,
} from '../../../../components';
import Styled from './Branding.style';
import * as _ from 'lodash';
import { getMarketplaceFeature } from '../../../../helpers';

interface BrandingProps {
  business: any;
  createBranding: Function;
  updateBranding: Function;
  getUser: Function;
  user: any;
  getBranding: Function;
  branding: any;
}
export const Branding: React.FC<BrandingProps> = (props) => {
  const {
    getBranding,
    branding,
    updateBranding,
    createBranding,
    getUser,
    user,
  } = props;
  const brandingBanner = _.get(branding, 'getBranding.data.banner');
  const { loading, error, done } = _.get(branding, 'getBranding.status');
  const businessLogo = _.get(branding, 'getBranding.data.logoURL');
  const imageUrl = _.get(brandingBanner, 'imageURL');
  const title = _.get(brandingBanner, 'title');
  const description = _.get(brandingBanner, 'description');
  const userData = _.get(user, 'data');
  const marketplaceBanners = getMarketplaceFeature(
    userData,
    'MARKETPLACE_BANNERS'
  );

  React.useEffect(() => {
    getBranding();
  }, []);

  const BrandingSchema = Yup.object().shape({
    bannerTitle: Yup.string(),
    bannerDescription: Yup.string(),
    bannerImage: Yup.mixed().test(
      'fileSize',
      'Image is too big! Max image size is 2MB',
      (value) => {
        if (value) {
          return value && value.size <= 2000000;
        } else {
          return true;
        }
      }
    ),
    logo: Yup.mixed().test(
      'fileSize',
      'Image is too big! Max image size is 2MB',
      (value) => {
        if (value) {
          return value && value.size <= 2000000;
        } else {
          return true;
        }
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      bannerImage: '',
      bannerTitle: title,
      bannerDescription: description,
      theme: 'lightTheme',
      logo: '',
    },
    validationSchema: BrandingSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (brandingBanner || businessLogo) {
        updateBranding(
          values,
          () => {
            setSubmitting(false);
            setImage(null);
            resetForm();
          },
          () => {
            setSubmitting(false);
          }
        );
      } else {
        createBranding(
          values,
          () => {
            setSubmitting(false);
            setImage(null);
            resetForm();
          },
          () => {
            setSubmitting(false);
          }
        );
      }

      setTimeout(() => {
        getBranding();
      }, 500);
      setSubmitting(false);
    },
  });
  const [bannerImageName, setImage] = React.useState(null);
  const [filename, setFilename] = React.useState('');
  const [logoImage, setLogoImage] = React.useState(null);
  const [logoName, setLogoName] = React.useState('');
  const onLogoChange = (event) => {
    setLogoImage(event.target.files[0]);
    formik.setFieldValue('logo', event.currentTarget.files[0]);
    setLogoName(event.currentTarget.files[0].name);
  };

  const onImageChange = (event) => {
    setImage(event.target.files[0]);
    formik.setFieldValue('bannerImage', event.currentTarget.files[0]);
    setFilename(event.currentTarget.files[0].name);
  };

  const imgUrl = bannerImageName
    ? `${URL.createObjectURL(bannerImageName)}`
    : imageUrl;
  const renderLogo = () => {
    if (logoImage) {
      return (
        <img
          style={{ maxHeight: '70px' }}
          src={`${URL.createObjectURL(logoImage)}`}
        />
      );
    } else if (businessLogo) {
      return <img style={{ maxHeight: '70px' }} src={businessLogo} />;
    } else {
      return (
        <div
          style={{
            background: '#f8f8f8',
            border: '1px dotted #6c6c6c',
            width: '300px',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ color: '#6c6b6b', margin: '0px', padding: '0px' }}>
            Your preview will show here
          </p>
        </div>
      );
    }
  };

  if (error) {
    return <ErrorDisplay />;
  }

  if (loading && !done) {
    return <Loader />;
  }
  return (
    <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
      <Row>
        <Col md={8} sm={12} xs={12} style={{ marginBottom: '16px' }}>
          <AddImage
            name="logo"
            label="Add logo"
            onClearClick={() => setLogoImage(null)}
            onImageChange={() => onLogoChange}
            disclaimer="Maximum file size 2MB.he max height of the logo should not exceed 70 pixels."
            filename={logoName ? logoName : 'Please select a image'}
            error={formik.errors.logo ? formik.errors.logo : undefined}
          />
        </Col>
        <Col md={4} xs={12} sm={12}>
          {renderLogo()}
        </Col>
      </Row>
      {marketplaceBanners ? (
        <Row>
          <Col sm={12} xs={12} md={12}>
            <Typography
              size="3"
              text="Banner"
              style={{ marginBottom: '8px' }}
            />
          </Col>
          <Col sm={12} xs={12} md={4}>
            <Input
              id="bannerTitle"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              type="text"
              label="Title"
              placeholder="Title"
              style={{ marginBottom: '16px' }}
              value={formik.values.bannerTitle}
            />
          </Col>

          <Col sm={12} xs={12} md={4}>
            <Input
              id="bannerDescription"
              type="text"
              label="Description"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              style={{ marginBottom: '16px' }}
              placeholder="Description"
              value={formik.values.bannerDescription}
            />
          </Col>
          <Col sm={12} xs={12} md={4}>
            <AddImage
              name="bannerImage"
              label="Add image"
              onClearClick={() => setImage(null)}
              onImageChange={() => onImageChange}
              disclaimer="Maximum file size 2MB. The recommended size of the image is 410x309 pixels."
              filename={filename ? filename : 'Please select a image'}
              error={
                formik.errors.bannerImage
                  ? formik.errors.bannerImage
                  : undefined
              }
            />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col
          md={12}
          xs={12}
          sm={12}
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'flex-end',
            marginTop: '16px',
          }}
        >
          <Button type="submit">Update</Button>
        </Col>
      </Row>
      {marketplaceBanners ? (
        <Row>
          <Col
            md={12}
            xs={12}
            sm={12}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              size="3"
              text="Preview banner"
              style={{ marginBottom: '0px' }}
            />
            <Styled.PreviewBanner theme="lightTheme">
              <Styled.Img
                style={{
                  backgroundImage: `url(${imgUrl})`,
                }}
              ></Styled.Img>
              <Styled.TextWrap>
                <Styled.Title>
                  {formik.values.bannerTitle
                    ? formik.values.bannerTitle
                    : title}
                </Styled.Title>
                <Styled.Description>
                  {formik.values.bannerDescription
                    ? formik.values.bannerDescription
                    : description}
                </Styled.Description>
                <Styled.ButtonWrap>Take me there</Styled.ButtonWrap>
              </Styled.TextWrap>
            </Styled.PreviewBanner>
          </Col>
        </Row>
      ) : null}
    </form>
  );
};

export default Branding;
