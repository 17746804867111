import React, { useEffect, useState, useRef } from 'react';
import { prettifyIfNumber } from '../../helpers';
const Counter = ({ start = 0, end, timer = 50, type, digits = 0 }) => {
  const [state, setState] = useState(0);
  const ref = useRef(start);
  const accumulator = end / 200;
  const isMounted = React.useRef(false);

  const updateCounterState = () => {
    if (ref.current < end) {
      const result = Math.ceil(ref.current + accumulator);
      if (result > end) {
        return setState(end);
      }
      setState(result);
      ref.current = result;
    }
    setTimeout(updateCounterState, timer);
  };
  useEffect(() => {
    isMounted.current = true;
    if (isMounted) {
      updateCounterState();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  const renderType = (type) => {
    switch (type) {
      case 'carbon':
        return (
          <span>
            Kg/CO<sub>2</sub>
          </span>
        );
      case 'water':
        return <span>Litres</span>;
      case 'money':
        return <span>£</span>;

      default:
        return false;
    }
  };

  return type === 'money' ? (
    <div>
      {renderType(type)} {prettifyIfNumber(state)}
    </div>
  ) : (
    <div>
      {prettifyIfNumber(state)} {renderType(type)}
    </div>
  );
};

export default Counter;
