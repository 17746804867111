import * as React from 'react';

const email = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 14.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M81,0 L9,0 C4.029435,0 0,4.029435 0,9 L0,63 C0,67.9707 4.029435,72 9,72 L81,72 C85.9707,72 90,67.9707 90,63 L90,9 C90,4.029435 85.9707,0 81,0 Z"></path>
        <path d="M90,13.5 L49.635,39.15 C48.24585,40.0203 46.63935,40.482 45,40.482 C43.36065,40.482 41.75415,40.0203 40.365,39.15 L0,13.5"></path>
      </g>
    </g>
  );
};
export default email;
