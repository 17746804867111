import styled, { css } from 'styled-components';
import { colors } from '../../assets/variable';
const Wrap = styled.div`
  display: flex;
  border-radius: 16px;
  position: relative;
  flex-direction: column;
  padding: 32px;
  height: 100%;
  width: 100%;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
  ${(props) => {
    switch (props.theme) {
      case 'darkTheme':
        return css`
          color: ${colors.baseLight};
          background-color: rgb(15, 23, 42);
        `;
      case 'intro':
        return css`
          color: ${colors.baseLight};
          background-color: #80c7dd;
          justify-content: center;
          padding: 16px 32px;
        `;
      case 'blue':
        return css`
          color: ${colors.baseLight};
          background-color: rgb(7, 14, 110);
        `;
      case 'green':
        return css`
          color: black;
          background-color: #e2edd9;
          padding: 16px 32px;
        `;

      default:
        return css`
          color: black;
          background: white;
        `;
    }
  }};
`;

export default { Wrap };
