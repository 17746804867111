import styled, { css } from 'styled-components';

export interface ButtonProps {
  full?: boolean;
  style?: Object;
  disabled?: boolean;
  id?: string;
  onClick?: (e: any) => void;
  title?: string;
  type?: 'button' | 'submit';
  className?: string;
  theme?: 'COLOR' | 'WHITE';
  children: React.ReactNode;
  size?: string;
}

const Button = styled.button<ButtonProps>`
  background-color: #0784ab;
  height: 46px;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #0784ab;
  min-width: 128px;
  cursor: pointer;
  color: white;
  font-weight: 600;
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: #047091;
    border: 1px solid #047091;
  }
  ${(props) => {
    if (props.size === 'small') {
      return css`
        height: 38px;
        min-width: 60px;
        width: 100px;
        padding: 6px 16px;
      `;
    }
  }}
  ${(props) => {
    if (props.theme === 'WHITE') {
      return whiteStyles;
    }
  }}
  ${(props) => {
    if (props.disabled) {
      return disabledStyles;
    }
  }}
`;
const whiteStyles = css`
  background-color: white;
  color: #64748b;
  border: 1px solid #64748b;
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: white;
    border: 1px solid #0784ab;
    color: #0784ab;
  }
`;
const disabledStyles = css`
  background-color: #d8d8d8;
  color: #0784ab;
  border: 1px solid #d8d8d8;
  transition: all 200ms ease-in-out;
  cursor: not-allowed;
  &:hover {
    background-color: #d8d8d8;
    color: #0784ab;
    border: 1px solid #d8d8d8;
  }
`;
export default { Button };
