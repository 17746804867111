import React from 'react';
import { CircleProgress, Card, NothingToDisplay, Typography } from '../index';
import { numberFormatter } from '../../helpers';
import { colors } from '../../assets/variable';
import { Spring } from 'react-spring';
import Styled from './InfoBoxDetailed.styles';
export interface InfoBoxDetailedProps {
  id?: number;
  text?: any;
  title: string;
  theme?: string;
  total?: number;
  value?: number;
  description?: string;
  valueText?: string;
  totalText?: string;
  dataTest?: string;
  chartKey?: string;
}

const InfoBoxDetailed = (props: InfoBoxDetailedProps) => {
  const {
    text = 'Sample text',
    valueText,
    totalText,
    theme = 't1',
    total,
    title,
    description,
    chartKey,
    value,
  } = props;

  function getProgressCircle() {
    return (
      <Styled.CircleContainer>
        <Spring from={{ number: 0 }} to={{ number: value }}>
          {(props) => (
            <CircleProgress theme={theme} value={value} total={total} />
          )}
        </Spring>
      </Styled.CircleContainer>
    );
  }

  return (
    <Card key={chartKey}>
      <Typography size="2" text={title} />
      <Typography size="p" text={description} />
      {total && total > 0 ? (
        <Styled.Wrapper style={{ display: 'flex' }}>
          {getProgressCircle()}
          <Styled.LegendWrap>
            <Styled.LegendItem>
              <Styled.LegendDot
                style={{ backgroundColor: colors[theme] }}
              ></Styled.LegendDot>
              <Styled.LegendText>{valueText}</Styled.LegendText>
              <Styled.LegendValue>{numberFormatter(value)}</Styled.LegendValue>
            </Styled.LegendItem>
            <Styled.LegendItem>
              <Styled.LegendDot
                style={{ backgroundColor: colors['c4'] }}
              ></Styled.LegendDot>
              <Styled.LegendText>{totalText}</Styled.LegendText>
              <Styled.LegendValue>{numberFormatter(total)}</Styled.LegendValue>
            </Styled.LegendItem>
          </Styled.LegendWrap>
        </Styled.Wrapper>
      ) : (
        <NothingToDisplay />
      )}
    </Card>
  );
};

export default InfoBoxDetailed;
