import { connect } from 'react-redux';
import SuccessCard from './SuccessCard';
import { getUser } from '../../../actions/user';

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUser()),
  };
};
export default connect(null, mapDispatchToProps)(SuccessCard);
