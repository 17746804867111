import styled from 'styled-components';

const Wrap = styled.div`
  margin-bottom: 16px;
`;
const Selector = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  p {
    flex: 1;
  }
`;
const Arrow = styled.svg`
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transform: translate(0, 0) rotate(360deg) skew(0) skewY(0) scaleX(1) scaleY(1);
  &.transform {
    transform: translate(0, 0) rotate(90deg) skew(0) skewY(0) scaleX(1)
      scaleY(1);
  }
`;
export default { Wrap, Selector, Arrow };
