import styled from 'styled-components';

const FeatureBox = styled.div`
  padding: 16px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
  background: linear-gradient(
    0deg,
    rgba(215, 241, 255, 1) 0%,
    rgba(227, 245, 255, 1) 1%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 16px;
`;

const Button = styled.div`
  margin-top: 0.5rem;
  a {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-color: #86b049;
    text-decoration-thickness: 2px;
    color: black;
    cursor: pointer;
  }
`;
export default {
  FeatureBox,
  Button,
};
