import * as _ from 'lodash';
import { SubscriptionReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
export const initialState: SubscriptionReducer = {
  availableSubscriptions: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  currentSubscriptions: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  newSubscriptions: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  cancelSubscription: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  updateSubscription: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};
const subscriptionsReducer = createReducer(initialState, {
  GET_SUBSCRIPTIONS_FULFILLED: (state, action) => {
    state.availableSubscriptions.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.availableSubscriptions.data = _.get(action, 'payload.data');
  },
  GET_SUBSCRIPTIONS_PENDING: (state) => {
    state.availableSubscriptions.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_SUBSCRIPTIONS_REJECTED: (state, action) => {
    state.availableSubscriptions.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  GET_NEW_SUBSCRIPTION_FULFILLED: (state, action) => {
    state.newSubscriptions.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.newSubscriptions.data = _.get(action, 'payload.data');
  },
  GET_NEW_SUBSCRIPTION_PENDING: (state) => {
    state.newSubscriptions.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_NEW_SUBSCRIPTION_REJECTED: (state, action) => {
    state.newSubscriptions.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  CANCEL_SUBSCRIPTION_FULFILLED: (state, action) => {
    state.cancelSubscription.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.cancelSubscription.data = _.get(action, 'payload.data');
  },
  CANCEL_SUBSCRIPTION_PENDING: (state) => {
    state.cancelSubscription.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  CANCEL_SUBSCRIPTION_REJECTED: (state, action) => {
    state.cancelSubscription.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  GET_CURRENT_SUBSCRIPTIONS_FULFILLED: (state, action) => {
    state.currentSubscriptions.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.currentSubscriptions.data = _.get(action, 'payload.data');
  },
  GET_CURRENT_SUBSCRIPTIONS_PENDING: (state) => {
    state.currentSubscriptions.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_CURRENT_SUBSCRIPTIONS_REJECTED: (state, action) => {
    state.currentSubscriptions.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  UPDATE_SUBSCRIPTION_FULFILLED: (state, action) => {
    state.currentSubscriptions.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.currentSubscriptions.data = _.get(action, 'payload.data');
  },
  UPDATE_SUBSCRIPTION_PENDING: (state) => {
    state.currentSubscriptions.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  UPDATE_SUBSCRIPTION_REJECTED: (state, action) => {
    state.currentSubscriptions.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});
export default subscriptionsReducer;
