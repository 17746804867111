import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { Button, Typography } from '../../../components';
import Styled from './Page404.style';
import { useNavigate } from 'react-router-dom';
export const Page404: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Grid>
      <Styled.Wrap>
        <Row>
          <Col md={6}>
            <img
              src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/404.svg"
              alt="404"
            />
          </Col>
          <Col md={6}>
            <Typography
              size="1"
              style={{ color: 'white', fontSize: '96px', margin: '0px' }}
              text="404"
            />
            <Typography
              size="2"
              style={{
                color: 'white',
                fontSize: '26px',
                margin: '0px 0px 16px 0px',
              }}
              text="Oops. This page has gone missing."
            />
            <Typography
              size="p"
              style={{ color: 'white' }}
              text="You may have mistyped the address or the page may have been moved."
            />
            <Button
              style={{ border: '1px solid white' }}
              onClick={() => navigate('/')}
            >
              Back to Home
            </Button>
          </Col>
        </Row>
      </Styled.Wrap>
    </Grid>
  );
};

export default Page404;
