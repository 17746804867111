import DefaultLayout from './DefaultLayout';
import { connect } from 'react-redux';
import { getUser } from '../../actions/user';
import { exitUser } from '../../actions/admin';

const mapStateToProps = (state) => {
  return {
    user: state.userReducer,
    metadata: state.metadataReducer,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  exitUser: () => dispatch(exitUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultLayout);
