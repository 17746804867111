import { connect } from 'react-redux';
import { getEndpointData } from '../../../actions/actionsShared';
import { listMarketplaceBanner } from '../../../actions/marketplace';
import { getUser } from '../../../actions/user';
import Overview from './Overview';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state) => {
  return {
    genericPage: state.genericPageReducer,
    metadata: state.metadataReducer,
    userData: state.userReducer,
    marketplace: state.marketplaceReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEndpointData: (meta) => dispatch(getEndpointData(meta, 'overview')),
    listMarketplaceBanner: () => dispatch(listMarketplaceBanner()),
    getUser: () => dispatch(getUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Overview);
