import * as React from 'react';

const gwf = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M49.9989772,21.9600109 C48.9934463,33.3979255 32.1885101,52.2673424 32.1885101,62.1058344 C32.067469,68.549777 35.4360024,74.5567065 40.9973211,77.8141265 C46.5586395,81.0715463 53.4455995,81.0715463 59.0069179,77.8141265 C64.5682366,74.5567065 67.93677,68.549777 67.8157289,62.1058344 C67.8157289,52.2673424 51.2496063,33.2628073 49.9989772,21.9600109 M50.0618229,46.0990384 L50.6588569,47.1202808 C53.3549368,51.7614347 57.8703992,59.5323036 57.9960906,62.1026921 C57.9926275,66.5184496 54.4147328,70.0977501 49.9989772,70.1029478 C45.5964736,70.0995306 42.0240257,66.5397209 42.0050061,62.1372572 C42.1432666,59.6014338 46.5770297,52.0442402 49.2259753,47.5287778 C49.5056386,47.0574351 49.7853019,46.5766657 50.0618229,46.0990384 M25.7782503,84.830834 C23.5518536,84.8298587 21.6046005,83.3313765 21.0334011,81.1794997 L5.16486562,21.3378386 C4.47069135,18.7173308 6.03229318,16.03025 8.65280113,15.3360757 C11.2733091,14.6419014 13.9603898,16.2035034 14.654564,18.8240112 L30.5230995,78.6625301 C30.9144072,80.1356907 30.599094,81.7070443 29.6697453,82.9151976 C28.7403965,84.1233513 27.3024957,84.830834 25.7782503,84.830834 M74.2259887,84.830834 C72.70211,84.829618 71.2650055,84.1215586 70.3354936,82.9139926 C69.4059814,81.7064266 69.0892323,80.1359852 69.4779972,78.6625301 L85.3465327,18.8208689 C85.7955846,17.1256975 87.114889,15.7988609 88.8074766,15.3401657 C90.5000639,14.8814704 92.3087904,15.3606032 93.5523258,16.5970794 C94.7958612,17.8335556 95.2852833,19.6395249 94.8362311,21.3346963 L78.9676957,81.1794997 C78.3964963,83.3313765 76.4492431,84.8298587 74.2228464,84.830834"
        fill={colorPrimary}
        fillRule="nonzero"
      ></path>
    </g>
  );
};
export default gwf;
