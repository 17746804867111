import React from 'react';
import Styled from './ChartDonut.style';
import {
  PieTooltip,
  CustomPieLegend,
  UseColors,
  chartDataTransform,
  chartDataColors,
  prettifyIfNumber,
} from '../../helpers';
import { ResponsiveContainer, Tooltip, PieChart, Pie } from 'recharts';
import { ChartData } from '../../interfaces';
import { colors } from '../../assets/variable';
import * as _ from 'lodash';
import { Card, Icon, Typography } from '../../components';

interface ChartDonut {
  id: number;
  chartData: ChartData;
  theme?: string;
  icon: string;
  title: string;
  clientSlug?: string;
  chartKey: string;
  description?: string;
}

const ChartDonut = (props: ChartDonut) => {
  const { chartData, title, theme, clientSlug, chartKey } = props;

  const data = chartDataTransform(chartData);
  const dataColors = chartDataColors(chartData, theme);
  const type = dataColors[0];
  const color = _.get(colors, `${type}`);
  const totals = _.sum(chartData.totals);

  if (!data || data.length < 1 || totals === 0) {
    return (
      <Card>
        <Typography size="2" text={title} />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            style={{ width: '120px', marginTop: '32px' }}
            src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/DonutChart.svg"
            alt="Not found"
          />
          <div data-test="no-data-info" style={{ marginTop: '32px' }}>
            There is no data available for your chosen date range.
          </div>
        </div>
      </Card>
    );
  }

  const renderTotals = () => {
    const value = prettifyIfNumber(_.sum(chartData.totals));
    // const textValue = _.get(chartData, 'datasets[0].label', '');
    return (
      <Styled.Total>
        <Typography size="4" text={title} />
        <Typography size="4" text={value} />
      </Styled.Total>
    );
  };

  const renderChart = () => {
    return (
      <PieChart
        height={150}
        width={150}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <Pie
          data={data}
          dataKey="dataset_0"
          outerRadius="100%"
          fill={colors[chartData.datasets[0].theme]}
          paddingAngle={0}
          startAngle={90}
          endAngle={-270}
          isAnimationActive={true}
          innerRadius="75%"
        >
          {UseColors({ data, color })}
        </Pie>
        <Tooltip content={<PieTooltip />} />
      </PieChart>
    );
  };

  return (
    <Styled.Card>
      <Styled.CardHeader>
        <Typography size="2" text={title} />
      </Styled.CardHeader>
      <Styled.CardBody>
        <Styled.ChartPie key={chartKey}>
          <Styled.Square>
            <Styled.Content>
              <Icon
                size={100}
                colorPrimary="#25B3EB"
                colorSecondary="#0B2641"
                icon="droplet"
              />
            </Styled.Content>
            <ResponsiveContainer aspect={1} width="100%">
              {renderChart()}
            </ResponsiveContainer>
          </Styled.Square>
          <Styled.Legend>
            {CustomPieLegend({ data, color, totals, clientSlug })}
          </Styled.Legend>
        </Styled.ChartPie>
      </Styled.CardBody>
      <Styled.CardFooter>{renderTotals()}</Styled.CardFooter>
    </Styled.Card>
  );
};

export default ChartDonut;
