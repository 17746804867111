import styled, { css } from 'styled-components';
import { colors } from '../../../assets/variable';
import { Field } from 'formik';
const Textarea = styled.textarea`
  width: 100%;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  resize: none;
  padding: 12px 20px;
  font-family: 'Open Sans', sans-serif;
  margin-top: 6px;
  height: 200px;
`;
const Label = styled.label`
  font-weight: 600;
  font-size: 14px;
`;
const Error = styled.div`
  color: red;
  font-size: 14px;
  padding: 4px 10px;
`;
const PreviewBanner = styled.div`
  border-radius: 10px;
  background: white;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-width: 280px;
  box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.05);
  margin: auto;
  width: 100%;
  &:hover {
    box-shadow: 0 0.8rem 1rem 0 rgba(0, 0, 0, 0.08);
  }
`;
const ShortDescription = styled.p`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 0;
`;
const ImgWrap = styled.div`
  display: flex;
  flex: 1 0 auto;
  background-size: cover;
  margin: 0;
  height: 250px;
`;
const Button = styled.button`
  text-underline-offset: 8px;
  text-decoration: underline 2px rgb(134, 176, 73);
  border: 0;
  background: transparent;
  min-height: 35px;
  cursor: pointer;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  margin-top: 10px;
  text-transform: none;
  padding: 0px;
  ${(props) => {
    if (props.disabled) {
      return css`
        text-decoration-color: ${colors.darkGray};
      `;
    }
  }}
`;
const CopyWrap = styled.div`
  padding: 16px;
  min-height: 203.5px;
  display: flex;
  flex-direction: column;
`;

const Banner = styled.div`
  background-color: white;
  display: flex;
  border-radius: 6px;
`;
const ImageWrap = styled.div`
  flex-basis: 500px;
  padding: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const TextWrap = styled.div`
  color: white;
  flex: 1;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(227, 245, 255, 1) 31%,
    rgba(215, 241, 255, 1) 100%
  );
  padding: 32px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  border-radius: 6px;
`;

export default {
  Textarea,
  Label,
  Error,
  PreviewBanner,
  ShortDescription,
  ImgWrap,
  Button,
  CopyWrap,
  Banner,
  TextWrap,
  ImageWrap,
  Box,
};
