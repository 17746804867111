import React from 'react';
import {
  Button,
  Icon,
  Card,
  Typography,
  Input,
  ErrorDisplay,
  Loader,
} from '../../../components';
import Styled from './ListUser.style';
import _ from 'lodash';
import ReactPaginate from 'react-paginate';
import { Row, Col } from 'react-styled-flexboxgrid';
import moment from 'moment';
interface ListUserProps {
  switchUser: Function;
  admin: any;
  getBusinessUser: Function;
  listLicences: Function;
  createLicence: Function;
  updateLicence: Function;
  getBusinessLicence: Function;
  licence: any;
}

export const ListUser: React.FC<ListUserProps> = (props) => {
  const {
    admin,
    switchUser,
    getBusinessUser,
    listLicences,
    createLicence,
    getBusinessLicence,
    updateLicence,
    licence,
  } = props;
  const users = _.get(admin, 'getBusinessUser.data');
  const [openLicence, setOpenLicence] = React.useState(null);
  const { loading, error, done } = _.get(admin, 'getBusinessUser.status');
  const businessLicences = _.get(licence, 'getBusinessLicence.data.licences');
  const loadingGetLicence = _.get(licence, 'getBusinessLicence.status.loading');
  const getLicenceName = _.get(licence, 'listLicences.data.[0].name');

  const [pageNumber, setPageNumber] = React.useState(0);
  const ItemsPerPage = 6;
  const pagesVisited = pageNumber * ItemsPerPage;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  React.useEffect(() => {
    getBusinessUser();
    listLicences();
  }, []);

  if (error) {
    return <ErrorDisplay />;
  }

  if (loading && !done) {
    return <Loader />;
  }

  const [searchString, setSearchString] = React.useState('');
  const [errorActive, setError] = React.useState('');
  const filterData = (searchString) => {
    const getSortedList = _.orderBy(users, ['litres'], ['desc']);
    const newArray = users
      ? getSortedList.map((v, index) => ({ ...v, position: index + 1 }))
      : null;
    if (!users) {
      return [];
    }
    if (searchString !== '' && newArray) {
      const filtered = _.filter(newArray, (step) => {
        return (
          step.email && step.email.toLowerCase().indexOf(searchString) !== -1
        );
      });
      if (filtered.length > 0) {
        return filtered;
      }
      return [];
    }
    return _.values(newArray);
  };
  const dataList = filterData(searchString);
  const dataUser = searchString ? dataList : users;
  const pageCount = Math.ceil(dataUser && dataUser.length / ItemsPerPage);
  const displayUsers =
    dataUser && dataUser.slice(pagesVisited, pagesVisited + ItemsPerPage);

  const handleSearchInput = (e) => {
    setSearchString(e.target.value.toLowerCase());
  };

  const renderLicencesActive = (businessId) => {
    const licenceLength = _.get(businessLicences, 'length');
    const licenceId = _.get(businessLicences, '[0].id');
    const licenceExpires = _.get(businessLicences, '[0].expires');

    const newDate = new Date(licenceExpires);
    const nowDate = new Date();
    console.log(licenceExpires !== null && newDate < nowDate);
    const date = moment().subtract(1, 'days').format('DD-MM-YYYY hh:mm:ss');

    if (licenceLength === 0) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <p style={{ fontSize: '12px' }}>Licence: NONE</p>
          <Button
            theme="WHITE"
            style={{ width: '100px', margin: 'auto' }}
            onClick={() => {
              createLicence(
                {
                  name: `${getLicenceName}`,
                  businessId: businessId,
                  value: '90',
                },
                setOpenLicence(null),
                setError('There was a error')
              );
            }}
          >
            ACTIVATE
          </Button>
        </div>
      );
    } else if (loadingGetLicence) {
      return (
        <div style={{ position: 'relative' }}>
          <Loader />
        </div>
      );
    } else if (licenceExpires !== null && newDate < nowDate) {
      console.log('here');
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ fontSize: '12px' }}>
            Licence:
            <br /> NONE
          </p>
          <Button
            theme="WHITE"
            style={{
              width: '100px',
              margin: 'auto',
            }}
            onClick={() => {
              updateLicence(
                licenceId,
                {
                  value: '90',
                  expires: null,
                },
                setOpenLicence(null)
              );
            }}
          >
            ACTIVATE
          </Button>
        </div>
      );
    } else if (!licenceExpires) {
      return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ fontSize: '12px' }}>
            Licence:
            <br /> {getLicenceName}
          </p>
          <Button
            theme="WHITE"
            style={{
              width: '100px',
              margin: 'auto',
              background: 'red',
              color: 'white',
              border: '1px solid red',
            }}
            onClick={() => {
              updateLicence(
                licenceId,
                {
                  value: '90',
                  expires: date,
                },
                setOpenLicence(null)
              );
            }}
          >
            DEACTIVATE
          </Button>
        </div>
      );
    }
  };

  const tableItems = () => {
    return _.map(displayUsers, (el, index) => {
      return (
        <tr key={index}>
          <td
            className="item"
            style={{ fontWeight: 700, whiteSpace: 'nowrap' }}
          >
            {el.email}
          </td>
          <td
            className="text-center item no-wrap"
            style={{ fontWeight: 700, whiteSpace: 'nowrap' }}
          >
            {el.businessName ? el.businessName : 'NaN'}
          </td>
          <td
            className="text-center item no-wrap"
            style={{ fontWeight: 700, whiteSpace: 'nowrap' }}
          >
            {openLicence === el.businessId ? (
              renderLicencesActive(el.businessId)
            ) : (
              <Button
                theme="WHITE"
                style={{ height: '30px' }}
                onClick={() => {
                  setOpenLicence(el.businessId);
                  getBusinessLicence(el.businessId);
                }}
              >
                Check
              </Button>
            )}
          </td>
          <td className="text-center item no-wrap">
            <Button
              theme="WHITE"
              onClick={() => {
                switchUser(el.email);
                setTimeout(() => {
                  window.location.reload();
                }, 100);
              }}
            >
              Access user
              <Icon
                icon="arrow"
                colorPrimary="rgb(100, 116, 139)"
                size={16}
                style={{ marginLeft: '8px' }}
              />
            </Button>
          </td>
        </tr>
      );
    });
  };
  return (
    <Card>
      <Typography size="2" text="List users" />
      <Col xs={12} md={6} style={{ marginBottom: '56px' }}>
        <Input
          style={{ width: '100%' }}
          placeholder="Search"
          id="search-filter-leaderboard"
          label="Search"
          type="text"
          onChange={handleSearchInput}
          value={searchString}
        />
      </Col>
      <Styled.Wrap>
        <table
          style={{
            width: '100%',
            borderSpacing: '0 ',
            borderCollapse: 'separate',
          }}
        >
          <thead>
            <tr>
              <td className="item no-wrap">EMAIL</td>
              <td className="text-center item no-wrap">BUSINESS NAME</td>
              <td className="text-center item no-wrap">LICENCE</td>
              <td className="text-center item no-wrap">ACTIONS</td>
            </tr>
          </thead>
          <tbody>{tableItems()}</tbody>
        </table>
      </Styled.Wrap>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Styled.PaginationWrap>
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              pageCount={pageCount}
              onPageChange={changePage}
              containerClassName={'paginationBttns'}
              previousLinkClassName={'previousBttn'}
              nextLinkClassName={'nextBttn'}
              disabledClassName={'paginationDisabled'}
              activeClassName={'paginationActive'}
              forcePage={pageNumber}
            />
          </Styled.PaginationWrap>
        </Col>
      </Row>
    </Card>
  );
};

export default ListUser;
