import styled from 'styled-components';

const Wrap = styled.div`
  background: #e7ebef;
  padding: 32px 0;
  min-height: 42px;
  font-size: 14px;
  margin-top: auto;
`;
const TextRight = styled.div`
  text-align: right;
`;
export default { Wrap, TextRight };
