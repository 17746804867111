import React from 'react';
import App from '../App';

interface AppWrapProps {
  metadata: any;
}

const AppWrap: React.FC<AppWrapProps> = (props) => {
  const componentRef = React.createRef<HTMLDivElement>();
  return (
    <div ref={componentRef}>
      <App metadata={props.metadata} />
    </div>
  );
};

export default AppWrap;
