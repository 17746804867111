import styled from 'styled-components';
import { colors } from '../../../../assets/variable';
const Wrap = styled.div`
  overflow-x: hidden;
  padding-bottom: 8px;
  @media screen and (max-width: 1700px) {
    overflow-x: auto;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .text-center {
    text-align: center;
  }
  table {
    thead {
      tr {
        td {
          padding: 0.75rem 1.25rem;
          font-weight: 600;
          border-bottom: 1px solid #0000001f;
          &.no-wrap {
            white-space: nowrap;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          padding: 0.75rem 1.25rem;
          border-bottom: 1px solid #0000001f;
        }
        &:last-child {
          td {
            border-bottom: 1px solid #0000001f;
          }
        }
      }
    }
  }
`;

const Arrow = styled.div`
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter, -webkit-text-decoration-color, -webkit-backdrop-filter;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  margin-right: 1.25rem;
  margin-left: auto;
  height: 24px;
  &.transform {
    transform: translate(0, 0) rotate(180deg) skew(0) skewY(0) scaleX(1)
      scaleY(1);
  }
`;
const Status = styled.div`
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: 600;
  width: 110px;
  font-size: 14px;
  text-align: center;
  margin: auto;
`;
const NameWrap = styled.div`
  display: flex;
  max-width: 700px;
  width: 700px;
`;
const IconWrap = styled.div`
  display: flex;
  width: 34px;
  height: 34px;
  background: rgb(254, 243, 199);
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;
const TextWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 600;
  justify-content: center;
  font-size: 18px;
`;
const EditButton = styled.div`
  display: flex;
  background: #f8f8f8;
  border: 1px solid rgb(100, 116, 139);
  border-radius: 16px;
  max-width: 100px;
  padding: 4px;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  margin: auto;
  transition: all 100ms ease-in-out;
  &:hover {
    background: #e0dfdf;
    transition: all 100ms ease-in-out;
  }
`;
const PaginationWrap = styled.div`
  & > .paginationBttns {
    display: flex;
    width: auto;
    justify-content: flex-end;
    border-radius: 9999px;
    padding-inline-start: 0px;
    background-color: #e9eef5;
    color: #475569;
  }

  li {
    width: 2rem;
    height: 2rem;
    font-size: 14px;
    border-radius: 9999px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    & > a {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2rem;
      height: 2rem;
    }
    &:hover {
      background-color: #cbd5e1;
      transition-duration: 0.15s;
      transition-property: color, background-color, border-color,
        text-decoration-color, fill, stroke;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    }
    &.paginationDisabled {
      cursor: not-allowed;
      background-color: #e9eef5;
      color: #bfbebe;
    }
    &.paginationActive {
      color: white;
      background-color: ${colors.primary};
      &:hover {
        background-color: ${colors.primary};
      }
    }
  }
`;
const Download = styled.div`
  display: flex;
  background: #c1c1c1;
  max-width: 80px;
  padding: 2px 8px;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  cursor: pointer;
  margin: auto;
  color: #fff;
  transition: all 200ms ease-in-out;
  &.offer {
    background: #25b3eb;
    &:hover {
      background: white;
      transition: all 200ms ease-in-out;
      border: 1px solid #25b3eb;
      color: #000;
      svg {
        g {
          transition: all 200ms ease-in-out;
          stroke: #000;
        }
      }
    }
  }
  svg {
    g {
      stroke: #fff;
    }
  }
`;
export default {
  Wrap,
  Arrow,
  EditButton,
  Status,
  NameWrap,
  IconWrap,
  TextWrap,
  Download,
  PaginationWrap,
};
