import Register from './Register';
import { connect } from 'react-redux';
import { register } from '../../../actions/auth';
import { AppState } from '../../../interfaces';
const mapStateToProps = (state: AppState) => ({
  registration: state.registrationReducer,
});

const mapDispatchToProps = (dispatch) => ({
  register: (credentials) => dispatch(register(credentials)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Register);
