import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const deleteMarketplaceBanner = (bannerId) => {
  return {
    type: 'DELETE_MARKETPLACE_BANNER',
    payload: axios.delete(
      `${getMicroServiceUrl(
        'sponsor-portal'
      )}marketplace/banner/delete/${bannerId}`
    ),
  };
};
export const listMarketplaceBanner = () => {
  return {
    type: 'LIST_MARKETPLACE_BANNER',
    payload: axios.get(
      `${getMicroServiceUrl('sponsor-portal')}marketplace/banner/list`
    ),
  };
};
export const createMarketplaceBanner = (data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'CREATE_MARKETPLACE_BANNER',
      payload: axios.post(
        `${getMicroServiceUrl('sponsor-portal')}marketplace/banner/createAll`,
        data,
        { headers: { 'Content-type': 'multipart/form-data' } }
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const updateMarketplaceBanner = (
  bannerId,
  data,
  onSuccess,
  onFailed
) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'UPDATE_MARKETPLACE_BANNER',
      payload: axios.post(
        `${getMicroServiceUrl(
          'sponsor-portal'
        )}marketplace/banner/update/${bannerId}`,
        data,
        { headers: { 'Content-type': 'multipart/form-data' } }
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};
