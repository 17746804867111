import styled, { keyframes } from 'styled-components';
import 'react-dates/lib/css/_datepicker.css';
import { colors } from '../../assets/variable';

const opacity = keyframes`
  0% {opacity: 0;}
  50% {opacity: 0.5;}
  100% {opacity: 1;}

`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  .open {
    display: block;
  }
  .close {
    display: none;
  }
  .DayPicker__withBorder {
    box-shadow: none;
  }
  .DayPicker_transitionContainer {
    padding-bottom: 16px;
    box-sizing: content-box;
  }

  .display {
    color: #4c4c4c;
    display: flex;
    align-items: center;
    .field {
      cursor: pointer;
      text-align: center;
      display: inline-block;
      padding: 3px;
      .fa {
        font-size: 16px;
        position: relative;
        top: -3px;
      }
      &.active {
        /* border-bottom: 1px solid #0060da; */
        background-color: #f9f9f9;
        box-shadow: 0 3px 1px -2px #0060da;
      }
      span {
        @media screen and (max-width: 420px) {
          display: none;
        }
      }
    }
  }
`;
const Separate = styled.div`
  display: inline-block;
  margin: 0 10px;
`;
const Info = styled.div`
  padding: 8px 0px;
  background: white;
  margin-bottom: 16px;
`;
const Default = styled.div`
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  color: #696969;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DateWrap = styled.div``;
const Calendar = styled.div`
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  transform: translate3d(-300px, 45px, 0px);
  box-shadow: 0 2px 6px rgb(0 0 0 / 5%), 0 0 0 1px rgb(0 0 0 / 7%);
  z-index: 999;
  @media only screen and (max-width: 600px) {
    transform: translate3d(0px, 50px, 0px);
  }
`;
const Selector = styled.div`
  border: 1px solid #e2e8f0;
  border-radius: 24px;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  min-width: 225px;
  /* margin-right: 16px; */
  p {
    flex: 1;
  }
`;
const Arrow = styled.svg`
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transform: translate(0, 0) rotate(360deg) skew(0) skewY(0) scaleX(1) scaleY(1);
  &.transform {
    transform: translate(0, 0) rotate(90deg) skew(0) skewY(0) scaleX(1)
      scaleY(1);
  }
`;
const Wrap = styled.div`
  display: flex;
  align-items: center;
  h1 {
    flex: 1;
  }
`;
const Filters = styled.div`
  display: flex;
  position: relative;
  @media screen and (max-width: 1281px) {
    flex-direction: column;
  }
`;
const FilterItem = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  min-width: 225px;
  padding-left: 16px;
  @media screen and (max-width: 1281px) {
    padding-left: 0px;
    padding-top: 16px;
  }
`;
const Selected = styled.div`
  flex: 1;
  overflow: hidden; /* make sure it hides the content that overflows */
  white-space: nowrap; /* don't break the line */
  text-overflow: ellipsis; /* give the beautiful '...' effect */
`;
const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  display: none;
  position: absolute;
  top: 64px;
  background: white;
  padding: 8px 0;
  box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
  z-index: 1000;
  &.active {
    display: block;
  }
  div {
    padding: 8px 16px;
    &.active {
      background: rgb(220, 252, 231);
      color: rgb(22, 101, 52);
      &:hover {
        background: rgb(220, 252, 231);
        color: rgb(22, 101, 52);
      }
    }
    &:hover {
      background: rgb(236, 236, 236);
      color: rgb(44, 44, 44);
    }
  }
`;
const FilterTitle = styled.span`
  text-align: center;
  margin-bottom: 4px;
  font-weight: 500;
  flex: 1;
`;
const SettingsWrap = styled.div`
  opacity: 0;
  @media screen and (max-height: 755px) {
    overflow-y: scroll;
    overflow-x: hidden;
  }
`;
const Settings = styled.div`
  position: fixed;
  top: 0;
  z-index: 999999;
  right: 0;
  width: 0px;
  min-width: 0px;
  background: white;
  height: 100vh;
  visibility: hidden;
  transition: all 0.3s ease-out;
  padding: 16px;
  display: flex;
  flex-direction: column;
  &.active {
    width: 350px;
    min-width: 350px;
    visibility: visible;
    ${SettingsWrap} {
      opacity: 1;
    }
  }
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  transition: all 1s ease-out;
  background: rgba(7, 132, 171, 0.4);
  display: none;
  visibility: hidden;
  &.active {
    display: block;
    visibility: visible;
  }
`;
const WrapSettings = styled.div`
  padding: 16px 12px;
  width: 100%;
`;
const CampaignStyle = styled.div`
  border: 2px solid ${colors.primary};
  border-radius: 6px;
  padding: 16px;
`;
export default {
  Wrapper,
  Selector,
  Arrow,
  Info,
  Calendar,
  Separate,
  Default,
  DateWrap,
  Wrap,
  Filters,
  Dropdown,
  FilterItem,
  FilterTitle,
  Selected,
  Settings,
  Overlay,
  WrapSettings,
  CampaignStyle,
  SettingsWrap,
};
