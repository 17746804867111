import * as _ from 'lodash';
import { UtmTagReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: UtmTagReducer = {
  createUtmTag: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  updateUtmTag: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  deleteUtmTag: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  listUtmTag: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  publishUtmTag: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const utmTagReducer = createReducer(initialState, {
  GET_UTM_TAG_FULFILLED: (state, action) => {
    state.listUtmTag.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.listUtmTag.data = _.get(action, 'payload.data');
  },
  GET_UTM_TAG_PENDING: (state) => {
    state.listUtmTag.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_UTM_TAG_REJECTED: (state, action) => {
    state.listUtmTag.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  CREATE_UTM_TAG_FULFILLED: (state, action) => {
    state.createUtmTag.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.createUtmTag.data = _.get(action, 'payload.data');
  },
  CREATE_UTM_TAG_PENDING: (state) => {
    state.createUtmTag.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  CREATE_UTM_TAG_REJECTED: (state, action) => {
    state.createUtmTag.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  DELETE_UTM_TAG_FULFILLED: (state, action) => {
    state.deleteUtmTag.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.deleteUtmTag.data = _.get(action, 'payload.data');
  },
  DELETE_UTM_TAG_PENDING: (state) => {
    state.deleteUtmTag.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  DELETE_UTM_TAG_REJECTED: (state, action) => {
    state.deleteUtmTag.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  UPDATE_UTM_TAG_FULFILLED: (state, action) => {
    state.updateUtmTag.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.updateUtmTag.data = _.get(action, 'payload.data');
  },
  UPDATE_UTM_TAG_PENDING: (state) => {
    state.updateUtmTag.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  UPDATE_UTM_TAG_REJECTED: (state, action) => {
    state.updateUtmTag.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  PUBLISH_UTM_TAG_FULFILLED: (state, action) => {
    state.publishUtmTag.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.publishUtmTag.data = _.get(action, 'payload.data');
  },
  PUBLISH_UTM_TAG_PENDING: (state) => {
    state.publishUtmTag.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  PUBLISH_UTM_TAG_REJECTED: (state, action) => {
    state.publishUtmTag.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default utmTagReducer;
