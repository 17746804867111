import * as _ from 'lodash';
import { UpdateBusinessReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: UpdateBusinessReducer = {
  updateBusiness: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  deleteLogo: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const updateBusinessReducer = createReducer(initialState, {
  UPDATE_BUSINESS_FULFILLED: (state, action) => {
    state.updateBusiness.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.updateBusiness.data = _.get(action, 'payload.data');
  },
  UPDATE_BUSINESS_PENDING: (state) => {
    state.updateBusiness.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  UPDATE_BUSINESS_REJECTED: (state, action) => {
    state.updateBusiness.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },

  DELETE_BUSINESS_LOGO_FULFILLED: (state, action) => {
    state.deleteLogo.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.deleteLogo.data = _.get(action, 'payload.data');
  },
  DELETE_BUSINESS_LOGO_PENDING: (state) => {
    state.deleteLogo.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  DELETE_BUSINESS_LOGO_REJECTED: (state, action) => {
    state.deleteLogo.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default updateBusinessReducer;
