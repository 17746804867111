import styled from 'styled-components';

const Wrap = styled.div``;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: white;
  flex-direction: column;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
  .separator {
    width: 3rem;
    height: 0.15rem;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    background: rgb(187, 189, 192);
  }
  .description {
    min-height: 50px;
  }
  p {
    text-transform: lowercase;
    &.title {
      font-weight: 600;
      font-size: 18px;
    }
    &:first-letter {
      text-transform: uppercase;
    }
  }
`;
const Status = styled.div`
  padding: 4px 8px;
  border-radius: 20px;
  font-weight: 600;
  width: 110px;
  font-size: 14px;
  text-align: center;
`;
const Body = styled.div`
  padding: 16px;
`;
const Footer = styled.div`
  padding: 16px;
  border-top: 1px solid rgb(226, 232, 240);
  background: rgb(248, 250, 252);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;

const Date = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;
const AdvBanner = styled.div`
  background: linear-gradient(
    0deg,
    rgba(215, 241, 255, 1) 0%,
    rgba(227, 245, 255, 1) 1%,
    rgba(255, 255, 255, 1) 100%
  );
  height: 500px;
  padding: 16px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    margin-top: 16px;
    width: 300px;
  }
  .campaign-link {
    color: #0b2641;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
const Banner = styled.div`
  background-color: white;
  display: flex;
  border-radius: 6px;
`;
const ImgWrap = styled.div`
  flex-basis: 500px;
  padding: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const TextWrap = styled.div`
  color: white;
  flex: 1;
  overflow: hidden;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(227, 245, 255, 1) 31%,
    rgba(215, 241, 255, 1) 100%
  );
  padding: 32px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
const PaginationWrap = styled.div`
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  display: flex;
  justify-content: flex-end;
`;
export default {
  Wrap,
  Card,
  Body,
  Footer,
  Date,
  Status,
  AdvBanner,
  Banner,
  ImgWrap,
  TextWrap,
  PaginationWrap,
};
