import * as React from 'react';

const users = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 9.500000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M63,81 L63,72 C63,67.22595 61.1037,62.64765 57.7278,59.2722 C54.35235,55.8963 49.77405,54 45,54 L18,54 C13.226085,54 8.64774,55.8963 5.272065,59.2722 C1.896435,62.64765 0,67.22595 0,72 L0,81"></path>
        <path d="M31.5,36 C41.44095,36 49.5,27.94113 49.5,18 C49.5,8.05887 41.44095,0 31.5,0 C21.55887,0 13.5,8.05887 13.5,18 C13.5,27.94113 21.55887,36 31.5,36 Z"></path>
        <path d="M90,80.99955 L90,71.99955 C89.99685,68.0112 88.6698,64.13715 86.2263,60.9849 C83.7828,57.83265 80.36145,55.58175 76.5,54.58455"></path>
        <path d="M63,0.58446 C66.8718,1.57581 70.3035,3.82761 72.7542,6.984855 C75.2049,10.1421 76.5351,14.025195 76.5351,18.02196 C76.5351,22.018725 75.2049,25.90182 72.7542,29.059065 C70.3035,32.2164 66.8718,34.4682 63,35.45955"></path>
      </g>
    </g>
  );
};
export default users;
