import React from 'react';
import {
  Button,
  ErrorDisplay,
  Loader,
  Typography,
  Textarea,
  Notification,
} from '../../components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useFormik } from 'formik';
import * as _ from 'lodash';

interface CredentialsProps {
  business: any;
  getUser: Function;
  updateBusiness: Function;
  user: any;
}
export const Credentials: React.FC<CredentialsProps> = (props) => {
  const { business, getUser, updateBusiness, user } = props;

  const { error, loading, done } = business.updateBusiness.status;
  const [refresh, setRefresh] = React.useState(false);
  if (refresh === true) {
    setTimeout(() => {
      setRefresh(false);
    }, 3000);
  }
  const userData = _.get(user, 'data.business');
  const waterCred = _.get(userData, 'waterCredentials');
  const carbonCred = _.get(userData, 'carbonCredentials');
  const energyCred = _.get(userData, 'energyCredentials');
  const impactCred = _.get(userData, 'environmentCredentials');

  const formik = useFormik({
    initialValues: {
      carbonCredentials: carbonCred ? carbonCred : '',
      waterCredentials: waterCred ? waterCred : '',
      energyCredentials: energyCred ? energyCred : '',
      environmentCredentials: impactCred ? impactCred : '',
    },

    onSubmit: (values, { setSubmitting, resetForm }) => {
      updateBusiness(
        values,
        () => {
          setSubmitting(false);

          resetForm();
        },
        () => {
          setSubmitting(false);
        }
      );
      setTimeout(() => {
        getUser();
      }, 500);
      setSubmitting(false);
    },
  });

  if (loading) {
    return (
      <div style={{ maxHeight: '150px' }}>
        <Loader />
      </div>
    );
  }

  if (error) {
    return <ErrorDisplay />;
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Row>
          {' '}
          <Col md={12} xs={12} sm={12}>
            <Typography size="2" text="Company credentials" />
            <Typography
              size="p"
              style={{ fontSize: '15px' }}
              text="Upload your company green credentials and be recognized as one of the good ones.  "
            />
            <Row>
              <Col md={6} xs={12} sm={12}>
                <Textarea
                  id="waterCredentials"
                  placeholder="Type in here"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Reduction of water use"
                  value={formik.values.waterCredentials}
                />
              </Col>
              <Col md={6} xs={12} sm={12}>
                <Textarea
                  id="energyCredentials"
                  placeholder="Type in here"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Reduction of energy use"
                  value={formik.values.energyCredentials}
                />
              </Col>
              <Col md={6} xs={12} sm={12}>
                <Textarea
                  id="carbonCredentials"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label="Reduction of carbon produced"
                  placeholder="Type in here"
                  value={formik.values.carbonCredentials}
                />
              </Col>
              <Col md={6} xs={12} sm={12}>
                <Textarea
                  id="environmentCredentials"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Type in here"
                  label="Reduction of  impact on the environment"
                  value={formik.values.environmentCredentials}
                />
              </Col>
              {error ? (
                <Col md={12} sm={12} xs={12}>
                  <Notification isClose title={error} theme="Error" />
                </Col>
              ) : null}
              {done && refresh ? (
                <Col md={12} sm={12} xs={12}>
                  <Notification
                    isClose
                    title={'Success, your stall has been created.'}
                    theme="Success"
                  />
                </Col>
              ) : null}
              <Col
                md={12}
                xs={12}
                sm={12}
                style={{
                  marginTop: 'auto',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button disabled={formik.isSubmitting || loading}>
                  Update
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default Credentials;
