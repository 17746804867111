import styled, { keyframes, css } from 'styled-components';

export interface WrapProps {
  isDesktop?: boolean;
}
const introAnimation = keyframes`
to {
  opacity:1;
  transform:translate(0)
 }

`;
const Wrap = styled.div<WrapProps>`
  background-color: #0784ab;
  overflow: hidden;
  height: 100vh;
  width: 100%;

  .introY-top {
    z-index: 0;
    opacity: 0;
    position: relative;
    transform: translateY(-50px);
    animation: 0.4s ${introAnimation} ease-in-out 0.33333s;
    animation-fill-mode: forwards;
  }
  .introY-bottom {
    z-index: 0;
    opacity: 0;
    position: relative;
    transform: translateY(50px);
    animation: 0.4s ${introAnimation} ease-in-out 0.33333s;
    animation-fill-mode: forwards;
  }
  .introX-left {
    z-index: 0;
    opacity: 0;
    position: relative;
    transform: translateX(-50px);
    animation: 0.4s ${introAnimation} ease-in-out 0.33333s;
    animation-fill-mode: forwards;
  }
  .introX-right {
    z-index: 0;
    opacity: 0;
    position: relative;
    transform: translateX(50px);
    animation: 0.4s ${introAnimation} ease-in-out 0.33333s;
    animation-fill-mode: forwards;
  }
  ${(props) => {
    if (props.isDesktop) {
      return desktopStyles;
    }
  }}
`;

const FormWrap = styled.div`
  background: #f1f5f9;
  height: 100%;
  border-top-left-radius: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const HeadLineWrap = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 32px;
`;
const TextContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
`;
const MobileForm = styled.div`
  display: flex;
  max-width: 500px;
  margin-top: auto;
  margin-bottom: auto;
  justify-content: center;
  background: #f1f5f9;
  padding: 32px;
  width: 100%;
  border-radius: 16px;
`;
const MobileWrap = styled.div``;
const desktopStyles = css`
  overflow: hidden;
  height: 100vh;
  width: 100%;
`;
export default {
  Wrap,
  MobileWrap,
  FormWrap,
  HeadLineWrap,
  TextContainer,
  MobileForm,
};
