import { connect } from 'react-redux';
import { createAdmin, getBusinessUser } from '../../../actions/admin';
import { AppState } from '../../../interfaces';
import CreateUser from './CreateUser';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    admin: state.adminReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createAdmin: (credentials) => dispatch(createAdmin(credentials)),
    getBusinessUser: () => dispatch(getBusinessUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUser);
