import { connect } from 'react-redux';
import { getEndpointData } from '../../../actions/actionsShared';
import Users from './Users';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state) => {
  return {
    genericPage: state.genericPageReducer,
    metadata: state.metadataReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEndpointData: (meta) => dispatch(getEndpointData(meta, 'user')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Users);
