import React from 'react';
import Styled from './NoFeature.style';
import { Typography, Href } from '../index';

export const NoFeature: React.FC = () => {
  return (
    <Styled.Banner>
      <Styled.ImgWrap>
        <img
          src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/marketing.svg"
          alt="Marketing"
        />
      </Styled.ImgWrap>
      <Styled.TextWrap>
        <Typography
          size="1"
          text="Feature not available"
          style={{ marginTop: '0px' }}
        />
        <Typography
          size="3"
          text="Contact our sales team to enable this feature."
          style={{ marginTop: '0px' }}
        />
        <Href
          icon="send"
          text="Contact us"
          style={{ marginTop: '40px' }}
          href="mailto:claudiu.soare@savewatersavemoney.com"
        />
      </Styled.TextWrap>
    </Styled.Banner>
  );
};

export default NoFeature;
