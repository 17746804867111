import React from 'react';
import Styled from './TopNav.style';
import _ from 'lodash';
import { Icon, Breadcrumbs, Button } from '../index';
import { useNavigate } from 'react-router-dom';

interface TopNavProps {
  onClickMaximise: Function;
  maximise: boolean;
  email: string;
  adminRole: string;
  impersonating: boolean;
  exitUser: Function;
}

export const TopNav: React.FC<TopNavProps> = (props) => {
  const { email, adminRole, exitUser, impersonating } = props;

  const fullScreen = () => {
    return (
      <Styled.FullScreen
        onClick={() => {
          const elem = document.documentElement;
          if (elem.requestFullscreen) {
            elem.requestFullscreen();
          }
        }}
      >
        <Icon icon="maximise" size={20} />
      </Styled.FullScreen>
    );
  };
  const [isDropdownVisible, setDropdownVisible] = React.useState(false);
  const handleMouseEnter = () => {
    setDropdownVisible(true);
  };
  const navigate = useNavigate();
  const handleMouseLeave = () => {
    setDropdownVisible(false);
  };
  return (
    <Styled.TopNav
      style={
        props.maximise
          ? { left: '300px', width: 'calc(100% -300px)' }
          : { left: '70px', width: 'calc(100% -70px)' }
      }
    >
      <Styled.NavContainer>
        <Styled.NavMenu>
          <li className="hamburger" onClick={() => props.onClickMaximise()}>
            <div style={{ height: '70px', lineHeight: '70px' }}>
              <Icon icon="hamburgerLeft" size={20} />
            </div>
          </li>
          <li style={{ flex: '1' }}>
            <Breadcrumbs />
          </li>
          {adminRole === 'ROLE_SUPER_ADMIN' || !impersonating ? null : (
            <li style={{ marginRight: '16px' }}>
              <Button
                theme="WHITE"
                onClick={() => {
                  exitUser();
                  setTimeout(() => {
                    window.location.reload();
                  }, 300);
                  navigate('/');
                }}
              >
                Switch to admin
              </Button>
            </li>
          )}

          <li>{fullScreen()}</li>

          <li onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <Styled.Avatar>
              <Icon icon="user" size={16} />
            </Styled.Avatar>
            <Styled.Dropdown className={isDropdownVisible ? 'active' : ''}>
              <li className="top">
                You are signed in as
                <br />{' '}
                <div>
                  <b>{email}</b>
                </div>
              </li>
              <li
                onClick={() => {
                  localStorage.removeItem('swsmUser');
                  localStorage.removeItem('user');
                  localStorage.removeItem('metadata');
                  localStorage.removeItem('campaignId');
                  navigate('/login');
                }}
              >
                <Icon
                  icon="power"
                  size={16}
                  style={{
                    marginRight: '8px',
                  }}
                />
                <span>Log out</span>
              </li>
            </Styled.Dropdown>
          </li>
        </Styled.NavMenu>
      </Styled.NavContainer>
    </Styled.TopNav>
  );
};

export default TopNav;
