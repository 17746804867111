import moment from 'moment';

export default function groupByPeriod(data, period) {
  if (data.length < 1) {
    return [];
  }

  let currentPeriod = null;
  let periodName = null;
  let prevTimePeriod = moment(data[0].name).format(period);
  const newData = [];
  let periodSummary = {};
  const keyMatrix = [];

  const recalcAverage = (accumulator, currentValue) => {
    const acumulated = {
      // name: periodName,
    };

    keyMatrix.forEach((key) => {
      acumulated[key] = Math.round(accumulator[key] + currentValue[key]);
    });

    periodSummary = {
      ...periodSummary,
      ...acumulated,
    };
  };

  function initPeriod(el) {
    periodSummary = {
      ...el,
      name: periodName,
    };
  }

  function pushPeriod() {
    newData.push(periodSummary); // push whatever we have to array
  }

  function samePeriod(el, index) {
    if (index === 0) {
      // INIT FIRST WEEK
      initPeriod(el);
    } else {
      // console.log('calculate on date: ', periodName);
      recalcAverage(el, periodSummary);
    }
  }

  function newPeriod(el, index) {
    // save old period
    pushPeriod();
    // and init new one
    initPeriod(el);
  }

  data.forEach((el, index) => {
    // debugger;

    if (keyMatrix.length === 0) {
      Object.keys(el).forEach((key) => {
        if (typeof el[key] === 'number') {
          keyMatrix.push(key);
        }
      });
    }

    currentPeriod = moment(el.name).format(period);
    periodName = moment(el.name).format('YYYY-MM-DD');

    if (currentPeriod === prevTimePeriod) {
      samePeriod(el, index);
    } else {
      newPeriod(el, index);
    }
    prevTimePeriod = moment(el.name).format(period);

    if (index === data.length - 1) {
      pushPeriod();
    }
  });

  return newData;
}
