import _ from 'lodash';
import React from 'react';
import Styled from './UtmList.style';
import {
  Icon,
  Popup,
  Button,
  Typography,
  Input,
  ErrorDisplay,
  Loader,
} from '../../../../../components';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-styled-flexboxgrid';
import ReactTooltip from 'react-tooltip';
import ReactPaginate from 'react-paginate';
interface UtmListProps {
  deleteUtmTag: Function;
  utmTags: any;
  updateUtmTag: Function;
  publishUtmTag: Function;
  getUtmTagList: Function;
}
export const UtmList: React.FC<UtmListProps> = (props) => {
  const { deleteUtmTag, publishUtmTag, utmTags, updateUtmTag, getUtmTagList } =
    props;
  const [popup, setPopup] = React.useState(false);

  const [content, setContent] = React.useState('');
  const [copy, setCopy] = React.useState('');
  const [copyCode, setCopyCode] = React.useState('');
  const { error, loading, done } = _.get(utmTags, 'listUtmTag.status');
  const utmTagList = _.get(utmTags, 'listUtmTag.data');

  const PageSize = 3;

  const [pageNumber, setPageNumber] = React.useState(0);
  const ItemsPerPage = 3;
  const pagesVisited = pageNumber * ItemsPerPage;
  const displayUsers =
    utmTagList && utmTagList.slice(pagesVisited, pagesVisited + ItemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const pageCount = Math.ceil(utmTagList && utmTagList.length / ItemsPerPage);

  if (copy !== '') {
    setTimeout(() => {
      setCopy('');
    }, 1500);
  }

  if (copyCode !== '') {
    setTimeout(() => {
      setCopyCode('');
    }, 1500);
  }
  React.useEffect(() => {
    getUtmTagList();
  }, []);

  const [currentPage, setCurrentPage] = React.useState(1);
  const currentTableData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return utmTagList && utmTagList.slice(firstPageIndex, lastPageIndex);
  }, [PageSize, currentPage, utmTagList]);

  const renderPopupContent = (id) => {
    const utmTag = _.find(utmTagList, function (o) {
      return o.id == id;
    });

    const getSource = _.get(utmTag, 'source');
    const getDescription = _.get(utmTag, 'description');
    const getCampaign = _.get(utmTag, 'campaign');
    const getMedium = _.get(utmTag, 'medium');
    const getTerm = _.get(utmTag, 'term');
    const getContent = _.get(utmTag, 'content');

    if (content === 'publish') {
      return (
        <>
          <Typography size="3" text="Publish this UTM tag?" />
          <Typography
            text="Once you publish it you wont be able to change or delete it until the campaign expires."
            size="p"
            style={{ textAlign: 'center' }}
          />
          <div>
            <Button
              theme="WHITE"
              style={{ marginRight: '16px' }}
              onClick={() => {
                setPopup(!popup);
                publishUtmTag(
                  id,
                  utmTagList,
                  () => {
                    setPopup(!popup);
                    setContent('');
                  },
                  () => {
                    console.log('failed');
                  }
                );
                setTimeout(() => {
                  getUtmTagList();
                }, 100);
              }}
            >
              Yes
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                setPopup(!popup);
                setContent('');
              }}
            >
              No
            </Button>
          </div>
        </>
      );
    } else if (content === 'edit') {
      return (
        <Formik
          initialValues={{
            source: getSource,
            description: getDescription,
            medium: getMedium,
            term: getTerm,
            content: getContent,
            campaign: getCampaign,
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            updateUtmTag(
              id,
              values,
              () => {
                setSubmitting(false);

                setPopup(!popup);
                setContent('');
                resetForm();
              },
              () => {
                setSubmitting(false);
                console.log('failed');
              }
            );
          }}
        >
          {({
            values,
            isSubmitting,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleBlur,
          }) => (
            <Form onSubmit={handleSubmit} style={{ padding: '16px' }}>
              <Row>
                <Col md={12} sm={12} xs={12}>
                  <Typography text="Edit UTM Tag" size="3" />
                </Col>
                <Col md={6} sm={12} xs={12}>
                  <Input
                    type="text"
                    placeholder="Name"
                    id="campaign"
                    label="Name"
                    value={values.campaign}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col md={6} sm={12} xs={12}>
                  <Input
                    type="text"
                    placeholder="Source"
                    label="Source"
                    id="source"
                    value={values.source}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col md={6} sm={12} xs={12}>
                  <Input
                    type="text"
                    placeholder="Medium"
                    label="Medium"
                    id="medium"
                    value={values.medium}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col md={6} sm={12} xs={12}>
                  <Input
                    type="text"
                    placeholder="Term"
                    label="Term"
                    id="term"
                    value={values.term}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
                <Col md={6} sm={12} xs={12}>
                  <Input
                    type="text"
                    placeholder="Content"
                    label="Content"
                    id="content"
                    value={values.content}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </Col>
              </Row>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '16px',
                }}
              >
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    setPopup(!popup);
                    setContent('');
                  }}
                  style={{ marginRight: '16px' }}
                >
                  Cancel
                </Button>
                <Button theme="WHITE" type="submit">
                  Update
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '16px',
        }}
      >
        <Typography size="3" text="Delete this campaign?" />
        <div style={{ flex: 1 }}>
          <Typography
            text="All your settings for this campaign will be lost"
            size="p"
            style={{ textTransform: 'italic' }}
          />
        </div>
        <div>
          <Button
            theme="WHITE"
            style={{ marginRight: '16px' }}
            onClick={() => {
              setPopup(!popup);
              deleteUtmTag(id);
            }}
          >
            Yes
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              setPopup(!popup);
              setContent('');
            }}
          >
            No
          </Button>
        </div>
      </div>
    );
  };
  const tableItems = () => {
    return _.map(displayUsers, (el, index) => {
      return (
        <tr key={index}>
          <td width="100%" className="item">
            <Styled.NameWrap>
              <Styled.TextWrap>
                <p>{el.campaign}</p>
                <Styled.ValueWrap>
                  <Styled.ValueItem>
                    <b>Source:</b> {el.source} <b>Content:</b> {el.content}
                    <br />
                    <b>Term:</b> {el.term} <b>Medium:</b> {el.medium}
                  </Styled.ValueItem>
                </Styled.ValueWrap>
              </Styled.TextWrap>
            </Styled.NameWrap>
          </td>

          <td className="text-right item">
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {el.published ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      minWidth: '128px',
                      color:
                        el.id === copy
                          ? 'rgb(22, 101, 52)'
                          : 'rgb(30, 64, 175)',
                      cursor: 'pointer',
                      borderRadius: '6px',
                      background:
                        el.id === copy
                          ? 'rgb(220, 252, 231)'
                          : 'rgb(219, 234, 254)',
                      padding: '6px 12px',
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText(el.url);
                      setCopy(el.id);
                    }}
                  >
                    <Icon
                      icon={el.id === copy ? 'check' : 'link'}
                      size={20}
                      style={{ marginRight: '8px' }}
                    />{' '}
                    {el.id === copy ? 'COPIED' : 'COPY URL'}
                  </div>
                  {/* {el.shortCode === null ? null : (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: '6px',
                        justifyContent: 'center',
                        color:
                          el.id === copyCode
                            ? 'rgb(22, 101, 52)'
                            : 'rgb(30, 64, 175)',
                        cursor: 'pointer',
                        borderRadius: '6px',
                        background:
                          el.id === copyCode
                            ? 'rgb(220, 252, 231)'
                            : 'rgb(219, 234, 254)',
                        padding: '6px 12px',
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText(el.shortCode);
                        setCopyCode(el.id);
                      }}
                    >
                      <Icon
                        icon={el.id === copyCode ? 'check' : 'link'}
                        size={20}
                        style={{ marginRight: '8px' }}
                      />{' '}
                      {el.id === copyCode ? 'COPIED CODE' : 'COPY CODE'}
                    </div>
                  )} */}
                </div>
              ) : (
                <>
                  <Styled.IconDelete
                    data-tip="Delete UTM Tag"
                    data-place="top"
                    data-type="info"
                    data-for="delete-tag"
                    onClick={() => {
                      setPopup(!popup);
                      setContent('delete');
                    }}
                    style={{ marginRight: '8px' }}
                  >
                    <Icon
                      icon="trash"
                      colorPrimary="rgb(157, 23, 23)"
                      size={20}
                    />
                    <ReactTooltip
                      id="delete-tag"
                      className="extraClass"
                      effect="solid"
                    />
                  </Styled.IconDelete>
                  <Styled.IconEdit
                    data-tip="Edit UTM Tag"
                    data-place="top"
                    data-type="info"
                    data-for="edit-tag"
                    onClick={() => {
                      setPopup(!popup);
                      setContent('edit');
                    }}
                    style={{ marginRight: '8px' }}
                  >
                    <Icon
                      icon="check"
                      colorPrimary="rgb(22, 101, 52)"
                      size={20}
                    />
                    <ReactTooltip
                      id="edit-tag"
                      className="extraClass"
                      effect="solid"
                    />
                  </Styled.IconEdit>
                  <Styled.IconPublish
                    data-tip="Publish UTM Tag"
                    data-place="top"
                    data-type="info"
                    data-for="publish-tag"
                    onClick={() => {
                      setPopup(!popup);
                      setContent('publish');
                    }}
                  >
                    <Icon
                      icon="send"
                      colorPrimary="rgb(157, 23, 77)"
                      size={20}
                    />
                    <ReactTooltip
                      id="publish-tag"
                      className="extraClass"
                      effect="solid"
                    />
                  </Styled.IconPublish>
                  <Popup open={popup} handleClick={() => setPopup(!popup)}>
                    {renderPopupContent(el.id)}
                  </Popup>
                </>
              )}
            </div>
          </td>
        </tr>
      );
    });
  };
  if (error) {
    return <ErrorDisplay />;
  }

  if (loading && !done) {
    return <Loader />;
  }
  return (
    <Styled.Wrap style={{ overflowX: 'auto' }}>
      <table
        style={{
          borderSpacing: '0',
          borderCollapse: 'separate',
          width: '100%',
          overflow: 'scroll',
          minHeight: '317px',
          display: 'flex',
          flexDirection: 'column',
          tableLayout: 'fixed',
          minWidth: '500px',
        }}
      >
        <thead>
          <tr>
            <td width="100%" className="item">
              Name
            </td>
            <td className="text-right item">Actions</td>
          </tr>
        </thead>
        <tbody>{tableItems()}</tbody>
      </table>
      <Styled.PaginationWrap>
        <ReactPaginate
          previousLabel={'<'}
          nextLabel={'>'}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={'paginationBttns'}
          previousLinkClassName={'previousBttn'}
          nextLinkClassName={'nextBttn'}
          disabledClassName={'paginationDisabled'}
          activeClassName={'paginationActive'}
          forcePage={pageNumber}
        />
      </Styled.PaginationWrap>
    </Styled.Wrap>
  );
};

export default UtmList;
