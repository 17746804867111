import * as React from 'react';

const star = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 7.205000)"
        fill={colorSecondary}
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <polygon points="45 0 58.905 28.17 90 32.715 67.5 54.63 72.81 85.59 45 70.965 17.19 85.59 22.5 54.63 0 32.715 31.095 28.17"></polygon>
      </g>
    </g>
  );
};
export default star;
