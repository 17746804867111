import { connect } from 'react-redux';
import {
  createBranding,
  updateBranding,
  getBranding,
} from '../../../../actions/branding';
import { getUser } from '../../../../actions/user';
import { AppState } from '../../../../interfaces';
import Branding from './Branding';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    business: state.updateBusinessReducer,
    user: state.userReducer,
    branding: state.brandingReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateBranding: (data, onSuccess, onFailed) =>
      dispatch(updateBranding(data, onSuccess, onFailed)),
    createBranding: (data, onSuccess, onFailed) =>
      dispatch(createBranding(data, onSuccess, onFailed)),
    getUser: () => dispatch(getUser()),
    getBranding: () => dispatch(getBranding()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Branding);
