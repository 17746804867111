import * as React from 'react';

const droplet = (props: any) => {
  const { colorPrimary, colorSecondary } = props;

  return (
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(12.000000, 8.000000)">
        <path
          d="M44.6309,82.1016 C30.3489,82.1016 18.7299,70.4826 18.7299,56.2006 C18.7299,43.2706 40.1079,9.7436 44.6309,2.7956 C49.1539,9.7436 70.5319,43.2686 70.5319,56.2006 C70.5319,70.4826 58.9129,82.1016 44.6309,82.1016"
          fill={colorPrimary}
        ></path>
        <path
          d="M43.7988,0.4175 C42.6938,2.0765 16.7298,41.2125 16.7298,56.2005 C16.7298,71.5845 29.2458,84.1015 44.6308,84.1015 C60.0158,84.1015 72.5318,71.5845 72.5318,56.2005 C72.5318,41.2125 46.5688,2.0765 45.4628,0.4175 C45.0918,-0.1395 44.1698,-0.1395 43.7988,0.4175 M44.6308,82.1015 C30.3488,82.1015 18.7298,70.4825 18.7298,56.2005 C18.7298,43.2715 40.1078,9.7435 44.6308,2.7955 C49.1538,9.7435 70.5318,43.2695 70.5318,56.2005 C70.5318,70.4825 58.9128,82.1015 44.6308,82.1015"
          fill={colorSecondary}
        ></path>
        <path
          d="M44.0605,72.9864 C44.0605,73.5384 44.5075,73.9864 45.0605,73.9864 C50.5785,73.9864 54.9535,72.3574 58.0625,69.1454 C63.1385,63.9004 62.9545,56.3874 62.9435,56.0694 C62.9255,55.5284 62.4825,55.1024 61.9455,55.1024 C61.9335,55.1024 61.9225,55.1024 61.9125,55.1034 C61.3595,55.1214 60.9265,55.5834 60.9455,56.1354 C60.9475,56.2044 61.1195,63.1114 56.6245,67.7544 C53.9065,70.5624 50.0155,71.9864 45.0605,71.9864 C44.5075,71.9864 44.0605,72.4344 44.0605,72.9864"
          fill={colorSecondary}
        ></path>
        <path
          d="M74.3467,8.2041 L69.1467,8.2041 L69.1467,3.0041 C69.1467,2.4521 68.6997,2.0041 68.1467,2.0041 C67.5937,2.0041 67.1467,2.4521 67.1467,3.0041 L67.1467,8.2041 L61.9467,8.2041 C61.3937,8.2041 60.9467,8.6521 60.9467,9.2041 C60.9467,9.7561 61.3937,10.2041 61.9467,10.2041 L67.1467,10.2041 L67.1467,15.4041 C67.1467,15.9561 67.5937,16.4041 68.1467,16.4041 C68.6997,16.4041 69.1467,15.9561 69.1467,15.4041 L69.1467,10.2041 L74.3467,10.2041 C74.8997,10.2041 75.3467,9.7561 75.3467,9.2041 C75.3467,8.6521 74.8997,8.2041 74.3467,8.2041"
          fill={colorSecondary}
        ></path>
        <path
          d="M13.4004,71.9864 L8.2004,71.9864 L8.2004,66.7864 C8.2004,66.2344 7.7534,65.7864 7.2004,65.7864 C6.6474,65.7864 6.2004,66.2344 6.2004,66.7864 L6.2004,71.9864 L1.0004,71.9864 C0.4474,71.9864 0.0004,72.4344 0.0004,72.9864 C0.0004,73.5384 0.4474,73.9864 1.0004,73.9864 L6.2004,73.9864 L6.2004,79.1864 C6.2004,79.7384 6.6474,80.1864 7.2004,80.1864 C7.7534,80.1864 8.2004,79.7384 8.2004,79.1864 L8.2004,73.9864 L13.4004,73.9864 C13.9534,73.9864 14.4004,73.5384 14.4004,72.9864 C14.4004,72.4344 13.9534,71.9864 13.4004,71.9864"
          fill={colorSecondary}
        ></path>
      </g>
    </g>
  );
};
export default droplet;
