import React from 'react';
import Button from '../Button';
import Styled from './TimePeriodSwitcher.style';
interface TimePeriodSwitcherProps {
  changeDisplayPeriod: Function;
  active: 'day' | 'week' | 'month';
}

const TimePeriodSwitcher = (props: TimePeriodSwitcherProps) => {
  const { changeDisplayPeriod = () => void 0, active = 'day' } = props;

  return (
    <Styled.Wrap>
      <Styled.Toggle
        data-test="changeDisplayPeriod-day"
        onClick={() => {
          changeDisplayPeriod('day');
        }}
        className={active === 'day' ? 'active' : ''}
      >
        Day
      </Styled.Toggle>
      <Styled.Toggle
        data-test="changeDisplayPeriod-week"
        onClick={() => {
          changeDisplayPeriod('week');
        }}
        className={active === 'week' ? 'active' : ''}
      >
        Week
      </Styled.Toggle>
      <Styled.Toggle
        data-test="changeDisplayPeriod-month"
        onClick={() => {
          changeDisplayPeriod('month');
        }}
        className={active === 'month' ? 'active' : ''}
      >
        Month
      </Styled.Toggle>
    </Styled.Wrap>
  );
};

export default TimePeriodSwitcher;
