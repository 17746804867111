import * as _ from 'lodash';
import { MarketplaceReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: MarketplaceReducer = {
  createMarketplaceBanner: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  updateMarketplaceBanner: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  deleteMarketplaceBanner: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  listMarketplaceBanner: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const marketplaceReducer = createReducer(initialState, {
  CREATE_MARKETPLACE_BANNER_FULFILLED: (state, action) => {
    state.createMarketplaceBanner.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.createMarketplaceBanner.data = _.get(action, 'payload.data');
  },
  CREATE_MARKETPLACE_BANNER_PENDING: (state) => {
    state.createMarketplaceBanner.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  CREATE_MARKETPLACE_BANNER_REJECTED: (state, action) => {
    state.createMarketplaceBanner.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.detail'),
      done: false,
    };
  },
  UPDATE_MARKETPLACE_BANNER_FULFILLED: (state, action) => {
    state.updateMarketplaceBanner.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.updateMarketplaceBanner.data = _.get(action, 'payload.data');
  },
  UPDATE_MARKETPLACE_BANNER_PENDING: (state) => {
    state.updateMarketplaceBanner.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  UPDATE_MARKETPLACE_BANNER_REJECTED: (state, action) => {
    state.updateMarketplaceBanner.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.detail'),
      done: false,
    };
  },
  LIST_MARKETPLACE_BANNER_FULFILLED: (state, action) => {
    state.listMarketplaceBanner.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.listMarketplaceBanner.data = _.get(action, 'payload.data');
  },
  LIST_MARKETPLACE_BANNER_PENDING: (state) => {
    state.listMarketplaceBanner.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  LIST_MARKETPLACE_BANNER_REJECTED: (state, action) => {
    state.listMarketplaceBanner.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  DELETE_MARKETPLACE_BANNER_FULFILLED: (state, action) => {
    state.deleteMarketplaceBanner.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.deleteMarketplaceBanner.data = _.get(action, 'payload.data');
  },
  DELETE_MARKETPLACE_BANNER_PENDING: (state) => {
    state.deleteMarketplaceBanner.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  DELETE_MARKETPLACE_BANNER_REJECTED: (state, action) => {
    state.deleteMarketplaceBanner.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default marketplaceReducer;
