import styled from 'styled-components';

const Span = styled.span`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
const Link = styled.a`
  color: #0b2641;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-decoration: underline;
  transition: all 100ms ease-in-out;
  &:hover {
    text-decoration: underline;
    transition: all 100ms ease-in-out;
  }
`;
export default { Span, Link };
