import _ from 'lodash';
import React from 'react';
import { Button, Input, Notification } from '../../../../../components';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-styled-flexboxgrid';

interface UtmCreateProps {
  getUtmTagList: Function;
  createUtmTag: Function;

  utmTags: any;
}
export const UtmCreate: React.FC<UtmCreateProps> = (props) => {
  const { createUtmTag, getUtmTagList, utmTags } = props;

  const { error, loading, done } = _.get(utmTags, 'createUtmTag.status');
  const renderUtmForm = () => {
    return (
      <Formik
        initialValues={{
          source: '',
          description: '',
          medium: '',
          term: '',
          content: '',
          campaign: '',
        }}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          createUtmTag(
            values,
            () => {
              setSubmitting(false);
              getUtmTagList();
              resetForm();
            },
            () => {
              setSubmitting(false);
              console.log('failed');
            }
          );
        }}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6} sm={12} xs={12}>
                <Input
                  type="text"
                  placeholder="Name"
                  id="campaign"
                  label="Name"
                  icon="info"
                  value={values.campaign}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip="This tracks which marketing efforts or campaigns attracted
                  your visitors to your site."
                />
              </Col>
              <Col md={6} sm={12} xs={12}>
                <Input
                  type="text"
                  placeholder="Source"
                  icon="info"
                  label="Source"
                  id="source"
                  value={values.source}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip="This parameter allows you to track the source of your
                  traffic.(E.g. facebook, google, twitter)"
                />
              </Col>
              <Col md={6} sm={12} xs={12}>
                <Input
                  type="text"
                  placeholder="Medium"
                  label="Medium"
                  id="medium"
                  icon="info"
                  value={values.medium}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip="This parameter tracks what marketing channels your visitors
                  came from. Popular marketing channels include social media,
                  organic search, PPC, email, and affiliates."
                />
              </Col>
              <Col md={6} sm={12} xs={12}>
                <Input
                  type="text"
                  placeholder="Term"
                  label="Term"
                  id="term"
                  icon="info"
                  value={values.term}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip="The term UTM parameter is used to track keyword terms. This
                  parameter is typically used for paid search ads."
                />
              </Col>
              <Col md={6} sm={12} xs={12}>
                <Input
                  type="text"
                  placeholder="Content"
                  label="Content"
                  id="content"
                  icon="info"
                  value={values.content}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  tooltip="The content parameter is usually optional and only used if you
                  have multiple links that go to the same URL. For instance, you
                  may have two CTAs in one email."
                />
              </Col>
            </Row>
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
              }}
            >
              <Button type="submit" disabled={isSubmitting || loading}>
                Create utm tag
              </Button>
            </div>
            {error || done ? (
              <Row style={{ marginTop: '16px' }}>
                <Col md={12} sm={12} xs={12}>
                  {error ? (
                    <Notification
                      theme="Error"
                      isClose
                      title="Oopps! There was a problem submitting your form. Please try again later"
                    />
                  ) : null}
                  {done ? (
                    <Notification
                      theme="Success"
                      isClose
                      title="Success! Your UTM tag has been created."
                    />
                  ) : null}
                </Col>
              </Row>
            ) : null}
          </Form>
        )}
      </Formik>
    );
  };

  return renderUtmForm();
};

export default UtmCreate;
