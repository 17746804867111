import * as React from 'react';
import iconTimes from './icons/times';
import iconHome from './icons/home';
import iconCoins from './icons/coins';
import iconChart from './icons/chart';
import iconMenu from './icons/menu';
import iconPower from './icons/power';
import iconTrash from './icons/trash';
import iconCheck from './icons/check';
import iconSend from './icons/send';
import iconDroplet from './icons/droplet';
import iconDroplets from './icons/droplets';
import iconMedal from './icons/medal';
import iconBox from './icons/box';
import iconUsers from './icons/users';
import iconDownload from './icons/download';
import icoDownloadFile from './icons/downloadFile';
import iconEye from './icons/eye';
import iconEyeOff from './icons/eyeOff';
import iconCalendar from './icons/calendar';
import iconArrow from './icons/arrow';
import iconEmail from './icons/email';
import iconPen from './icons/pen';
import iconLink from './icons/link';
import iconInfo from './icons/info';
import iconMarket from './icons/market';
import iconStar from './icons/star';
import iconUpload from './icons/upload';
import iconMaximise from './icons/maximise';
import iconHamburgerLeft from './icons/hamburgerLeft';
import iconGwf from './icons/gwf';
import iconProfile from './icons/profile';
import iconLock from './icons/lock';
import iconUser from './icons/user';
import iconLeaf from './icons/leaf';
import iconBell from './icons/bell';
import iconSettings from './icons/settings';
import iconInvoices from './icons/invoices';
import iconCreditCard from './icons/creditCard';
export const allIcons = {
  times: iconTimes,
  home: iconHome,
  coins: iconCoins,
  chart: iconChart,
  menu: iconMenu,
  power: iconPower,
  check: iconCheck,
  trash: iconTrash,
  send: iconSend,
  droplet: iconDroplet,
  box: iconBox,
  medal: iconMedal,
  droplets: iconDroplets,
  users: iconUsers,
  download: iconDownload,
  downloadFile: icoDownloadFile,
  eye: iconEye,
  eyeOff: iconEyeOff,
  calendar: iconCalendar,
  arrow: iconArrow,
  email: iconEmail,
  pen: iconPen,
  link: iconLink,
  info: iconInfo,
  market: iconMarket,
  star: iconStar,
  upload: iconUpload,
  maximise: iconMaximise,
  hamburgerLeft: iconHamburgerLeft,
  gwf: iconGwf,
  profile: iconProfile,
  lock: iconLock,
  user: iconUser,
  leaf: iconLeaf,
  bell: iconBell,
  settings: iconSettings,
  creditCard: iconCreditCard,
  invoices: iconInvoices,
};
const IconRoot = (props) => {
  const { icon } = props;
  // eslint-disable-next-line no-prototype-builtins
  const iconExists = allIcons.hasOwnProperty(icon);
  if (iconExists) {
    const SpecificIcon = allIcons[icon];
    return <SpecificIcon {...props} />;
  }
  const FallbackIcon = allIcons.times;
  return <FallbackIcon {...props} />;
};
export default IconRoot;
