import { setMeta, setPickerPeriod } from '../../actions/actionsMetadata';
import { getAllCampaigns } from '../../actions/campaigns';
import { connect } from 'react-redux';
import CustomDatePicker from './CustomDatePicker';

const mapStateToProps = (state) => {
  return {
    metadata: state.metadataReducer,
    campaign: state.campaignReducer,
    user: state.userReducer,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setMeta: (
      regionSlug,
      startDate,
      endDate,
      datePickerPeriod,
      initialDate,
      campaignId,
      focus
    ) =>
      dispatch(
        setMeta(
          regionSlug,
          startDate,
          endDate,
          datePickerPeriod,
          initialDate,
          campaignId,
          focus
        )
      ),
    setPickerPeriod: (period) => dispatch(setPickerPeriod(period)),
    getAllCampaigns: () => dispatch(getAllCampaigns()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomDatePicker);
