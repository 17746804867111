import { connect } from 'react-redux';
import Success from './Success';
import { getUser } from '../../../actions/user';

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUser()),
  };
};
export default connect(null, mapDispatchToProps)(Success);
