import { connect } from 'react-redux';
import { AppState } from './../../../interfaces';
import Profile from './Profile';
import { resetPasswordRequest } from '../../../actions/auth';
import { updateBusiness } from '../../../actions/updateBusiness';
import { getUser } from '../../../actions/user';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    user: state.userReducer,
    resetPassword: state.resetPasswordReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    resetPasswordRequest: (oldPassword, newPassword) =>
      dispatch(resetPasswordRequest(oldPassword, newPassword)),
    updateBusiness: (data, onSuccess, onFailed) =>
      dispatch(updateBusiness(data, onSuccess, onFailed)),
    getUser: () => dispatch(getUser()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
