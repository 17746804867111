import React from 'react';
import { Button, Icon } from '../index';
import Styled from './AddImage.style';

interface AddImageProps {
  onImageChange: Function;
  onClearClick: Function;
  className?: any;
  style?: React.CSSProperties;
  name: string;
  label: string;
  error?: string;
  disclaimer: string;
  filename?: string;
}

export const AddImage: React.FC<AddImageProps> = (props) => {
  const {
    onImageChange,
    disclaimer,
    onClearClick,
    className,
    style,
    name,
    label,
    filename,
    error,
  } = props;
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);
  const clickHidden = () => {
    if (hiddenFileInput.current != null) {
      hiddenFileInput.current.click();
    }
  };
  return (
    <Styled.Wrapper className={className} style={style}>
      {label ? <label htmlFor={name}>{label}</label> : null}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          marginTop: '6px',
        }}
      >
        <Styled.IconWrap onClick={clickHidden}>
          <Icon icon="upload" size={20} />
        </Styled.IconWrap>
        <Styled.AddLogo onClick={clickHidden}>{filename}</Styled.AddLogo>
        <input
          type="file"
          multiple
          id={name}
          ref={hiddenFileInput}
          name={name}
          accept="image/*"
          style={{
            display: 'none',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            width: '50px',
          }}
          onChange={onImageChange()}
        ></input>
        <Styled.ButtonWrap
          onClick={(e) => {
            e.preventDefault();
            onClearClick();
          }}
        >
          <Icon icon="trash" size={20} />
        </Styled.ButtonWrap>
      </div>
      <Styled.Disclaimer>{disclaimer ? disclaimer : null}</Styled.Disclaimer>
      {error ? <Styled.Error>{error}</Styled.Error> : null}
    </Styled.Wrapper>
  );
};

export default AddImage;
