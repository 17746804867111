import React from 'react';
import { prettifyIfNumber } from '../../helpers';

import Styled from './InfoBox.styles';
export interface InfoBoxProps {
  id?: number | string;
  icon: string;
  dataTest?: string;
  loading?: boolean;
  value?: number;
  subtitle?: string;
  theme?: string;
  layout?: 'horizontal' | 'vertical';
  valueType?: 'time' | 'number' | 'currency';
  chartKey: string;
}

const InfoBox = (props: InfoBoxProps) => {
  const { subtitle, value = 0, valueType = 'number', chartKey } = props;

  const typeSuffix = (value, valueType) => {
    switch (valueType) {
      case 'time':
        return `${value.toFixed(0)} mins`;
      case 'currency':
        return `£${prettifyIfNumber(value)}`;
      default:
        return `${prettifyIfNumber(value)}`;
    }
  };

  return (
    <Styled.Wrapper key={chartKey}>
      <Styled.Text>
        <Styled.Title>{value && typeSuffix(value, valueType)}</Styled.Title>
        <Styled.Subtitle>{subtitle}</Styled.Subtitle>
      </Styled.Text>
    </Styled.Wrapper>
  );
};

export default InfoBox;
