import styled from 'styled-components';

const DropdownToggle = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
const DropdownItem = styled.div`
  padding: 6px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  &.button {
    border-radius: 6px;
    margin-top: 6px;
    padding: 6px;
    &:hover {
      background: #1090b8;
      color: white;
      svg {
        g {
          g {
            stroke: white;
          }
        }
      }
    }
  }
`;
const DropdownItemWrap = styled.div`
  width: 200px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background: white;
  padding: 8px 16px;
  position: absolute;
  top: 38px;
  transform: translateX(-85%);
  overflow: hidden;
  transition: height 500ms ease;
`;
const Header = styled.div`
  padding: 6px 0;
  border-bottom: 1px solid black;
`;
export default { Header, DropdownToggle, DropdownItem, DropdownItemWrap };
