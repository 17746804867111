import * as React from 'react';

const menu = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 17.500000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <line x1="90" y1="2.5" x2="0" y2="2.5"></line>
        <line x1="90" y1="32.5" x2="30" y2="32.5"></line>
        <line x1="90" y1="62.5" x2="20" y2="62.5"></line>
      </g>
    </g>
  );
};
export default menu;
