import * as React from 'react';
import Styled from './Filter.style';
import { Card } from '../index';
import { Row, Col } from 'react-styled-flexboxgrid';
import store from 'store2';

interface FilterProps {
  getCampaigns: Function;
  campaign: any;
}

export const Filter: React.FC<FilterProps> = (props) => {
  const { getCampaigns, campaign } = props;

  const user = store.get('user');
  const features = user.validFeatures;
  const utmTag = features.find((o) => o.name === 'CREATE_CAMPAIGNS');

  React.useEffect(() => {
    getCampaigns(utmTag.region.slug);
  }, []);

  return (
    <Styled.Wrap>
      <Card>
        <Row>
          <Col md={2}>
            <Styled.Selector>
              <p style={{ margin: '0' }}>Select campaign</p>
              <Styled.Arrow
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </Styled.Arrow>
            </Styled.Selector>
          </Col>
        </Row>
      </Card>
    </Styled.Wrap>
  );
};

export default Filter;
