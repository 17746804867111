import * as React from 'react';

const market = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      id="icon/benefactor_market"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="store"
        transform="translate(5.000000, 4.999999)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path
          d="M0,22.5 L19.845,2.65500285 C20.68227,1.81273785 21.67794,1.14453285 22.774635,0.688862849 C23.87133,0.233192849 25.047405,-0.00094215078 26.235,1.99840144e-15 L63.765,1.99840144e-15 C64.95255,-0.00094215078 66.12885,0.233192849 67.2255,0.688862849 C68.32215,1.14453285 69.31755,1.81273785 70.155,2.65500285 L90,22.5"
          id="Path"
        ></path>
        <path
          d="M9,45.0000028 L9,81.0000028 C9,83.3868028 9.948195,85.6759528 11.636055,87.3639028 C13.32387,89.0518528 15.613065,90.0000028 18,90.0000028 L72,90.0000028 C74.3868,90.0000028 76.67595,89.0518528 78.3639,87.3639028 C80.05185,85.6759528 81,83.3868028 81,81.0000028 L81,45.0000028"
          id="Path"
        ></path>
        <path
          d="M58.5,90.0000028 L58.5,72.0000028 C58.5,69.6132028 57.55185,67.3240528 55.8639,65.6361028 C54.17595,63.9481528 51.8868,63.0000028 49.5,63.0000028 L40.5,63.0000028 C38.1132,63.0000028 35.82387,63.9481528 34.136055,65.6361028 C32.448195,67.3240528 31.5,69.6132028 31.5,72.0000028 L31.5,90.0000028"
          id="Path"
        ></path>
        <line x1="0" y1="22.5000028" x2="90" y2="22.5000028" id="Path"></line>
        <path
          d="M90,22.5000028 L90,36.0000028 C90,38.3868028 89.05185,40.6759528 87.3639,42.3639028 C85.67595,44.0518528 83.3868,45.0000028 81,45.0000028 C78.37065,44.8551028 75.85965,43.8601528 73.845,42.1650028 C73.30815,41.7771028 72.6624,41.5683028 72,41.5683028 C71.3376,41.5683028 70.69185,41.7771028 70.155,42.1650028 C68.14035,43.8601528 65.62935,44.8551028 63,45.0000028 C60.37065,44.8551028 57.85965,43.8601528 55.845,42.1650028 C55.30815,41.7771028 54.6624,41.5683028 54,41.5683028 C53.3376,41.5683028 52.69185,41.7771028 52.155,42.1650028 C50.14035,43.8601528 47.62935,44.8551028 45,45.0000028 C42.37065,44.8551028 39.85965,43.8601528 37.845,42.1650028 C37.30815,41.7771028 36.6624,41.5683028 36,41.5683028 C35.337555,41.5683028 34.69194,41.7771028 34.155,42.1650028 C32.140215,43.8601528 29.62917,44.8551028 27,45.0000028 C24.37083,44.8551028 21.859785,43.8601528 19.845,42.1650028 C19.30806,41.7771028 18.662445,41.5683028 18,41.5683028 C17.337555,41.5683028 16.69194,41.7771028 16.155,42.1650028 C14.140215,43.8601528 11.62917,44.8551028 9,45.0000028 C6.613065,45.0000028 4.32387,44.0518528 2.636055,42.3639028 C0.948195,40.6759528 0,38.3868028 0,36.0000028 L0,22.5000028"
          id="Path"
        ></path>
      </g>
    </g>
  );
};
export default market;
