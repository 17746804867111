import { connect } from 'react-redux';
import { getEndpointData } from '../../../actions/actionsShared';
import Products from './Products';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state) => {
  return {
    genericPage: state.genericPageReducer,
    metadata: state.metadataReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEndpointData: (meta) => dispatch(getEndpointData(meta, 'product')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
