import * as React from 'react';

const pen = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="5"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 4.999932)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M68.0841621,3.76019749 C69.2760888,2.56804382 70.6913316,1.62240019 72.2490973,0.977234673 C73.8068629,0.332069153 75.4762865,4.03139223e-15 77.1620504,4.03139223e-15 C78.8478142,4.03139223e-15 80.5172379,0.332069153 82.0750035,0.977234673 C83.6327692,1.62240019 85.0480119,2.56804382 86.2399387,3.76019749 C87.4318654,4.95230578 88.3777814,6.36754857 89.0227653,7.92513264 C89.6682032,9.4827621 90,11.1521404 90,12.8380858 C90,14.5239858 89.6682032,16.1934095 89.0227653,17.7509935 C88.3777814,19.3085776 87.4318654,20.7238204 86.2399387,21.9159741 L24.9641928,83.19172 L0,90.0001362 L6.80841621,65.0359434 L68.0841621,3.76019749 Z"></path>
      </g>
    </g>
  );
};
export default pen;
