import React from 'react';
import * as _ from 'lodash';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
  Legend,
} from 'recharts';
import {
  TooltipSimple,
  chartDataColors,
  CustomPieLegend,
  chartDataTransform,
  TicksBold,
  setChartBarHeight,
} from '../../helpers';
import { ChartData } from '../../interfaces';
import { colors } from '../../assets/variable';
import { Card, NothingToDisplay, Typography } from '../../components';
interface ChartBar2Props {
  id: number;
  chartData: ChartData;
  theme?: string;
  chartKey?: string;
  title: string;
  clientSlug?: string;
}
// TODO: refactor to handle multiple datasets
const ChartBar: React.FC<ChartBar2Props> = (props) => {
  const { chartData, theme = 'c1', id, chartKey, title, clientSlug } = props;
  const data = chartDataTransform(chartData);
  const dataColors = chartDataColors(chartData, theme);
  const type = dataColors[0];
  const totals = _.sum(chartData.totals);
  const color = _.get(colors, `${type}`);
  const themes = theme.split(',');
  const renderBars = () => {
    return _.map(chartData.datasets, (bar, index) => {
      return (
        <Bar
          key={`dataset_${index}`}
          name={bar.label}
          dataKey={`dataset_${index}`}
          fill={colors[_.get(themes, index, theme)]}
          radius={[0, 5, 5, 0]}
        />
      );
    });
  };
  const height = setChartBarHeight(data, chartData);
  return (
    <Card>
      <Typography size="2" text={title} />
      <div key={chartKey} style={{ marginTop: '32px' }}>
        {data.length > 0 && totals > 0 ? (
          <ResponsiveContainer height={height} width="100%">
            <BarChart
              data={data}
              layout="vertical"
              margin={{ top: 0, right: 0, bottom: 0, left: 80 }}
              barCategoryGap={15}
            >
              <CartesianGrid
                horizontal={false}
                strokeWidth={0.5}
                stroke="#ddd"
              />
              <XAxis stroke="#bbb" type="number" />
              <YAxis
                stroke="#bbb"
                type="category"
                tick={TicksBold}
                dataKey="name"
                width={chartKey === 'gwf_total_product_orders' ? 280 : 70}
              />
              <Tooltip
                cursor={{
                  fill: '#F5F5F5',
                }}
                content={TooltipSimple}
              />
              {renderBars()}
              {CustomPieLegend({ data, color, totals, clientSlug })}
              {TooltipSimple}
              <Legend />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <NothingToDisplay />
        )}
      </div>
    </Card>
  );
};

export default ChartBar;
