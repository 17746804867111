import * as _ from 'lodash';
import { BrandingReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: BrandingReducer = {
  createBranding: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  updateBranding: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  getBranding: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const campaignReducer = createReducer(initialState, {
  GET_BRANDING_FULFILLED: (state, action) => {
    state.getBranding.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getBranding.data = _.get(action, 'payload.data');
  },
  GET_BRANDING_PENDING: (state) => {
    state.getBranding.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_BRANDING_REJECTED: (state, action) => {
    state.getBranding.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  UPDATE_BRANDING_FULFILLED: (state, action) => {
    state.updateBranding.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.updateBranding.data = _.get(action, 'payload.data');
  },
  UPDATE_BRANDING_PENDING: (state) => {
    state.updateBranding.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  UPDATE_BRANDING_REJECTED: (state, action) => {
    state.updateBranding.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  CREATE_BRANDING_FULFILLED: (state, action) => {
    state.createBranding.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.createBranding.data = _.get(action, 'payload.data');
  },
  CREATE_BRANDING_PENDING: (state) => {
    state.createBranding.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  CREATE_BRANDING_REJECTED: (state, action) => {
    state.createBranding.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.detail'),
      done: false,
    };
  },
});

export default campaignReducer;
