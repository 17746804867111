import React from 'react';
import Styled from './Register.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Typography, Input, Button, Notification } from '../../../components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { useResponsivity } from '../../../helpers';

export interface RegisterProps {
  register: Function;
  registration: any;
}

export const Register: React.FC<RegisterProps> = (props) => {
  const { register, registration } = props;
  const { error, loading, done } = registration.status;

  const navigate = useNavigate();
  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Password too short')
      .max(50, 'Password too long')
      .required('Required'),
    email: Yup.string().email('Email not valid').required('Email is required'),
    company: Yup.string().required('Company name is required'),
  });
  const isMobile = useResponsivity();
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          email: '',
          password: '',
          company: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={async (values) => {
          register(values);
        }}
      >
        {({
          values,
          errors,
          touched,
          isSubmitting,
          handleChange,
          handleBlur,
        }) => (
          <Form>
            <Input
              placeholder="Company name"
              className="introX-right"
              type="text"
              id="company"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.company}
              error={
                errors.company && touched.company ? errors.company : undefined
              }
            />
            <Input
              placeholder="Email"
              className="introX-right"
              type="text"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email ? errors.email : undefined}
            />
            <Input
              placeholder="Password"
              className="introX-right"
              type="password"
              id="password"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              error={
                errors.password && touched.password
                  ? errors.password
                  : undefined
              }
            />
            {error ? (
              <Notification isClose title={error} theme="Error" />
            ) : null}
            {done ? (
              <Notification
                isClose
                title="Success! Check your email to verify your registration."
                theme="Success"
              />
            ) : null}
            <div style={{ marginBottom: '16px' }}>
              <Button
                type="submit"
                className="introX-right"
                theme="COLOR"
                disabled={isSubmitting || loading}
                style={{ marginRight: '16px' }}
              >
                Register
              </Button>
              <Button
                className="introX-right"
                onClick={() => navigate('/login')}
                theme="WHITE"
              >
                Sign in
              </Button>
            </div>{' '}
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Styled.Wrap isDesktop={!isMobile}>
      <Row style={{ height: '100%' }}>
        <Col style={{ height: '100%' }} sm={7} md={5}>
          <Styled.HeadLineWrap>
            <div style={{ width: '100%', paddingTop: '32px' }}>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/www-white.svg"
                alt="GetWaterFit"
                className="introY-top"
                height="35px"
              />
            </div>
            <Styled.TextContainer>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/corporate_woman.svg"
                alt="Corporate woman"
                className="introX-left"
                style={{ maxWidth: '550px', width: '100%' }}
              />
              <Typography
                size="1"
                style={{ color: 'white' }}
                className="introX-left"
                text="A few more clicks to register your account."
              />
              <Typography
                size="p"
                style={{ color: 'white' }}
                className="introX-left"
                text="Manage all your campaigns in one place."
              />
            </Styled.TextContainer>
            <div
              style={{
                display: 'flex',
                marginBottom: '32px',
              }}
            >
              <Link
                to="/terms-and-conditions"
                className="introY-bottom"
                target="_blank"
                style={{
                  color: 'white',
                  textDecoration: 'underline',
                  marginRight: '16px',
                }}
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>
              <Link
                to="/privacy-policy"
                className="introY-bottom"
                target="_blank"
                style={{ color: 'white', textDecoration: 'underline' }}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </div>
          </Styled.HeadLineWrap>
        </Col>
        <Col style={{ height: '100%' }} sm={5} md={7}>
          <Styled.FormWrap>
            <div>
              <Typography
                size="2"
                style={{ fontSize: '30px' }}
                className="introX-right"
                text="Register"
              />
              {renderForm()}
              <p className="introX-right" style={{ fontSize: '12px' }}>
                <i>
                  By registering your account you agree to the{' '}
                  <Link
                    style={{ textDecoration: 'underline', color: 'black' }}
                    to="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </Link>{' '}
                  and{' '}
                  <Link
                    style={{ textDecoration: 'underline', color: 'black' }}
                    to="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </i>
              </p>
            </div>
          </Styled.FormWrap>
        </Col>
      </Row>
    </Styled.Wrap>
  );
};

export default Register;
