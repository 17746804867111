import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const getUtmTagList = () => {
  return {
    type: 'GET_UTM_TAG',
    payload: axios.get(
      `${getMicroServiceUrl('sponsor-portal')}branding/utm/list`
    ),
  };
};

export const deleteUtmTag = (utmTagId) => {
  return {
    type: 'DELETE_UTM_TAG',
    payload: axios.delete(
      `${getMicroServiceUrl('sponsor-portal')}branding/utm/delete/${utmTagId}`
    ),
  };
};

export const createUtmTag = (data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'CREATE_UTM_TAG',
      payload: axios.post(
        `${getMicroServiceUrl('sponsor-portal')}branding/utm/create`,
        data
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const updateUtmTag = (utmTagId, data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'UPDATE_UTM_TAG',
      payload: axios.post(
        `${getMicroServiceUrl(
          'sponsor-portal'
        )}branding/utm/update/${utmTagId}`,
        data
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const publishUtmTag = (utmTagId, data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'PUBLISH_UTM_TAG',
      payload: axios.post(
        `${getMicroServiceUrl(
          'sponsor-portal'
        )}branding/utm/publish/${utmTagId}`,
        data
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};
