import { combineReducers } from 'redux';
import appReducer from './app';
import authReducer from './auth';
import registrationReducer from './registration';
import campaignReducer from './campaigns';
import genericPageReducer from './genericPage';
import metadataReducer from './metadata';
import bannerReducer from './banner';
import utmTagReducer from './utmTag';
import marketplaceReducer from './marketplace';
import updateBusinessReducer from './updateBusiness';
import userReducer from './user';
import resetPasswordReducer from './reset';
import brandingReducer from './branding';
import adAnalyticsReducer from './adAnalytics';
import subscriptionsReducer from './subscription';
import invoiceReducer from './invoices';
import paymentReducer from './payment';
import adminReducer from './admin';
import licenceReducer from './licence';
import categoriesReducer from './categories';
const rootReducer = (state: any, action: any) => {
  return mainReducer(state, action);
};

const mainReducer = combineReducers({
  appReducer,
  authReducer,
  registrationReducer,
  campaignReducer,
  bannerReducer,
  utmTagReducer,
  metadataReducer,
  genericPageReducer,
  marketplaceReducer,
  updateBusinessReducer,
  userReducer,
  resetPasswordReducer,
  brandingReducer,
  adAnalyticsReducer,
  subscriptionsReducer,
  invoiceReducer,
  paymentReducer,
  adminReducer,
  licenceReducer,
  categoriesReducer,
});

export default rootReducer;
