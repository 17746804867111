import { connect } from 'react-redux';
import { createUtmTag, getUtmTagList } from '../../../../../actions/utmTag';
import { AppState } from '../../../../../interfaces';
import UtmCreate from './UtmCreate';

// CONNECT PROPS AND DISPATCHERS
const mapStateToProps = (state: AppState) => {
  return {
    utmTags: state.utmTagReducer,
    campaign: state.campaignReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    createUtmTag: (data, onSuccess, onFailed) =>
      dispatch(createUtmTag(data, onSuccess, onFailed)),
    getUtmTagList: () => dispatch(getUtmTagList()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UtmCreate);
