/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import { useParams } from 'react-router-dom';
import UpdateBanner from '../Marketplace/UpdateBanner';
import {
  Card,
  Wrap,
  Loader,
  ErrorDisplay,
  Typography,
  Counter,
} from '../../../components';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import { scrollToTop, useUpdateEffect } from '../../../helpers';

interface SingleBannerProps {
  marketplace: any;
  metadata: any;
  analytics: any;
  updateMarketplaceBanner: Function;
  deleteMarketplaceBanner: Function;
  listMarketplaceBanner: Function;
  getAnalytics: Function;
  categories: any;
  getCategories: Function;
  getBannerTag: Function;
  bannerData: any;
}
export const SingleBanner: React.FC<SingleBannerProps> = (props) => {
  const {
    marketplace,
    listMarketplaceBanner,
    updateMarketplaceBanner,
    deleteMarketplaceBanner,
    metadata,
    getAnalytics,
    analytics,
    categories,
    getCategories,
    getBannerTag,
    bannerData,
  } = props;
  const listBanners = _.get(marketplace, 'listMarketplaceBanner.data');
  const { loading, error } = marketplace.listMarketplaceBanner.status;
  const { bannerId } = useParams();
  const banner: string = bannerId!;

  const analyticsData = _.get(analytics, 'data');
  const loadingAnalytics = _.get(analytics, 'status.loading');
  const errorAnalytics = _.get(analytics, 'status.error');

  const adsViewed = _.get(analyticsData, 'myShopAdViewed');
  const adsClicked = _.get(analyticsData, 'myShopAdClicked');
  const adsClickedThrough = _.get(analyticsData, 'myShopAdClickThrough');
  const startDate = _.get(metadata, 'startDate');
  const endDate = _.get(metadata, 'endDate');
  const categoriesData = _.get(categories, 'data');
  const bannerTagData = _.get(bannerData, 'getBannerTag.data');
  useUpdateEffect(() => {
    getAnalytics(parseInt(banner), startDate, endDate);
  }, [startDate, endDate]);

  React.useEffect(() => {
    scrollToTop();
    listMarketplaceBanner();
    getCategories();
    getBannerTag();
    getAnalytics(parseInt(banner), startDate, endDate);
  }, []);

  const loadingUpdate = _.get(
    marketplace,
    'updateMarketplaceBanner.status.loading'
  );
  const loadingList = _.get(
    marketplace,
    'listMarketplaceBanner.status.loading'
  );
  const doneList = _.get(marketplace, 'listMarketplaceBanner.status.done');

  const currentBanner = _.find(listBanners, function (o) {
    return o.id === parseInt(`${bannerId}`);
  });

  const bannerTitle = _.get(currentBanner, 'title');

  if (error || errorAnalytics) {
    return <ErrorDisplay />;
  }

  if (loading || loadingAnalytics) {
    return <Loader />;
  }
  return (
    <Row>
      <Col md={12} sm={12} xs={12}>
        <Wrap title={bannerTitle} />
      </Col>
      {loadingList && !doneList && <Loader />}
      <Col md={4} sm={12} xs={12} style={{ marginBottom: '16px' }}>
        <Card style={{ padding: '12px 24px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              style={{ flex: '1', fontWeight: 500, marginBottom: '0px' }}
              size="p"
              text="Ad clicked"
            />
            <Typography
              size="p"
              text="3"
              style={{
                color: '#25b3eb',
                fontWeight: 600,
                fontSize: '48px',
                marginBottom: '0px',
                lineHeight: '64px',
              }}
            >
              <Counter
                type="none"
                end={adsClicked ? adsClicked : 0}
                start={0}
                timer={15}
              />
            </Typography>
          </div>
        </Card>
      </Col>
      <Col md={4} sm={12} xs={12} style={{ marginBottom: '16px' }}>
        <Card style={{ padding: '12px 24px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              style={{ flex: '1', fontWeight: 500, marginBottom: '0px' }}
              size="p"
              text="Ad clicked through"
            />
            <Typography
              size="p"
              text="3"
              style={{
                color: '#25b3eb',
                fontWeight: 600,
                fontSize: '48px',
                marginBottom: '0px',
                lineHeight: '64px',
              }}
            >
              <Counter
                type="none"
                end={adsClickedThrough ? adsClickedThrough : 0}
                start={0}
                timer={15}
              />
            </Typography>
          </div>
        </Card>
      </Col>
      <Col md={4} sm={12} xs={12} style={{ marginBottom: '16px' }}>
        <Card style={{ padding: '12px 24px' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              style={{ flex: '1', fontWeight: 500, marginBottom: '0px' }}
              size="p"
              text="Ad viewed"
            />
            <Typography
              size="p"
              text="3"
              style={{
                color: '#25b3eb',
                fontWeight: 600,
                fontSize: '48px',
                marginBottom: '0px',
                lineHeight: '64px',
              }}
            >
              <Counter
                type="none"
                end={adsViewed ? adsViewed : 0}
                start={0}
                timer={15}
              />
            </Typography>
          </div>
        </Card>
      </Col>
      <Col md={12} sm={12} xs={12}>
        <Card>
          <UpdateBanner
            list={listMarketplaceBanner}
            loading={loadingUpdate}
            data={listBanners}
            paramId={banner}
            onDelete={deleteMarketplaceBanner}
            setUpdate={updateMarketplaceBanner}
            categories={categoriesData}
            bannerTags={bannerTagData}
          />
        </Card>
      </Col>
    </Row>
  );
};

export default SingleBanner;
