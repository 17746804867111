import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const deleteBanner = (campaignBannerId) => {
  return {
    type: 'DELETE_BANNER',
    payload: axios.delete(
      `${getMicroServiceUrl(
        'sponsor-portal'
      )}campaign/banner/delete/${campaignBannerId}`
    ),
  };
};

export const createBanner = (campaignId, data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'CREATE_BANNER',
      payload: axios.post(
        `${getMicroServiceUrl(
          'sponsor-portal'
        )}campaign/banner/createAll/${campaignId}`,
        data,
        { headers: { 'Content-type': 'multipart/form-data' } }
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const updateBanner = (campaignBannerId, data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'UPDATE_BANNER',
      payload: axios.post(
        `${getMicroServiceUrl(
          'sponsor-portal'
        )}campaign/banner/update/${campaignBannerId}`,
        data,
        { headers: { 'Content-type': 'multipart/form-data' } }
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const uploadBannerImage = (
  campaignBannerId,
  data,
  onSuccess,
  onFailed
) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'UPLOAD_IMAGE',
      payload: axios.post(
        `${getMicroServiceUrl(
          'sponsor-portal'
        )}upload/campaignBanner/${campaignBannerId}`,
        data
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const getBannerTag = () => {
  return {
    type: 'GET_BANNER_TAGS',
    payload: axios.get(`${getMicroServiceUrl('sponsor-portal')}banner/tag`),
  };
};
