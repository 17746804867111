import React, { Component } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Card, Notification, ErrorDisplay, Loader } from '../../../components';
import { MetadataReducer } from '../../../interfaces';
import { isMetaChanged, updateIfRequired } from '../../../helpers';
import * as _ from 'lodash';
import RenderComponent from '../../../components/RenderComponent';
interface GenericPageProps {
  getEndpointData: Function;
  genericPage: any;
  metadata: MetadataReducer;
  pageName: string;
}

class GenericPage extends Component<GenericPageProps, any> {
  componentDidMount() {
    this.updateData();
  }

  componentDidUpdate(prevProps) {
    if (isMetaChanged(this.props.metadata, prevProps.metadata)) {
      this.updateData(true);
    }
  }
  updateData = (forceUpdate = false) => {
    updateIfRequired(
      this.props.pageName,
      this.props.getEndpointData,
      this.props,
      forceUpdate
    );
  };
  renderLayout = (layout) => {
    return _.map(layout, (row, index) => {
      const components = _.map(row.components, (component) => {
        return <RenderComponent key={component.id} {...component} />;
      });

      if (row.title) {
        return (
          <Row key={index}>
            <Col sm={12} md={12}>
              <Row>{components}</Row>
            </Col>
          </Row>
        );
      }

      return <Row key={index}>{components}</Row>;
    });
  };
  render() {
    const { genericPage, pageName } = this.props;

    const loading = _.get(this, `props.genericPage.${pageName}.status.loading`);
    const error = _.get(this, `props.genericPage.${pageName}.status.error`);
    const done = _.get(this, `props.genericPage.${pageName}.status.done`);

    const data = _.get(genericPage, `${pageName}.data.layout`);

    if (error && !done) return <ErrorDisplay />;
    if (loading && !done) return <Loader />;
    return <div>{this.renderLayout(data)}</div>;
  }
}

export default GenericPage;
