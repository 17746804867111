import * as _ from 'lodash';
import { InvoiceReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
export const initialState: InvoiceReducer = {
  invoice: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  nextInvoice: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};
const invoiceReducer = createReducer(initialState, {
  GET_INVOICES_FULFILLED: (state, action) => {
    state.invoice.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.invoice.data = _.get(action, 'payload.data');
  },
  GET_INVOICES_PENDING: (state) => {
    state.invoice.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_INVOICES_REJECTED: (state, action) => {
    state.invoice.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});
export default invoiceReducer;
