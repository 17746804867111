import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Styled from './Breadcrumbs.style';
import Icon from '../Icon';
import { capitalizeFirstLetter } from '../../helpers';
export const Breadcrumbs: React.FC = () => {
  const location = useLocation();

  const renderBreadcrumbs = () => {
    if (location.pathname === '/') {
      return <Styled.Wrap></Styled.Wrap>;
    } else if (location.pathname.includes('/campaigns/')) {
      return (
        <Styled.Wrap>
          <Link to="/">
            <Icon style={{ cursor: 'pointer' }} icon="home" size={18} />{' '}
          </Link>{' '}
          <span style={{ margin: '0 8px', fontSize: '14px', fontWeight: 600 }}>
            {'/'}
          </span>
          <Link to="/campaigns">
            <span style={{ textTransform: 'capitalize' }}>
              {location.pathname.substring(
                location.pathname.indexOf('/') + 1,
                location.pathname.lastIndexOf('/')
              )}
            </span>
          </Link>{' '}
          <span style={{ margin: '0 8px', fontSize: '14px', fontWeight: 600 }}>
            {'/'}
          </span>
          <span>
            {location.pathname.substring(
              location.pathname.lastIndexOf('/') + 1
            )}
          </span>
        </Styled.Wrap>
      );
    } else {
      return (
        <Styled.Wrap>
          <Icon style={{ cursor: 'pointer' }} icon="home" size={18} />{' '}
          <span style={{ margin: '0 8px', fontSize: '14px', fontWeight: 600 }}>
            {'/'}
          </span>
          <span style={{ color: '#6f6f6f' }}>
            {capitalizeFirstLetter(
              location.pathname.substring(1).replace(/-/g, ' ')
            )}
          </span>
        </Styled.Wrap>
      );
    }
  };
  return renderBreadcrumbs();
};

export default Breadcrumbs;
