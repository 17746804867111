import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  height: 70px;
  line-height: 70px;
  flex: 1;
  align-items: center;
  a {
    text-decoration: none;
    color: #0784ab;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export default { Wrap };
