import SingleBanner from './SingleBanner';
import { connect } from 'react-redux';
import {
  updateMarketplaceBanner,
  deleteMarketplaceBanner,
  listMarketplaceBanner,
} from '../../../actions/marketplace';
import { AppState } from '../../../interfaces';
import { getAnalytics } from '../../../actions/adAnalytics';
import { getCategories } from '../../../actions/categories';
import { getBannerTag } from '../../../actions/banner';

const mapStateToProps = (state: AppState) => ({
  marketplace: state.marketplaceReducer,
  metadata: state.metadataReducer,
  analytics: state.adAnalyticsReducer,
  user: state.userReducer,
  categories: state.categoriesReducer,
  bannerData: state.bannerReducer,
});

const mapDispatchToProps = (dispatch) => ({
  updateMarketplaceBanner: (bannerId, data, onSuccess, onFailed) =>
    dispatch(updateMarketplaceBanner(bannerId, data, onSuccess, onFailed)),
  deleteMarketplaceBanner: (bannerId) =>
    dispatch(deleteMarketplaceBanner(bannerId)),
  listMarketplaceBanner: () => dispatch(listMarketplaceBanner()),
  getAnalytics: (bannerId, startDate, endDate) =>
    dispatch(getAnalytics(bannerId, startDate, endDate)),
  getCategories: () => dispatch(getCategories()),
  getBannerTag: () => dispatch(getBannerTag()),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleBanner);
