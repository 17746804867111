import styled, { css } from 'styled-components';
import { colors } from '../../assets/variable';

interface CheckboxProps {
  boxSize: number;
  checked?: any;
  onChange: Function;
  disabled?: boolean;
  error?: string;
  name: string;
  id: string;
  theme?: string;
  required?: boolean;
  isElipse?: boolean;
  [x: string]: any;
}

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })<CheckboxProps>`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 0;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 0;
`;

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  position: absolute;
  top: 3px;
  left: 0;
`;

const Description = styled.div<CheckboxProps>`
  line-height: 1.6em;
  font-size: 14px;
  color: #000;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
`;

const checkedStyles = css`
  background-color: ${colors.primary};
  border-radius: 6px;
`;

const checkedRoundedStyles = css`
  background-color: #174489;
  border: 1px solid #174489;
  stroke: white;
`;

const hoverStyles = css`
  &:hover {
    background-color: transparent;
    border: 1px solid #174489;
    stroke: rgba(0, 0, 0, 0.5);
    transition: all 200ms ease-in-out;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
  }
`;
const uncheckedRoundedStyles = css<CheckboxProps>`
  background-color: transparent;
  border: 1px solid #25b3eb;
  stroke: transparent;
  &:hover {
    ${(props) => !props.disabled && hoverStyles}
  }
`;
const uncheckedStyles = css`
  border: 2px solid ${colors.primary};
  background-color: ${colors.baseLight};
  border-radius: 6px;
`;

const disabledStyles = css<CheckboxProps>`
  border: 2px solid ${(props) => (props.checked ? '#25b3eb' : colors.disabled)};
  background-color: ${colors.baseLight};
  cursor: not-allowed;
  stroke: ${(props) => (props.checked ? '#25b3eb' : colors.disabled)};
`;

const Label = styled.label<CheckboxProps>`
  min-height: ${(props) => props.boxSize + 4}px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 26px;
  ${Description} {
    margin-left: ${(props) => props.boxSize + 6}px;
    color: ${(props) => (props.disabled ? colors.disabled : 'inherit')};
    color: ${(props) => (props.disabled ? colors.darkGray : '#000')};
  }
  ${HiddenCheckbox} {
    ~ ${() => Icon} {
      background-color: ${colors.primary};
      width: ${(props) => props.boxSize}px;
      height: ${(props) => props.boxSize}px;
      ${(props) => (props.checked ? checkedStyles : uncheckedStyles)};
      ${(props) => (props.disabled ? disabledStyles : '')};
    }
    &:focus {
      ~ ${Icon} {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.5);
      }
    }
  }
  ${Icon} {
    stroke: ${(props) => (props.disabled ? colors.disabled : colors.baseLight)};
  }

  ${(props) => {
    if (props.theme === 'rounded') {
      return roundedStyle;
    }
  }};
`;

const FieldError = styled.div`
  font-size: 0.8em;
  color: ${colors.error};
  margin-top: 5px;
`;
const roundedStyle = css<CheckboxProps>`
  ${HiddenCheckbox} {
    ~ ${() => Icon} {
      background-color: ${colors.baseLight};
      width: ${(props) => props.boxSize}px;
      height: ${(props) => props.boxSize}px;
      border-radius: 50%;
      stroke: ${(props) => (props.checked ? '#25b3eb' : 'white')};
      ${(props) =>
        props.checked ? checkedRoundedStyles : uncheckedRoundedStyles};
      ${(props) => (props.disabled ? disabledStyles : '')}
    }
    &:focus {
      ~ ${Icon} {
        box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.2);
      }
    }
  }
  ${Icon} {
    stroke: ${(props) => (props.disabled ? colors.disabled : colors.baseLight)};
  }
`;

export default {
  Icon,
  HiddenCheckbox,
  Label,
  Description,
  FieldError,
};
