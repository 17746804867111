import React from 'react';
import { Button, Typography, AddImage, Notification } from '../../components';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lodash';

interface LogoUploadProps {
  url: string;
  business: any;
  createBranding: Function;
  updateBranding: Function;
}
export const LogoUpload: React.FC<LogoUploadProps> = (props) => {
  const { url, business, updateBranding, createBranding } = props;

  const { error, loading, done } = business.updateBusiness.status;
  const [refresh, setRefresh] = React.useState(false);
  if (refresh === true) {
    setTimeout(() => {
      setRefresh(false);
    }, 3000);
  }

  const UploadLogoSchema = Yup.object().shape({
    logo: Yup.mixed().test(
      'fileSize',
      'Image is too big! Max image size is 2MB',
      (value) => {
        if (value) {
          return value && value.size <= 2000000;
        } else {
          return true;
        }
      }
    ),
  });

  const formik = useFormik({
    initialValues: {
      logo: '',
    },
    validationSchema: UploadLogoSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (url) {
        updateBranding(
          values,
          () => {
            setSubmitting(false);
            setImage(null);
            resetForm();
          },
          () => {
            setSubmitting(false);
          }
        );
      } else {
        createBranding(
          values,
          () => {
            setSubmitting(false);
            setImage(null);
            resetForm();
          },
          () => {
            setSubmitting(false);
          }
        );
      }
      setSubmitting(false);
    },
  });

  const onImageChange = (event) => {
    setImage(event.target.files[0]);
    formik.setFieldValue('logo', event.currentTarget.files[0]);
    setFilename(event.currentTarget.files[0].name);
  };
  const [bannerImage, setImage] = React.useState(null);
  const [filename, setFilename] = React.useState('');
  const renderLogo = () => {
    if (bannerImage) {
      return (
        <img
          style={{ maxHeight: '70px' }}
          src={`${URL.createObjectURL(bannerImage)}`}
        />
      );
    } else if (url) {
      return <img style={{ maxHeight: '70px' }} src={url} />;
    } else {
      return (
        <div
          style={{
            background: '#f8f8f8',
            border: '1px dotted #6c6c6c',
            width: '300px',
            height: '100px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <p style={{ color: '#6c6b6b', margin: '0px', padding: '0px' }}>
            Your preview will show here
          </p>
        </div>
      );
    }
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Row>
          <Col md={12} xs={12} sm={12}>
            <div
              style={{
                minHeight: '150px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {renderLogo()}
            </div>
          </Col>
          <Col md={12} xs={12} sm={12}>
            <Typography
              size="p"
              style={{ fontSize: '13px', fontStyle: 'italic' }}
              text="The max height of the logo should not exceed 70 pixels."
            />
          </Col>
          <Col md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
            <AddImage
              name="logo"
              label="Add logo"
              onClearClick={() => setImage(null)}
              onImageChange={() => onImageChange}
              disclaimer="Maximum file size 2MB.he max height of the logo should not exceed 70 pixels."
              filename={filename ? filename : 'Please select a image'}
            />
          </Col>
          {error ? (
            <Col md={12} sm={12} xs={12}>
              <Notification isClose title={error} theme="Error" />
            </Col>
          ) : null}
          {done && refresh ? (
            <Col md={12} sm={12} xs={12}>
              <Notification
                isClose
                title={'Success, you have added your logo.'}
                theme="Success"
              />
            </Col>
          ) : null}
          <Col
            sm={12}
            xs={12}
            md={12}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <Button disabled={formik.isSubmitting || loading}>
              {url ? 'Update branding' : 'Create branding'}
            </Button>
          </Col>
        </Row>
      </form>
    </div>
  );
};

export default LogoUpload;
