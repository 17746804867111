import React, { Component } from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { ErrorDisplay, Loader, NoFeature, Wrap } from '../../../components';
import { MetadataReducer } from '../../../interfaces';
import { isMetaChanged, updateIfRequired } from '../../../helpers';
import * as _ from 'lodash';
import RenderComponent from '../../../components/RenderComponent';
import store from 'store2';
interface LeaksProps {
  getEndpointData: Function;
  genericPage: any;
  metadata: MetadataReducer;
}

class Leaks extends Component<LeaksProps, any> {
  componentDidMount() {
    // console.log('DID MOUNT');
    if (this.props.metadata.regionSlug !== 'none') {
      this.updateData();
    }
  }

  componentDidUpdate(prevProps) {
    if (isMetaChanged(this.props.metadata, prevProps.metadata)) {
      // console.log('META CHANGED');
      this.updateData(true);
    }
  }
  updateData = (forceUpdate = false) => {
    updateIfRequired(
      'leak',
      this.props.getEndpointData,
      this.props,
      forceUpdate
    );
  };
  renderLayout = (layout) => {
    return _.map(layout, (row, index) => {
      const components = _.map(row.components, (component) => {
        return <RenderComponent key={component.id} {...component} />;
      });

      if (row.title) {
        return (
          <Row key={index}>
            <Col xs={12} sm={12} md={12}>
              <Row>{components}</Row>
            </Col>
          </Row>
        );
      }

      return <Row key={index}>{components}</Row>;
    });
  };
  render() {
    const { genericPage } = this.props;

    const loading = _.get(genericPage, 'leak.status.loading');
    const error = _.get(genericPage, 'leak.status.error');
    const done = _.get(genericPage, 'leak.status.done');

    const data = _.get(genericPage, 'leak.data.layout');
    const user = store.get('user');
    const features = user.validFeatures;
    const createCampaigns = features[0].features.find(
      (o) => o.name === 'CREATE_CAMPAIGNS'
    );

    if (!createCampaigns) {
      return <NoFeature />;
    }
    if (error && createCampaigns) {
      if (error === 'no.data.available.for.campaign') {
        return (
          <ErrorDisplay text="No data has been found for this campaign." />
        );
      } else if (error.includes('tags.not.found.for.campaign')) {
        const text = `No UTM tag(s) have been created for this campaign.`;
        return <ErrorDisplay text={text} />;
      }
      return <ErrorDisplay text={error} />;
    }
    if (loading && !done) return <Loader />;
    return this.renderLayout(data);
  }
}

export default Leaks;
