import styled from 'styled-components';

const Wrap = styled.div`
  background: #0784ab;
  position: fixed;
  display: flex;
  transform: translate(0, -50%);
  top: 50%;
  width: 100px;
  margin: auto;
  z-index: 9999;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  left: 0;
  align-items: center;
  transition: all 100ms ease-in-out;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

  &.expanded {
    transform: translate(calc(-100%), -50%);
    transition: all 100ms ease-in-out;
  }
`;

const Nav = styled.div`
  margin-bottom: auto;
  margin: 16px 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  a {
    text-decoration: none;
  }
`;

const NavElement = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: all 200ms ease-in-out;
  &:hover {
    background-color: #1090b8;
    transition: all 200ms ease-in-out;
  }
  &:focus {
    background-color: #1090b8;
    transition: all 200ms ease-in-out;
  }
  &.active {
    background-color: #1090b8;
    transition: all 200ms ease-in-out;
    svg {
      g {
        stroke: white;
      }
    }
  }
`;
const NavWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
`;
const ArrowWrap = styled.div`
  background: #0784ab;
  height: 60px;
  width: 40px;
  border-bottom-right-radius: 40px;
  border-top-right-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
`;
const Arrow = styled.svg`
  transition-property: color, background-color, border-color,
    text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter,
    backdrop-filter;
  transition-duration: 0.1s;
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1);
  transform: translate(0, 0) rotate(-90deg) skew(0) skewY(0) scaleX(1) scaleY(1);
  &.transform {
    transform: translate(0, 0) rotate(90deg) skew(0) skewY(0) scaleX(1)
      scaleY(1);
  }
`;
export default { Wrap, Nav, Arrow, ArrowWrap, NavElement, NavWrap };
