import React from 'react';
import Styled from './ResetPassword.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Typography, Input, Button } from '../../../components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, Link } from 'react-router-dom';
import { appWidth } from '../../../helpers';

export const ResetPassword: React.FC = () => {
  const navigate = useNavigate();
  const SignupSchema = Yup.object().shape({
    email: Yup.string().email('Email not valid').required('Email is required'),
  });
  const { width } = appWidth();

  const isDesktop = width > 1024;

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
          alert(JSON.stringify(values, null, 2));
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form>
            <Input
              placeholder="Enter your email address"
              className="introX-right"
              type="text"
              id="email"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              error={errors.email && touched.email ? errors.email : undefined}
            />
            <div>
              <Button
                type="submit"
                className="introX-right"
                theme="COLOR"
                style={{ marginRight: '16px' }}
              >
                Submit
              </Button>
              <Button
                className="introX-right"
                onClick={() => navigate('/login')}
                theme="WHITE"
              >
                Back
              </Button>
            </div>{' '}
          </Form>
        )}
      </Formik>
    );
  };

  return isDesktop ? (
    <Styled.Wrap>
      <Row style={{ height: '100%' }}>
        <Col style={{ height: isDesktop ? '100%' : '100%' }} md={5}>
          <Styled.HeadLineWrap>
            <div style={{ width: '550px', paddingTop: '32px' }}>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/www-white.svg"
                alt="GetWaterFit"
                className="introY-top"
                height="35px"
              />
            </div>
            <Styled.TextContainer>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/corporate_woman.svg"
                alt="Corporate woman"
                className="introX-left"
                style={{ minWidth: '550px' }}
              />
              <Typography
                size="1"
                style={{ color: 'white' }}
                className="introX-left"
                text="Forgot your password?"
              />
              <Typography
                size="p"
                style={{ color: 'white' }}
                className="introX-left"
                text="Type in your email and you will receive the instructions to reset it"
              />
            </Styled.TextContainer>
            <div
              style={{
                display: 'flex',
                marginBottom: '32px',
              }}
            >
              <Link
                to="/terms-and-conditions"
                className="introY-bottom"
                target="_blank"
                style={{
                  color: 'white',
                  textDecoration: 'underline',
                  marginRight: '16px',
                }}
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>
              <Link
                to="/privacy-policy"
                className="introY-bottom"
                target="_blank"
                style={{ color: 'white', textDecoration: 'underline' }}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </div>
          </Styled.HeadLineWrap>
        </Col>
        <Col style={{ height: '100%' }} md={7}>
          <Styled.FormWrap>
            <div style={{ width: '500px' }}>
              <Typography
                size="2"
                className="introX-right"
                style={{ fontSize: '30px' }}
                text="Reset password"
              />
              {renderForm()}
            </div>
          </Styled.FormWrap>
        </Col>
      </Row>
    </Styled.Wrap>
  ) : (
    <Styled.MobileWrap>
      <div style={{ marginTop: '16px' }}>
        <img
          src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/www-white.svg"
          alt="GetWaterFit"
          height="35px"
        />
      </div>
      <Styled.MobileForm>
        <div>
          <Typography
            size="2"
            style={{ fontSize: '30px' }}
            text="Reset password"
          />
          {renderForm()}
        </div>
      </Styled.MobileForm>
      <div
        style={{
          display: 'flex',
          marginBottom: '32px',
        }}
      >
        <Link
          to="/terms-and-conditions"
          className="introY-bottom"
          target="_blank"
          style={{
            color: 'white',
            textDecoration: 'underline',
            marginRight: '16px',
          }}
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </Link>
        <Link
          to="/privacy-policy"
          className="introY-bottom"
          target="_blank"
          style={{ color: 'white', textDecoration: 'underline' }}
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </div>
    </Styled.MobileWrap>
  );
};

export default ResetPassword;
