import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const createBranding = (data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'CREATE_BRANDING',
      payload: axios.post(
        `${getMicroServiceUrl('sponsor-portal')}branding/create`,
        data,
        { headers: { 'Content-type': 'multipart/form-data' } }
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const updateBranding = (data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'UPDATE_BRANDING',
      payload: axios.post(
        `${getMicroServiceUrl('sponsor-portal')}branding/update`,
        data,
        { headers: { 'Content-type': 'multipart/form-data' } }
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const getBranding = () => {
  return {
    type: 'GET_BRANDING',
    payload: axios.get(`${getMicroServiceUrl('sponsor-portal')}branding`),
  };
};
