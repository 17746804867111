import * as React from 'react';
import * as _ from 'lodash';
import Styled from './Select.style';

interface SelectProps {
  [anyProp: string]: any;
  options?: Array<{
    value: string;
    label: string;
  }>;
  placeholder?: string;
  onChange: Function;
  value: string | number | undefined;
  error?: string;
  borderColor?: string;
  required?: boolean;
  style?: any;
  label?: string;
  children?: React.ReactNode;
}

export const Select: React.FC<SelectProps> = (props) => {
  const {
    placeholder,
    options,
    onChange,
    error,
    value,
    borderColor,
    required,
    label,
    style,
    children,
    ...rest
  } = props;

  const renderList = () =>
    _.map(options, (el) => (
      <option key={el.value} value={el.value}>
        {el.label}
      </option>
    ));

  return (
    <div style={style}>
      <Styled.GlobalStyle />
      {label ? <Styled.Label htmlFor={props.id}>{label}</Styled.Label> : null}
      <Styled.Select
        value={value}
        {...rest}
        onChange={(e) => {
          onChange(e);
        }}
      >
        {placeholder ? (
          <option value="" disabled>
            {placeholder}
            {required ? '*' : null}
          </option>
        ) : null}

        {children ? children : renderList()}
      </Styled.Select>

      {error ? <Styled.FieldError>{error}</Styled.FieldError> : null}
    </div>
  );
};

export default Select;
