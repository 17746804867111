/* eslint-disable no-prototype-builtins */
import { ChartDataElement } from './../interfaces';
import React from 'react';
import { range } from '../assets/variable';
import {
  MetadataReducer,
  ChartData,
  ChartDataTransformed,
} from '../interfaces';
import * as _ from 'lodash';
import store from 'store2';
/**
 * Comparing metadataReducer (curently selected daterange and regionSlug)
 * @param current this.props
 * @param prev prevProps
 * @return Returns bool.
 **/
export function isMetaChanged(current: MetadataReducer, prev: MetadataReducer) {
  if (!current || !prev) {
    return false;
  }

  const { startDate, endDate, regionSlug, campaignId, focus } = current;

  const {
    startDate: p_startDate,
    endDate: p_endDate,
    regionSlug: p_regionSlug,
    campaignId: p_campaignId,
    focus: p_focus,
  } = prev;

  if (
    startDate !== p_startDate ||
    endDate !== p_endDate ||
    regionSlug !== p_regionSlug ||
    campaignId !== p_campaignId ||
    focus !== p_focus
  ) {
    return true;
  }
  return false;
}

export function colorRange(index: number, colors: string[]) {
  if (colors) {
    const rangeLengthIndexed = colors.length;
    return colors[index % rangeLengthIndexed];
  }

  const rangeLengthIndexed = range.length;
  return range[index % rangeLengthIndexed];
}

/**
 * Fetching endpoint when force is true or there is no data
 * or if page metadata is different from global one (datepicker)
 * @param pageId
 * @param updaterAction Run if update.
 * @param props
 * @param force Update anyway.
 * @return If required trigger `updaterAction`.
 **/
export function updateIfRequired(
  pageId: string,
  updaterAction: Function,
  props: any,
  force = false
) {
  if (
    force ||
    !_.get(props, `genericPage.${pageId}.data`) ||
    isMetaChanged(props.metadata, _.get(props, `genericPage.${pageId}.meta`))
  ) {
    // console.log('UPDATE DATA REQUIRED');
    const { startDate, endDate, regionSlug, campaignId, focus } =
      props.metadata;
    updaterAction(
      {
        startDate,
        endDate,
        regionSlug,
        campaignId,
        focus,
      },
      pageId
    );
  }
  return;
}

/**
 * Generating URL based on const REACT_APP_BASE_URL from .env
 * example `api/1.0/outdoor-garden`
 * @param relativeURL
 * @return Returns the accumulated value.
 **/
export function getEndpointURL(relativeURL: string) {
  return `${process.env.REACT_APP_BASE_URL}/${relativeURL}`;
}

export const chartDataTransform = (
  chartData: ChartData
): Array<ChartDataElement> => {
  if (
    // eslint-disable-next-line no-prototype-builtins
    chartData.hasOwnProperty('labels') &&
    Array.isArray(chartData.labels) &&
    Array.isArray(chartData.links) &&
    Array.isArray(chartData.datasets) &&
    chartData.labels.length > 0 &&
    chartData.links.length > 0 &&
    chartData.datasets.length > 0
  ) {
    const { labels } = chartData;

    return _.map(labels, (name, i) => {
      const datasets = {};
      const links = {};

      _.forEach(chartData.datasets, (set, setIndex) => {
        datasets[`dataset_${setIndex}`] = chartData.datasets[setIndex].data[i];

        links[`links_${setIndex}`] = chartData.links[i];
      });

      return {
        name,
        ...datasets,
        links,
      };
    });
  } else if (
    chartData.hasOwnProperty('labels') &&
    Array.isArray(chartData.labels) &&
    Array.isArray(chartData.datasets) &&
    chartData.labels.length > 0 &&
    chartData.datasets.length > 0
  ) {
    const { labels } = chartData;

    return _.map(labels, (name, i) => {
      const datasets = {};

      _.forEach(chartData.datasets, (set, setIndex) => {
        datasets[`dataset_${setIndex}`] = chartData.datasets[setIndex].data[i];
      });

      return {
        name,
        ...datasets,
      };
    });
  }
  return [];
};

export const chartDataColors = (chartData: ChartData, theme?: string) => {
  if (
    chartData.hasOwnProperty('datasets') &&
    Array.isArray(chartData.datasets) &&
    chartData.datasets.length > 0
  ) {
    const { datasets } = chartData;
    return _.map(datasets, (set) => {
      return _.get(set, 'theme', theme); // fallback to component theme
    });
  }
  return [];
};

export const setChartBarHeight = (
  data: ChartDataTransformed,
  chartData: ChartData,
  expectedBarHeight = 20
): number => {
  const datasetsLength = chartData.datasets.length;
  const dataLength = data.length;
  const legendHeight = 51;
  const elementDatasetSpace =
    datasetsLength > 1 ? 14 * (datasetsLength - 1) : 0;
  const padding = 30 * dataLength + elementDatasetSpace;
  return (
    legendHeight + padding + expectedBarHeight * datasetsLength * dataLength
  );
};

export const flattenChartData = (chartData) => {
  const data = _.map(chartData.labels, (element, index) => {
    return {
      value: _.get(chartData, `datasets[0].data[${index}]`, 0),
      label: element,
    };
  });

  return {
    total: _.sum(_.get(chartData, 'datasets[0].data', 0)),
    data,
  };
};

export const getFeature = (user, feature) => {
  const features = _.get(user, 'validFeatures');
  return _.filter(features, function (o) {
    return o.regionSlug === feature;
  });
};

export const getMarketplaceFeature = (user, key) => {
  const filter = getFeature(user, 'none');
  const features = _.get(filter, '[0].features');
  if (features) {
    return features.find((o) => o.name === key);
  } else return null;
};

export const getB2bFeature = (user, key) => {
  const filter = getFeature(user, 'b2b');

  const features = _.get(filter, '[0].features');
  if (features) {
    return features.find((o) => o.name === key);
  } else return null;
};

export const getB2cFeature = (user, key) => {
  const filter = getFeature(user, 'all-uk');
  const features = _.get(filter, '[0].features');
  if (features) {
    return features.find((o) => o.name === key);
  } else return null;
};

export const scrollToTop = () => {
  if (window.scrollTo) {
    window.scrollTo({ top: 0 });
  }
};

export const scrollToTopAnimated = () => {
  if (window.scrollTo) {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
};

export const useUpdateEffect = (callback, dependencies) => {
  const isFirstRender = React.useRef(true);
  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      return callback();
    }
  }, dependencies);
};
