import * as React from 'react';
import Styled from './Input.style';
import { Icon } from '../index';
import ReactTooltip from 'react-tooltip';
export interface InputProps {
  type?: 'text' | 'number' | 'password' | 'email' | 'tel' | 'date' | 'url';
  placeholder?: string;
  error?: string;
  label?: string;
  icon?: string;
  className?: any;
  id: string;
  tooltip?: string;
  [anyProp: string]: any;
  theme?: string;
}

export const Input: React.FC<InputProps> = (props) => {
  const {
    placeholder,
    tooltip,
    error,
    id,
    icon,
    label,
    style,
    className,
    theme,
  } = props;
  const [hidden, setHidden] = React.useState(false);
  return (
    <Styled.Wrapper className={className} style={style}>
      {label ? <label htmlFor={props.name}>{label}</label> : null}
      {theme === 'true' ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '6px',
          }}
        >
          <Styled.IconWrap
            data-tip={tooltip}
            data-place="top"
            data-type="info"
            data-for={id}
            style={theme && { cursor: 'pointer' }}
            onClick={() => {
              setHidden(!hidden);
            }}
          >
            <Icon icon={hidden ? 'eye' : 'eyeOff'} size={20} />
            {tooltip ? (
              <ReactTooltip id={id} className="extraClass" effect="solid" />
            ) : null}
          </Styled.IconWrap>

          <input
            placeholder={placeholder}
            type={hidden ? 'text' : 'password'}
            {...props}
          />
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '6px',
          }}
        >
          <Styled.IconWrap
            data-tip={tooltip}
            data-place="top"
            data-type="info"
            data-for={id}
          >
            <Icon icon={icon ? icon : 'pen'} size={20} />
            {tooltip ? (
              <ReactTooltip id={id} className="extraClass" effect="solid" />
            ) : null}
          </Styled.IconWrap>

          <input placeholder={placeholder} {...props} />
        </div>
      )}
      {error ? <Styled.Error>{error}</Styled.Error> : null}
    </Styled.Wrapper>
  );
};

export default Input;
