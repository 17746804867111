import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  background: white;
  border-radius: 6px;
  flex-direction: column;
`;
const ImgWrap = styled.div`
  padding: 32px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const TextWrap = styled.div`
  flex: 1;
  overflow: hidden;

  padding: 32px;
  border-radius: 6px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
export default { Wrap, TextWrap, ImgWrap };
