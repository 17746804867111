/* eslint-disable @typescript-eslint/no-empty-function */
/*eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { useLocation, Navigate } from 'react-router-dom';
import Styled from './GenericMarkdown.style';
import { Link } from 'react-router-dom';
import { appWidth } from '../../../helpers';

const GenericMarkdown: React.FC = (props) => {
  const [pageContent, setPageContent] = React.useState(null);
  const [error, setError] = React.useState(undefined);
  const location = useLocation();

  React.useEffect(() => {
    axios
      .get(`/pages/${location.pathname}.md`, {})
      .then((result) => {
        setPageContent(result.data);
      })
      .catch((err) => {
        setError(err);

        // redirect to homepage
      });
  }, []);
  const { width } = appWidth();
  const isDesktop = width > 1024;
  if (error) {
    return <Navigate to="/404" />;
  }
  return (
    <Styled.Wrap
      style={
        isDesktop
          ? { borderTopLeftRadius: '300px' }
          : { borderTopLeftRadius: '60px' }
      }
    >
      <Grid>
        <Row style={{ padding: '32px 0' }}>
          <Col xs={12}>
            <Link to="/">
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/logos/www-color.svg"
                alt="GWF Logo"
                height="30px"
              />
            </Link>
          </Col>
        </Row>
        <Row style={{ paddingBottom: '20px' }}>
          <Col xs={12}>
            <div>
              <ReactMarkdown>
                {pageContent ? pageContent : 'empty'}
              </ReactMarkdown>
            </div>
          </Col>
        </Row>
      </Grid>
    </Styled.Wrap>
  );
};

export default GenericMarkdown;
