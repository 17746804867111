import React from 'react';
import {
  Typography,
  Button,
  Credentials,
  ErrorDisplay,
  Loader,
  Input,
} from '../../../components';
import { Row, Col } from 'react-styled-flexboxgrid';
import * as _ from 'lodash';
import Reset from './Reset';
import { Tab } from './Tab';
import store from 'store2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
interface ProfileProps {
  user: any;
  resetPassword: any;
  resetPasswordRequest: Function;
  updateBusiness: Function;
  getUser: Function;
}
export const Profile: React.FC<ProfileProps> = (props) => {
  const { user, resetPassword, resetPasswordRequest, updateBusiness, getUser } =
    props;

  const doneMessage = _.get(resetPassword, 'status.done');
  const errorMessage = _.get(resetPassword, 'status.error');

  const { error, loading, done } = _.get(user, 'status');

  const roles = _.get(user, 'data.roles');
  const adminRole = roles && roles.find((o) => o === 'ROLE_SUPER_ADMIN');
  const storageProfile = store('profileKey');
  const [tabData, setTabData] = React.useState(
    storageProfile === null ? 'profile' : storageProfile
  );
  React.useEffect(() => {
    if (storageProfile === null) {
      store('profileKey', 'profile');
    }
  }, []);

  const tabs = [
    {
      label: 'Profile',
      key: 'profile',
      icon: 'user',
      color: '#0784ab',
      isDisplay: true,
    },
    {
      label: 'Credentials',
      key: 'credentials',
      icon: 'leaf',
      isDisplay: adminRole !== 'ROLE_SUPER_ADMIN',
    },
    {
      label: 'Reset password',
      key: 'reset',
      icon: 'lock',
      color: '#0784ab',
      isDisplay: true,
    },
  ];
  const pull_data = (data) => {
    setTabData(data);
    store('profileKey', data);
  };
  const userDataBusiness = _.get(user, 'data.business');
  const name = _.get(userDataBusiness, 'name');

  const companyUrl = _.get(userDataBusiness, 'url');
  const UploadLogoSchema = Yup.object().shape({
    name: Yup.string(),
    url: Yup.string(),
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: _.get(userDataBusiness, 'name'),
      url: companyUrl ? companyUrl : '',
    },
    validationSchema: UploadLogoSchema,
    onSubmit: (values, { setSubmitting, resetForm }) => {
      updateBusiness(
        values,
        () => {
          setSubmitting(false);
          resetForm();
        },
        () => {
          setSubmitting(false);
        }
      );
      setTimeout(() => {
        getUser();
      }, 500);
      setSubmitting(false);
    },
  });
  const profile = () => {
    return (
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <Row>
          <Col md={12} sm={12} xs={12}>
            <Typography size="2" text="Company details" />
            <Typography
              size="p"
              style={{ fontSize: '15px' }}
              text="Update your company details."
            />
          </Col>

          <Col md={6} sm={12} xs={12}>
            <Input
              type="text"
              placeholder="Company name"
              label="Company name"
              id="name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
            />
          </Col>
          <Col md={6} sm={12} xs={12}>
            <Input
              type="text"
              placeholder="Site url"
              label="Site url"
              id="url"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.url}
            />
          </Col>
          <Col
            sm={12}
            xs={12}
            md={12}
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
              marginTop: '16px',
            }}
          >
            <Button disabled={formik.isSubmitting}>Update</Button>
          </Col>
        </Row>
      </form>
    );
  };

  const credentials = () => {
    return <Credentials />;
  };

  const reset = () => {
    return (
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Reset
            doneMessage={doneMessage}
            errorMessage={errorMessage}
            setPreferences={resetPasswordRequest}
          />
        </Col>
      </Row>
    );
  };

  const sizeProps = (data) => {
    switch (data) {
      case 'profile':
        return profile();
      case 'credentials':
        return credentials();
      case 'reset':
        return reset();
      default:
        return reset();
    }
  };
  if (error) {
    return <ErrorDisplay />;
  }

  if (loading && !done) {
    return <Loader />;
  }
  return (
    <Tab data={tabs} getKey={tabData} onClickTab={pull_data}>
      {sizeProps(tabData)}
    </Tab>
  );
};

export default Profile;
