import { getVariable } from '../helpers';

export const colors = {
  baseDark: getVariable('colors', 'baseDark', '#575757'),
  baseLight: getVariable('colors', 'baseLight', '#FFF'),
  error: getVariable('colors', 'error', '#FF4136'),
  success: getVariable('colors', 'success', '#56BB00'),
  disabled: getVariable('colors', 'disabled', '#D8D8D8'),
  primary: getVariable('colors', 'primary', '#0784ab'),
  secondary: getVariable('colors', 'secondary', '#83389E'),
  action: getVariable('colors', 'action', '#3830a3'),
  lightGray: getVariable('colors', 'lightGray', '#E0E0E0'),
  darkGray: getVariable('colors', 'darkGray', '#595959'),
  water: getVariable('colors', 'water', '#25b3eb'),
  energy: getVariable('colors', 'energy', '#F4AC05'),
  money: getVariable('colors', 'money', '#54A93E'),
  tertiary: getVariable('colors', 'action', '#d7f1ff'),

  c1: '#3182CE',
  c2: 'rgb(49, 151, 149)',
  c3: 'rgb(221, 107, 32)',
  c4: 'rgb(226, 232, 240)',
  c5: 'rgb(55, 48, 163)',
  t1: '#25B3EB',
  t2: '#9d68a7',
  t3: '#f3a536',
  t4: '#1d7fcb',
  t5: '#21c4c5',
  t6: '#8ed131',
  t7: '#1F87C4',
};

export const range = [
  '#1683fb',
  '#1375E1',
  '#1068C8',
  '#0D5BAF',
  '#0A4E96',
  '#07417D',
  '#043464',
  '#02274b',
];
