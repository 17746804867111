import styled from 'styled-components';

const Wrap = styled.div`
  display: flex;
  justify-content: flex-end;
  max-height: 45px;
  @media screen and (max-width: 1281px) {
    justify-content: flex-start;
  }
`;

const Toggle = styled.div`
  border: 1px solid #0784ab;
  padding: 10px 16px;
  color: #0784ab;
  cursor: pointer;
  transition: all 100ms ease-in-out;
  &.active {
    background: #0784ab;
    color: white;
    &:hover {
      background: #0784ab;
      color: white;
    }
  }
  &:hover {
    background: #ccf3ff;
  }
  &:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  &:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
`;
export default { Wrap, Toggle };
