import styled from 'styled-components';

const ChartPie = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1200px) {
    flex-direction: column;
  }
`;

const Square = styled.div`
  position: relative;
  width: 100%;
  max-width: 250px;
  max-height: 250px;
  margin: 32px auto 0px auto;
  &:after {
    content: '';
    display: block;
  }
  .recharts-pie-label-line {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    order: 1;
  }
`;

const Title = styled.div`
  font-size: 1.4em;
  line-height: 1.2em;
  min-height: 4.6em;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;

  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Icon = styled.div`
  text-align: center;
  margin-bottom: 8px;
`;

const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  svg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
`;

const Legend = styled.div`
  flex: 1;
  display: flex;
  /* align-items: center; */
  justify-content: center;

  margin-top: 32px;
  @media screen and (max-width: 1200px) {
    order: 2;
  }
`;
const Card = styled.div`
  display: flex;
  border-radius: 16px;
  background: white;
  flex-direction: column;
  height: 100%;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
`;
const CardBody = styled.div`
  padding: 0 32px;
`;
const CardFooter = styled.div`
  padding: 16px 32px;
  margin-top: auto;
  border-top: 1px solid rgb(226, 232, 240);
  background: rgb(248, 250, 252);
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
`;
const CardHeader = styled.div`
  padding: 32px 32px 0px 32px;
`;
const Total = styled.div`
  display: flex;
  text-align: center;

  h4 {
    &:first-child {
      flex: 1;
      justify-content: flex-start;
      text-align: left;
    }
    margin-bottom: 0px;
  }
`;
export default {
  ChartPie,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Title,
  Total,
  Icon,
  Content,
  Legend,
  Square,
};
