import React from 'react';
import classnames from 'classnames';
import { usePagination } from './usePagination';
import Styled from './Pagination.style';
import * as _ from 'lodash';
const Pagination = (props) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || (paginationRange && paginationRange.length < 2)) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onNumber = (id) => {
    onPageChange(id);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  const renderMiddlePage = () => {
    if (paginationRange && paginationRange.length <= 5) {
      const array = Array(paginationRange.length);
      return _.map(array, (el, index) => {
        return (
          <li
            className={currentPage === index + 1 ? 'active' : ''}
            onClick={() => onNumber(index + 1)}
            key={index}
          >
            {index + 1}
          </li>
        );
      });
    }
  };

  const lastPage =
    paginationRange && paginationRange[paginationRange.length - 1];
  const arrowLeft = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 19l-7-7 7-7"
      ></path>
    </svg>
  );
  const arrowRight = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9 5l7 7-7 7"
      ></path>
    </svg>
  );

  return (
    <Styled.Wrap className={classnames({ [className]: className })}>
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        {arrowLeft}
      </li>

      {renderMiddlePage()}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        {arrowRight}
      </li>
    </Styled.Wrap>
  );
};

export default Pagination;
