import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import Styled from './Downloads.style';
import {
  Icon,
  Button,
  Typography,
  Select,
  NoFeature,
  Pagination,
  Loader,
} from '../../../components/index';
import _ from 'lodash';
import moment from 'moment';
import { Link } from 'react-router-dom';
import store from 'store2';
import ReactTooltip from 'react-tooltip';
interface DownloadProps {
  campaign: any;
  getAllCampaigns: Function;
  getDownloadCsvData: Function;
  metadata: any;
}

export const Downloads: React.FC<DownloadProps> = (props) => {
  const { campaign, getAllCampaigns, getDownloadCsvData, metadata } = props;

  React.useEffect(() => {
    getAllCampaigns();
  }, []);

  const dateNow = moment().format('YYYY-MM-DD');
  const PageSize = 8;
  const campaignData = _.get(campaign, 'getCampaign.data');
  const campaignLength = _.get(campaignData, 'length');
  const { error, loading, done } = _.get(campaign, 'getCampaign.status');

  const [currentPage, setCurrentPage] = React.useState(1);
  const [isSorting, setSorting] = React.useState('');
  const filteredDataId = _.orderBy(campaignData, ['id'], ['desc']);

  const filteredDataExp = _.orderBy(filteredDataId, ['expires'], ['desc']);
  const sortedZA = _.orderBy(filteredDataExp, [(o) => o.name], ['desc']);
  const sortedAZ = _.orderBy(filteredDataExp, [(o) => o.name], ['asc']);
  const sortedNew = _.orderBy(filteredDataExp, [(o) => o.createdAt], ['asc']);

  const sorting = [
    { label: 'Newest to oldest', value: 'new' },
    { label: 'Alphabetical (A - Z)', value: 'az' },
    { label: 'Alphabetical (Z - A)', value: 'za' },
  ];
  const renderSorted = () => {
    switch (isSorting) {
      case 'za':
        return sortedZA;
      case 'az':
        return sortedAZ;
      case 'new':
        return sortedNew;
      default:
        return filteredDataExp;
    }
  };
  const currentTableData = React.useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return (
      renderSorted() && renderSorted().slice(firstPageIndex, lastPageIndex)
    );
  }, [PageSize, currentPage, campaignData]);

  const user = store.get('user');
  const features = user.validFeatures;
  const createCampaigns = features[0].features.find(
    (o) => o.name === 'CREATE_CAMPAIGNS'
  );

  if (loading) {
    return <Loader />;
  }
  if (!createCampaigns) {
    return <NoFeature />;
  }

  const renderCampaign = () => {
    return _.map(currentTableData, (el, index) => {
      return (
        <Col md={3} sm={6} xs={12} key={index} style={{ marginBottom: '16px' }}>
          <Styled.Card>
            <Styled.Body>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'space-between',
                }}
              >
                <Styled.Status
                  style={
                    moment(el.expires).isBefore(dateNow)
                      ? {
                          backgroundColor: 'rgb(252, 231, 235)',
                          color: 'rgb(157, 23, 23)',
                        }
                      : {
                          backgroundColor: 'rgb(220, 252, 231)',
                          color: 'rgb(22, 101, 52)',
                        }
                  }
                >
                  {moment(el.expires).isBefore(dateNow) ? 'ENDED' : 'ACTIVE'}
                </Styled.Status>
                <div>
                  {el.type === 'B2C' ? (
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        background: '#25b3eb',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                      data-tip="GetWaterFit"
                      data-place="top"
                      data-type="info"
                      data-for="type-tag"
                    >
                      <Icon icon="gwf" size={18} colorPrimary="#fff" />
                    </div>
                  ) : (
                    <div
                      style={{
                        width: '30px',
                        height: '30px',
                        background: '#0b2641',
                        borderRadius: '50%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        margin: 'auto',
                      }}
                      data-tip="GetWaterFit for Business"
                      data-place="top"
                      data-type="info"
                      data-for="type-tag"
                    >
                      <Icon icon="gwf" size={18} colorPrimary="#fff" />
                    </div>
                  )}
                  <ReactTooltip
                    id="type-tag"
                    className="extraClass"
                    effect="solid"
                  />
                </div>
              </div>

              <p className="title">{el.name}</p>
              <p className="description">{el.description}</p>
              <div className="separator"></div>
              <Styled.Date>
                <Icon size={20} icon="calendar" />
                <Typography
                  size="p"
                  style={{
                    fontSize: '16px',
                    fontWeight: 500,
                    margin: '0px 0px 0px 8px',
                  }}
                  text={moment(el.expires).format('YYYY-MM-DD')}
                />
              </Styled.Date>
            </Styled.Body>
            <Styled.Footer>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button theme="WHITE" onClick={() => getDownloadCsvData(el.id)}>
                  Download CSV
                </Button>
              </div>
            </Styled.Footer>
          </Styled.Card>
        </Col>
      );
    });
  };
  const renderNoCampaigns = () => {
    return (
      <Styled.AdvBanner>
        <img
          src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/marketing.svg"
          alt="No data"
        />
        <Typography
          style={{ paddingTop: '32px' }}
          size="2"
          text="There are no campaigns created."
        />
        <Typography
          size="p"
          text="Please create one in order to download the CSV."
        />
        <Link className="campaign-link" to="/campaigns">
          Take me there <Icon icon="send" size={16} />
        </Link>
      </Styled.AdvBanner>
    );
  };

  return (
    <Row style={{ width: '100%' }}>
      {/* <Col sm={12} md={12} xs={12} style={{ marginBottom: '16px' }}>
        <Row>
          <Col md={2} xs={12} sm={12}>
            <Select
              options={sorting}
              placeholder="Sort by"
              onChange={(e) => setSorting(e.currentTarget.value)}
              value={isSorting}
              defaultValue={'new'}
              name="sorting"
            />
          </Col>
        </Row>
      </Col> */}

      <Col md={12} sm={12} xs={12}>
        {campaignLength > 0 ? (
          <Row>{renderCampaign()}</Row>
        ) : (
          <>{renderNoCampaigns()}</>
        )}
      </Col>
      <Col md={12} xs={12}>
        <Styled.PaginationWrap>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={campaignData && campaignLength}
            pageSize={PageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Styled.PaginationWrap>
      </Col>
    </Row>
  );
};

export default Downloads;
