import axios from 'axios';
import { getEndpointURL } from '../helpers/helpers';

export const toConstName = (string: string): string => {
  if (!string) {
    return '';
  }
  return string.toUpperCase().replace('-', '_');
};

export const getEndpointData = (meta, endpoint) => {
  const url = `gwf-${endpoint}`;

  return (dispatch) => {
    dispatch(getEndpointDataPending(endpoint));
    return axios
      .get(getEndpointURL(url), {
        params: {
          ...meta,
        },
      })
      .then((resp) => {
        dispatch(getEndpointDataFulfilled(resp, endpoint));
      })
      .catch((error) => {
        dispatch(getEndpointDataRejected(error, endpoint));
      });
  };
};

export const getEndpointDataPending = (endpointName) => {
  return {
    endpointName,
    type: `GET_${toConstName(endpointName)}_DATA_PENDING`,
  };
};
export const getEndpointDataFulfilled = (resp, endpointName) => {
  return {
    type: `GET_${toConstName(endpointName)}_DATA_FULFILLED`,
    endpointName,
    payload: resp,
  };
};
export const getEndpointDataRejected = (resp, endpointName) => {
  return {
    type: `GET_${toConstName(endpointName)}_DATA_REJECTED`,
    endpointName,
    payload: resp,
  };
};
