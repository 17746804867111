import * as React from 'react';

const send = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <line x1="90" y1="0" x2="40.5" y2="49.5"></line>
        <polygon points="90 0 58.5 90 40.5 49.5 0 31.5"></polygon>
      </g>
    </g>
  );
};
export default send;
