import * as React from 'react';

const arrow = (props: any) => {
  const { colorPrimary } = props;
  return (
    <g
      stroke="none"
      strokeWidth="7"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g transform="translate(5.000000, 5.000000)" stroke={colorPrimary}>
        <line x1="0" y1="45" x2="90" y2="45"></line>
        <polyline points="45 0 90 45 45 90"></polyline>
      </g>
    </g>
  );
};
export default arrow;
