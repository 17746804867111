import * as _ from 'lodash';
import { LicenceReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
export const initialState: LicenceReducer = {
  listLicences: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  getBusinessLicence: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  createLicence: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  updateLicence: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};
const licenceReducer = createReducer(initialState, {
  LIST_LICENCES_FULFILLED: (state, action) => {
    state.listLicences.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.listLicences.data = _.get(action, 'payload.data');
  },
  LIST_LICENCES_PENDING: (state) => {
    state.listLicences.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  LIST_LICENCES_REJECTED: (state, action) => {
    state.listLicences.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  GET_BUSINESS_LICENCE_FULFILLED: (state, action) => {
    state.getBusinessLicence.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getBusinessLicence.data = _.get(action, 'payload.data');
  },
  GET_BUSINESS_LICENCE_PENDING: (state) => {
    state.getBusinessLicence.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_BUSINESS_LICENCE_REJECTED: (state, action) => {
    state.getBusinessLicence.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  CREATE_LICENCE_FULFILLED: (state, action) => {
    state.createLicence.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.createLicence.data = _.get(action, 'payload.data');
  },
  CREATE_LICENCE_PENDING: (state) => {
    state.createLicence.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  CREATE_LICENCE_REJECTED: (state, action) => {
    state.createLicence.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  UPDATE_LICENCE_FULFILLED: (state, action) => {
    state.updateLicence.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.updateLicence.data = _.get(action, 'payload.data');
  },
  UPDATE_LICENCE_PENDING: (state) => {
    state.updateLicence.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  UPDATE_LICENCE_REJECTED: (state, action) => {
    state.updateLicence.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});
export default licenceReducer;
