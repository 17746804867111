import * as React from 'react';

const lock = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(9.500000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M40.5,67.5 C42.98535,67.5 45,65.48535 45,63 C45,60.51465 42.98535,58.5 40.5,58.5 C38.01465,58.5 36,60.51465 36,63 C36,65.48535 38.01465,67.5 40.5,67.5 Z"></path>
        <path d="M72,36 L9,36 C4.029435,36 0,40.0293 0,45 L0,81 C0,85.9707 4.029435,90 9,90 L72,90 C76.9707,90 81,85.9707 81,81 L81,45 C81,40.0293 76.9707,36 72,36 Z"></path>
        <path d="M18,36 L18,22.5 C18,16.53264 20.37051,10.809675 24.590115,6.590115 C28.809675,2.37051 34.53255,0 40.5,0 C46.46745,0 52.19055,2.37051 56.40975,6.590115 C60.6294,10.809675 63,16.53264 63,22.5 L63,36"></path>
      </g>
    </g>
  );
};
export default lock;
