import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto !important;
  display: flex;
  border-radius: 16px;
  background: white;
  flex-direction: column;
  padding: 16px;

  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.04);
  @media screen and (max-width: 900px) {
    flex-direction: column;
  }
`;

const Icon = styled.div`
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 10px;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 900px) {
    margin: 0 auto 10px;
  }
`;
const Text = styled.div`
  display: flex;
  flex: 1;
  overflow-wrap: anywhere;
  hyphens: auto;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Subtitle = styled.div`
  display: flex;
  color: rgb(141, 141, 141);
  font-size: 16px;

  @media screen and (max-width: 900px) {
    text-align: center;
    margin-bottom: 6px;
    min-height: 44px;
  }
`;

const Title = styled.div`
  display: flex;
  font-size: 30px;
  font-weight: 700;
  color: rgb(55, 48, 163);
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

export default {
  Icon,
  Text,
  Title,
  Subtitle,
  Wrapper,
};
