import * as _ from 'lodash';
import { BannerReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: BannerReducer = {
  createBanner: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  updateBanner: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  deleteBanner: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  getBannerTag: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const bannerReducer = createReducer(initialState, {
  CREATE_BANNER_FULFILLED: (state, action) => {
    state.createBanner.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.createBanner.data = _.get(action, 'payload.data');
  },
  CREATE_BANNER_PENDING: (state) => {
    state.createBanner.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  CREATE_BANNER_REJECTED: (state, action) => {
    state.createBanner.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  UPDATE_BANNER_FULFILLED: (state, action) => {
    state.updateBanner.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.updateBanner.data = _.get(action, 'payload.data');
  },
  UPDATE_BANNER_PENDING: (state) => {
    state.updateBanner.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  UPDATE_BANNER_REJECTED: (state, action) => {
    state.updateBanner.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  DELETE_BANNER_FULFILLED: (state, action) => {
    state.deleteBanner.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.deleteBanner.data = _.get(action, 'payload.data');
  },
  DELETE_BANNER_PENDING: (state) => {
    state.deleteBanner.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  DELETE_BANNER_REJECTED: (state, action) => {
    state.deleteBanner.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  GET_BANNER_TAGS_FULFILLED: (state, action) => {
    state.getBannerTag.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getBannerTag.data = _.get(action, 'payload.data');
  },
  GET_BANNER_TAGS_PENDING: (state) => {
    state.getBannerTag.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_BANNER_TAGS_REJECTED: (state, action) => {
    state.getBannerTag.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});

export default bannerReducer;
