import React from 'react';
import Styled from './Login.style';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Typography, Input, Button, Notification } from '../../../components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { appWidth } from '../../../helpers';
import store from 'store2';
import _ from 'lodash';
interface LoginProps {
  logIn: Function;
  auth: any;
}
export const Login: React.FC<LoginProps> = (props) => {
  const { logIn, auth } = props;
  const { loading, done, error } = auth.loginRequest.status;
  const isAuth = localStorage.getItem('swsmUser');
  const navigate = useNavigate();
  const location = useLocation();
  const getParam = location.search.substring(location.search.indexOf('=') + 1);

  const renderNotifications = () => {
    if (getParam && getParam === 'verificationUnknown') {
      return (
        <Notification
          isClose
          title="Something went wrong, please check your email or contact us."
          theme="Error"
        />
      );
    } else if (getParam && getParam === 'verificationExpired') {
      return (
        <Notification
          isClose
          title="Verification has expired, please register a new account."
          theme="Error"
        />
      );
    } else if (getParam && getParam === 'verificationSuccess') {
      return (
        <Notification
          isClose
          title="Success! You can now login to your account."
          theme="Success"
        />
      );
    }
  };
  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .min(2, 'Password too short')
      .max(50, 'Password too long')
      .required('Required'),
    username: Yup.string()
      .email('Email not valid')
      .required('Email is required'),
  });
  const { width } = appWidth();
  React.useEffect(() => {
    if (done && isAuth) {
      navigate('/');
    }
  }, [done]);
  const isDesktop = width > 1024;
  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          username: '',
          password: '',
        }}
        validationSchema={SignupSchema}
        onSubmit={(values, { setSubmitting }) => {
          logIn(values);
          setSubmitting(false);
        }}
      >
        {({
          values,
          isSubmitting,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
        }) => (
          <Form onSubmit={handleSubmit}>
            <Input
              placeholder="Email"
              type="text"
              className="introX-right"
              id="username"
              icon="email"
              name="username"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.username}
              style={{ marginBottom: '16px' }}
              error={
                errors.username && touched.username
                  ? errors.username
                  : undefined
              }
            />
            <Input
              placeholder="Password"
              className="introX-right"
              id="password"
              name="password"
              theme="true"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              style={{ marginBottom: '16px' }}
              error={
                errors.password && touched.password
                  ? errors.password
                  : undefined
              }
            />
            {/* <Href
              text="Forgot password?"
              className="introX-right"
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '16px',
                fontSize: '15px',
                textDecoration: 'underline',
              }}
              onClick={() => navigate('/forgot-password')}
            /> */}
            {error ? (
              <Notification isClose title={error} theme="Error" />
            ) : null}
            <div>
              <Button
                type="submit"
                theme="COLOR"
                className="introX-right"
                disabled={isSubmitting || loading}
                style={{ marginRight: '16px', marginBottom: '16px' }}
              >
                Sign in
              </Button>
              {/* <Button
                className="introX-right"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/register');
                }}
                theme="WHITE"
              >
                Register
              </Button> */}
              {renderNotifications()}
            </div>{' '}
          </Form>
        )}
      </Formik>
    );
  };

  return isDesktop ? (
    <Styled.Wrap>
      <Row style={{ height: '100%' }}>
        <Col style={{ height: isDesktop ? '100%' : '100%' }} md={5}>
          <Styled.HeadLineWrap>
            <div style={{ width: '550px', paddingTop: '32px' }}>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/www-white.svg"
                alt="GetWaterFit"
                className="introY-top"
                height="35px"
              />
            </div>
            <Styled.TextContainer>
              <img
                src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/corporate_woman.svg"
                alt="Corporate woman"
                className="introX-left"
                style={{ minWidth: '550px' }}
              />
              <Typography
                size="1"
                className="introX-left"
                style={{ color: 'white' }}
                text="A few more clicks to sign in to your account."
              />
              <Typography
                size="p"
                className="introX-left"
                style={{ color: 'white' }}
                text="Manage all your campaigns in one place."
              />
            </Styled.TextContainer>
            <div
              style={{
                display: 'flex',
                marginBottom: '32px',
              }}
            >
              <Link
                to="/terms-and-conditions"
                className="introY-bottom"
                target="_blank"
                style={{
                  color: 'white',
                  textDecoration: 'underline',
                  marginRight: '16px',
                }}
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>
              <Link
                to="/privacy-policy"
                className="introY-bottom"
                target="_blank"
                style={{ color: 'white', textDecoration: 'underline' }}
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </div>
          </Styled.HeadLineWrap>
        </Col>
        <Col style={{ height: '100%' }} md={7}>
          <Styled.FormWrap>
            <div style={{ width: '500px' }}>
              <Typography
                size="2"
                style={{ fontSize: '30px' }}
                className="introX-right"
                text="Sign in"
              />

              {renderForm()}
              <p className="introX-right" style={{ fontSize: '14px' }}>
                <i>
                  {' '}
                  By signing up you agree to the{' '}
                  <Link
                    style={{ textDecoration: 'underline', color: 'black' }}
                    to="/terms-and-conditions"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms and Conditions
                  </Link>{' '}
                  and{' '}
                  <Link
                    style={{ textDecoration: 'underline', color: 'black' }}
                    to="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </Link>
                </i>
              </p>
            </div>
          </Styled.FormWrap>
        </Col>
      </Row>
    </Styled.Wrap>
  ) : (
    <Styled.MobileWrap>
      <div style={{ marginTop: '16px' }}>
        <img
          src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/www-white.svg"
          alt="GetWaterFit"
          height="35px"
        />
      </div>
      <Styled.MobileForm>
        <div>
          <Typography size="2" style={{ fontSize: '30px' }} text="Sign in" />
          {renderForm()}
          <p style={{ fontSize: '14px' }}>
            <i>
              {' '}
              By signing up you agree to the{' '}
              <Link
                style={{ textDecoration: 'underline', color: 'black' }}
                to="/terms-and-conditions"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms and Conditions
              </Link>{' '}
              and{' '}
              <Link
                style={{ textDecoration: 'underline', color: 'black' }}
                to="/privacy-policy"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </Link>
            </i>
          </p>
        </div>
      </Styled.MobileForm>
      <div
        style={{
          display: 'flex',
          marginBottom: '32px',
        }}
      >
        <Link
          to="/terms-and-conditions"
          className="introY-bottom"
          target="_blank"
          style={{
            color: 'white',
            textDecoration: 'underline',
            marginRight: '16px',
          }}
          rel="noopener noreferrer"
        >
          Terms and Conditions
        </Link>
        <Link
          to="/privacy-policy"
          className="introY-bottom"
          target="_blank"
          style={{ color: 'white', textDecoration: 'underline' }}
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </div>
    </Styled.MobileWrap>
  );
};

export default Login;
