import React from 'react';

const NothingToDisplay = () => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img
        style={{ width: '120px', marginRight: '16px' }}
        src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/DonutChart.svg"
        alt="Not found"
      />
      There is no data to show for this specific date range.
    </div>
  );
};

export default NothingToDisplay;
