import React, { useState } from 'react';
import {
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  AreaChart,
  Area,
  Legend,
} from 'recharts';
import moment from 'moment';
import Styled from './ChartLine.style';
import {
  TicksDateRotated,
  TooltipDateWithPeriod,
  groupByPeriod,
  chartDataTransform,
} from '../../helpers';
import { colors } from '../../assets/variable';
import {
  TimePeriodSwitcher,
  Card,
  NothingToDisplay,
  Typography,
} from '../index';
import { ChartData } from '../../interfaces';
import * as _ from 'lodash';

// TODO: make sure we show days for day period
export const ticks = (label) => {
  return moment(label).format('MMM YYYY');
};
interface ChartLineProps {
  chartData: ChartData;
  title: string;
  theme?: string;
  id: number;
  icon: string;
  chartKey: string;
}

const ChartLine: React.FC<ChartLineProps> = (props) => {
  const { chartData, title, theme = 'c1', id, icon, chartKey } = props;
  const data = chartDataTransform(chartData);
  const totals = _.sum(chartData.totals);

  type DisplayPeriodState = 'day' | 'month' | 'week';
  const [displayPeriod, changeDisplayPeriod] =
    useState<DisplayPeriodState>('day');

  const getPeriod = () => {
    switch (displayPeriod) {
      case 'month':
        return groupByPeriod(data, 'M');
      case 'week':
        return groupByPeriod(data, 'W');
      default:
        return data;
    }
  };

  const dataWithPeriod = getPeriod();
  const themes = theme.split(',');

  const renderLines = () => {
    return _.map(chartData.datasets, (line, index) => {
      return (
        <Area
          key={index}
          isAnimationActive={false}
          strokeWidth={2}
          dataKey={`dataset_${index}`}
          name={line.label}
          fill={colors[_.get(themes, index, theme)]}
          stroke={colors[_.get(themes, index, theme)]}
          fillOpacity={0.1}
        />
      );
    });
  };

  return (
    <Styled.Card key={chartKey}>
      <Styled.CardHeader>
        <Typography size="2" text={title} />
        {dataWithPeriod.length > 0 && totals > 0 ? (
          <TimePeriodSwitcher
            active={displayPeriod}
            changeDisplayPeriod={changeDisplayPeriod}
          />
        ) : null}{' '}
      </Styled.CardHeader>
      {dataWithPeriod.length > 0 && totals > 0 ? (
        <div style={{ marginTop: '32px' }}>
          <ResponsiveContainer height={500} width="100%">
            <AreaChart
              data={dataWithPeriod}
              margin={{
                top: 20,
                right: 0,
                left: 0,
                bottom: 90,
              }}
            >
              <XAxis
                stroke="#999"
                dataKey="name"
                tick={<TicksDateRotated period={displayPeriod} />}
                minTickGap={0}
              />
              <YAxis stroke="#999" width={80} />
              <CartesianGrid strokeWidth={0.5} stroke="#ddd" />
              <Tooltip
                content={(e) => {
                  return TooltipDateWithPeriod(e, displayPeriod, true);
                }}
              />
              <Legend />
              <Brush
                dataKey="name"
                travellerWidth={10}
                y={420}
                tickFormatter={ticks}
              />
              {renderLines()}
            </AreaChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <NothingToDisplay />
      )}
    </Styled.Card>
  );
};

export default ChartLine;
