import * as _ from 'lodash';
import { PaymentReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';
export const initialState: PaymentReducer = {
  getPayment: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  addPayment: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  deletePayment: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  addDefaultPayment: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};
const paymentReducer = createReducer(initialState, {
  GET_PAYMENT_METHODS_FULFILLED: (state, action) => {
    state.getPayment.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getPayment.data = _.get(action, 'payload.data');
  },
  GET_PAYMENT_METHODS_PENDING: (state) => {
    state.getPayment.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_PAYMENT_METHODS_REJECTED: (state, action) => {
    state.getPayment.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  ADD_PAYMENT_METHOD_FULFILLED: (state, action) => {
    state.addPayment.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.addPayment.data = _.get(action, 'payload.data');
  },
  ADD_PAYMENT_METHOD_PENDING: (state) => {
    state.addPayment.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  ADD_PAYMENT_METHOD_REJECTED: (state, action) => {
    state.addPayment.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  ADD_DEFAULT_PAYMENT_METHOD_FULFILLED: (state, action) => {
    state.addDefaultPayment.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.addDefaultPayment.data = _.get(action, 'payload.data');
  },
  ADD_DEFAULT_PAYMENT_METHOD_PENDING: (state) => {
    state.addDefaultPayment.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  ADD_DEFAULT_PAYMENT_METHOD_REJECTED: (state, action) => {
    state.addDefaultPayment.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  DELETE_PAYMENT_METHOD_FULFILLED: (state, action) => {
    state.addDefaultPayment.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.addDefaultPayment.data = _.get(action, 'payload.data');
  },
  DELETE_PAYMENT_METHOD_PENDING: (state) => {
    state.addDefaultPayment.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  DELETE_PAYMENT_METHOD_REJECTED: (state, action) => {
    state.addDefaultPayment.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
});
export default paymentReducer;
