import React from 'react';
import Styled from './Textarea.style';

interface TextareaProps {
  id: string;
  label: string;
  placeholder?: string;
  value: string;
  [anyProp: string]: any;
}

export const Textarea: React.FC<TextareaProps> = (props) => {
  const { id, label, placeholder, value } = props;
  return (
    <Styled.Wrap>
      <label htmlFor={id}>{label}</label>
      <textarea placeholder={placeholder} name={id} {...props} />
    </Styled.Wrap>
  );
};

export default Textarea;
