import * as React from 'react';

const user = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(10.000000, 5.000000)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path d="M40,50 C53.807,50 65,38.807 65,25 C65,11.1929 53.807,0 40,0 C26.1929,0 15,11.1929 15,25 C15,38.807 26.1929,50 40,50 Z"></path>
        <path d="M80,90 C80,79.3915 75.7855,69.217 68.2845,61.7155 C60.783,54.2145 50.6085,50 40,50 C29.39135,50 19.2172,54.2145 11.71575,61.7155 C4.21425,69.217 0,79.3915 0,90"></path>
      </g>
    </g>
  );
};
export default user;
