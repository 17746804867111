import React from 'react';
import { Row, Col } from 'react-styled-flexboxgrid';
import { Card, Typography, Href } from '../../../components';
import * as _ from 'lodash';
import UtmCreate from './UTM/UtmCreate/index';
import UtmList from './UTM/UtmList/index';
import Branding from './Branding';
import { getMarketplaceFeature } from '../../../helpers';
import Styled from './UTMBranding.style';
interface UTMBrandingProps {
  getBranding: Function;
  user: any;
  branding: any;
}

export const UtmBranding: React.FC<UTMBrandingProps> = (props) => {
  const { getBranding, branding, user } = props;
  const userData = _.get(user, 'data');
  const brandingId = _.get(branding, 'getBranding.data.id');
  const haveBranding = getMarketplaceFeature(userData, 'CREATE_BRANDING');

  React.useEffect(() => {
    getBranding();
  }, []);
  const descriptionText =
    'Upload your company logo along with your directional banner and the GetWaterFit portal will display it.';

  const noFeature = () => {
    if (haveBranding) {
      return (
        <Row>
          <Col md={12} xs={12} sm={12} style={{ marginBottom: '16px' }}>
            <Card>
              <Typography
                size="2"
                text="Company branding"
                style={{ marginBottom: '16px' }}
              />
              <Typography
                size="p"
                style={{ fontSize: '15px' }}
                text={descriptionText}
              />
              <Branding />
            </Card>{' '}
          </Col>
          {brandingId && (
            <Col md={6} xs={12} sm={12}>
              <Card>
                <Typography size="2" text="Create UTM tag" />
                <UtmCreate />
              </Card>
            </Col>
          )}
          {branding && (
            <Col md={6} xs={12} sm={12}>
              <Card>
                <Typography size="2" text="My UTM Tags" />
                <UtmList />
              </Card>
            </Col>
          )}
        </Row>
      );
    }
    return (
      <Col md={12} sm={12} xs={12} style={{ marginBottom: '16px' }}>
        <Styled.Banner>
          <Styled.ImageWrap>
            <img
              src="https://cdn.getwaterfit.co.uk/static/files/images/benefactor-portal/marketing.svg"
              alt="Marketing"
            />
          </Styled.ImageWrap>
          <Styled.TextWrap>
            <Typography
              size="1"
              text="Feature not available"
              style={{ marginTop: '0px' }}
            />
            <Typography
              size="3"
              text="Contact our sales team to enable this feature."
              style={{ marginTop: '0px' }}
            />
            <Href
              icon="send"
              text="Contact us"
              style={{ marginTop: '40px' }}
              href="mailto:claudiu.soare@savewatersavemoney.com"
            />
          </Styled.TextWrap>
        </Styled.Banner>
      </Col>
    );
  };
  return noFeature();
};

export default UtmBranding;
