import React from 'react';
import Styled from './ListBanners.style';
import { Icon, Typography } from '../../../../components';
import _ from 'lodash';
import { Row, Col } from 'react-styled-flexboxgrid';
import { useNavigate } from 'react-router-dom';

import ReactPaginate from 'react-paginate';

interface ListBannersProps {
  data: any;
}
export const ListBanners: React.FC<ListBannersProps> = (props) => {
  const { data } = props;

  const sortedData = _.orderBy(data, ['createdAt'], ['desc']);

  const [pageNumber, setPageNumber] = React.useState(0);
  const ItemsPerPage = 6;
  const pagesVisited = pageNumber * ItemsPerPage;
  const displayUsers =
    sortedData && sortedData.slice(pagesVisited, pagesVisited + ItemsPerPage);

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  const pageCount = Math.ceil(sortedData && sortedData.length / ItemsPerPage);
  const navigate = useNavigate();

  const tableItems = () => {
    return _.map(displayUsers, (el, index) => {
      return (
        <tr key={index}>
          <td
            className="item"
            style={{ fontWeight: 700, whiteSpace: 'nowrap' }}
          >
            <Styled.NameWrap>
              <Styled.IconWrap>
                <Icon icon="market" size={18} colorPrimary="rgb(146, 64, 14)" />
              </Styled.IconWrap>
              <Styled.TextWrap>{el.title}</Styled.TextWrap>
            </Styled.NameWrap>
          </td>
          <td>
            <p style={{ textAlign: 'center' }}>{el.published ? 'Yes' : 'No'}</p>
          </td>
          <td className="text-center item no-wrap">
            <Styled.EditButton onClick={() => navigate(`/myshop/${el.id}`)}>
              Edit
              <Icon
                icon="arrow"
                colorPrimary="rgb(100, 116, 139)"
                size={16}
                style={{ marginLeft: '8px' }}
              />{' '}
            </Styled.EditButton>
          </td>
        </tr>
      );
    });
  };

  return (
    <>
      <Row style={{ display: 'flex', alignItems: 'center' }}>
        <Col md={7} sm={12} xs={12} style={{ marginBottom: '16px' }}>
          <Typography text="List adverts" size="2" />
        </Col>
      </Row>
      <Row>
        <Col md={12} sm={12} xs={12}>
          <Styled.Wrap>
            <table
              style={{
                width: '100%',
                borderSpacing: '0 ',
                borderCollapse: 'separate',
              }}
            >
              <thead>
                <tr>
                  <td className="item no-wrap">NAME</td>
                  <td className="text-center item no-wrap">PUBLISHED</td>
                  <td className="text-center item no-wrap">ACTIONS</td>
                </tr>
              </thead>
              <tbody>{tableItems()}</tbody>
            </table>
          </Styled.Wrap>
        </Col>
      </Row>
      {sortedData && sortedData.length > 6 ? (
        <Row>
          <Col md={12} sm={12} xs={12}>
            <Styled.PaginationWrap>
              <ReactPaginate
                previousLabel={'<'}
                nextLabel={'>'}
                pageCount={pageCount}
                onPageChange={changePage}
                containerClassName={'paginationBttns'}
                previousLinkClassName={'previousBttn'}
                nextLinkClassName={'nextBttn'}
                disabledClassName={'paginationDisabled'}
                activeClassName={'paginationActive'}
                forcePage={pageNumber}
              />
            </Styled.PaginationWrap>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default ListBanners;
