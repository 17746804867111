import styled from 'styled-components';

const Wrap = styled.div`
  margin-bottom: 16px;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
  background: white;
  flex-direction: column;
`;
const Tab = styled.div`
  background: white;
  min-width: 200px;
  text-align: center;
  padding: 16px;
  border-radius: 16px 16px 0 0;
  margin-right: 16px;
  transition: all 200ms ease-in-out;
  cursor: pointer;
  &.active {
    box-shadow: inset 0 -2px 0 0 rgb(55, 48, 163);
    color: rgb(55, 48, 163);
    &:hover {
      box-shadow: inset 0 -2px 0 0 rgb(55, 48, 163);
      color: rgb(55, 48, 163);
    }
  }
  &:hover {
    transition: all 200ms ease-in-out;
    box-shadow: inset 0 -2px 0 0 rgb(156, 153, 200);
    color: rgb(156, 153, 200);
  }
`;
const TabsContainer = styled.div`
  display: flex;
  padding: 0 24px;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 16px;
`;

const Content = styled.div`
  display: flex;
  height: 100%;
  padding: 16px 32px;
  width: 100%;
  flex: 1 1 100%;
`;
export default { Wrap, Tab, TabsContainer, Content };
