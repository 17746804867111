import axios from 'axios';
import { getMicroServiceUrl } from '../helpers';

export const createCampaign = (regionSlug, data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'CREATE_CAMPAIGN',
      payload: axios.post(
        `${getMicroServiceUrl('sponsor-portal')}campaign/create/${regionSlug}`,
        data
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const updateCampaign = (campaignId, data, onSuccess, onFailed) => {
  return (dispatch) => {
    const response = dispatch({
      type: 'UPDATE_CAMPAIGN',
      payload: axios.post(
        `${getMicroServiceUrl('sponsor-portal')}campaign/update/${campaignId}`,
        data
      ),
    });
    response
      .then(() => {
        onSuccess();
      })
      .catch(() => {
        onFailed();
      });
  };
};

export const getCampaigns = (regionSlug) => {
  return {
    type: 'GET_CAMPAIGNS',
    payload: axios.get(
      `${getMicroServiceUrl('sponsor-portal')}campaign/list/${regionSlug}`
    ),
  };
};

export const getAllCampaigns = () => {
  return {
    type: 'GET_ALL_CAMPAIGNS',
    payload: axios.get(`${getMicroServiceUrl('sponsor-portal')}campaign/list`),
  };
};
