import React from 'react';
import Styled from './CircleProgress.styles';
import { colors } from '../../assets/variable';

const MIN_PERCENTAGE = 0;
const MAX_PERCENTAGE = 100;
const MAX_X = 100;
const MAX_Y = 100;
const FULL_RADIUS = 50;
const CENTER_X = 50;
const CENTER_Y = 50;

interface CircleProgress {
  counterClockwise?: boolean;
  icon?: string;
  strokeWidth?: number;
  theme?: string;
  total?: number;
  value?: number;
}

const CircleProgress = (props: CircleProgress) => {
  const {
    counterClockwise = false,
    icon,
    strokeWidth = 2,
    theme = 'c4',
    total = 0,
    value = 0,
  } = props;

  function getPathDescription(): string {
    const radius = getPathRadius();
    const rotation = counterClockwise ? 1 : 0;

    // Move to center of canvas
    // Relative move to top canvas
    // Relative arc to bottom of canvas
    // Relative arc to top of canvas
    return `
      M ${CENTER_X},${CENTER_Y}
      m 0,-${radius}
      a ${radius},${radius} ${rotation} 1 1 0,${2 * radius}
      a ${radius},${radius} ${rotation} 1 1 0,-${2 * radius}
    `;
  }

  function getPercentage(): number {
    if (total > 0) {
      return (value && value / total) * 100;
    }

    return 0;
  }

  function getPathStyles() {
    const diameter = Math.PI * 2 * getPathRadius();
    const truncatedPercentage = Math.min(
      Math.max(getPercentage(), MIN_PERCENTAGE),
      MAX_PERCENTAGE
    );
    const dashoffset = ((100 - truncatedPercentage) / 100) * diameter;

    return {
      strokeDasharray: `${diameter}px ${diameter}px`,
      strokeDashoffset: `${counterClockwise ? -dashoffset : dashoffset}px`,
    };
  }

  function getPathRadius() {
    // the radius of the path is defined to be in the middle, so in order for the path to
    // fit perfectly inside the 100x100 viewBox, need to subtract half the strokeWidth
    return FULL_RADIUS - strokeWidth / 2 - strokeWidth;
  }

  function getThemeColor() {
    return (
      <path
        stroke={colors[theme]}
        d={pathDescription}
        strokeWidth={5}
        fillOpacity={0}
        style={Object.assign({}, getPathStyles())}
      />
    );
  }

  const pathDescription = getPathDescription();

  return (
    <div
      style={{
        margin: 'auto',
        maxWidth: '300px',
      }}
    >
      <Styled.CircleProgress viewBox={`0 0 ${MAX_X} ${MAX_Y}`}>
        <g>
          <circle cx={CENTER_X} cy={CENTER_Y} r={FULL_RADIUS} fillOpacity={0} />
          <path
            className="trail"
            d={pathDescription}
            strokeWidth={5}
            fillOpacity={0}
          />
          {getThemeColor()}
        </g>
        <g>
          <text x={CENTER_X} y={icon ? CENTER_Y + 25 : CENTER_Y}>
            {getPercentage().toFixed(1)}%
          </text>
        </g>
      </Styled.CircleProgress>
    </div>
  );
};

export default CircleProgress;
