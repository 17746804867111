import * as React from 'react';

const box = (props: any) => {
  const { colorPrimary } = props;

  return (
    <g
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        transform="translate(5.000000, 5.104563)"
        stroke={colorPrimary}
        strokeWidth="5"
      >
        <path
          d="M84.9827482,30.6946791 L29.5238369,1.01190855 C28.2723107,0.347436605 26.8769062,0 25.459857,0 C24.0428527,0 22.6474482,0.347436605 21.3958771,1.01190855 L5.00523978,9.54402111 C3.52738807,10.3538982 2.29099515,11.5415234 1.42233628,12.9856508 C0.553722318,14.4297782 0.0840070682,16.0785865 0.0602774231,17.7636787 C0.0383378131,19.4487261 0.463192113,21.1096589 1.29237874,22.5767781 C2.12156536,24.0438973 3.32535663,25.264663 4.7807105,26.1142818 L59.6558456,57.2341296 C60.9886514,57.9732799 62.4876089,58.3608175 64.0117136,58.3608175 C65.5358184,58.3608175 67.0347758,57.9732799 68.3675816,57.2341296 L85.3869009,47.1752179 C86.8122128,46.3040443 87.9833575,45.0731748 88.7826817,43.6065496 C89.5815569,42.1394753 89.9807699,40.4882869 89.9399056,38.8182382 C89.8990413,37.1481894 89.4194467,35.518511 88.5491712,34.0925705 C87.6788958,32.6666299 86.4489244,31.4947667 84.9827482,30.6946791 L84.9827482,30.6946791 Z"
          id="Path"
        ></path>
        <path d="M4.96024411,30.6946791 L60.4187961,1.01190855 C61.6703223,0.347436605 63.0659963,0 64.4827761,0 C65.9000049,0 67.2952298,0.347436605 68.546756,1.01190855 L84.9373933,9.54402111 C86.4273696,10.3334211 87.680692,11.5043413 88.5698279,12.9370177 C89.4585148,14.3696942 89.9511321,16.0127096 89.996487,17.6981162 C90.0422909,19.3835228 89.6394854,21.0507874 88.8293838,22.5295372 C88.0197312,24.0082421 86.8315223,25.2453086 85.3864518,26.1142818 L30.2871467,57.2341296 C28.9543409,57.9732799 27.4553385,58.3608175 25.9312787,58.3608175 C24.4072188,58.3608175 22.9082165,57.9732799 21.5754107,57.2341296 L4.55609141,47.1752179 C3.13068975,46.3040443 1.95954503,45.0731748 1.16031061,43.6065496 C0.361076197,42.1394753 -0.0380919531,40.4882869 0.00286218719,38.8182382 C0.0438163275,37.1481894 0.523455771,35.518511 1.39364144,34.0925705 C2.2637822,32.6666299 3.4938434,31.4947667 4.96024411,30.6946791 L4.96024411,30.6946791 Z"></path>
        <line
          x1="44.9716309"
          y1="89.7908748"
          x2="44.9716309"
          y2="49.3756048"
        ></line>
        <path d="M80.8963205,51.6208976 L80.8963205,66.7541709 C80.8981116,68.4489179 80.4342341,70.1122308 79.5549774,71.5613428 C78.6761698,73.0104547 77.4156625,74.1901316 75.9117654,74.9719425 L48.968252,88.802946 C47.73379,89.4446507 46.3628143,89.7796484 44.9716309,89.7796484 C43.5804475,89.7796484 42.2094717,89.4446507 40.9750097,88.802946 L14.0314964,74.9719425 C12.5275095,74.1901316 11.2672266,73.0104547 10.3881945,71.5613428 C9.50916238,70.1122308 9.04519508,68.4489179 9.04694147,66.7541709 L9.04694147,51.6208976"></path>
      </g>
    </g>
  );
};
export default box;
