import React from 'react';
import { Icon } from '../../../components';
import Styled from './Profile.style';
import _ from 'lodash';

interface TabProps {
  children: any;
  getKey: string;
  data: any;
  onClickTab: Function;
}

export const Tab: React.FC<TabProps> = (props) => {
  const { children, getKey, data, onClickTab } = props;

  const renderTabs = () => {
    return _.map(data, (el, index) => {
      if (el.isDisplay) {
        return (
          <Styled.NavItem
            className={getKey === el.key ? 'active' : ''}
            key={index}
            onClick={() => {
              onClickTab(el.key);
            }}
          >
            <Styled.IconWrap>
              <Icon icon={el.icon} size={18} colorPrimary={'#fff'} />
            </Styled.IconWrap>
            {el.label}
          </Styled.NavItem>
        );
      } else return null;
    });
  };

  return (
    <Styled.Wrap>
      <Styled.SideNav>{renderTabs()}</Styled.SideNav>
      <Styled.Content>{children}</Styled.Content>
    </Styled.Wrap>
  );
};

export default Tab;
