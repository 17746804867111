import * as _ from 'lodash';
import { CampaignReducer } from '../interfaces';
import { createReducer } from '@reduxjs/toolkit';

export const initialState: CampaignReducer = {
  getCampaign: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  updateCampaign: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
  createCampaign: {
    status: {
      loading: false,
      error: false,
      done: false,
    },
    data: null,
  },
};

const campaignReducer = createReducer(initialState, {
  GET_CAMPAIGNS_FULFILLED: (state, action) => {
    state.getCampaign.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getCampaign.data = _.get(action, 'payload.data');
  },
  GET_CAMPAIGNS_PENDING: (state) => {
    state.getCampaign.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_CAMPAIGNS_REJECTED: (state, action) => {
    state.getCampaign.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  GET_ALL_CAMPAIGNS_FULFILLED: (state, action) => {
    state.getCampaign.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.getCampaign.data = _.get(action, 'payload.data');
  },
  GET_ALL_CAMPAIGNS_PENDING: (state) => {
    state.getCampaign.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  GET_ALL_CAMPAIGNS_REJECTED: (state, action) => {
    state.getCampaign.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  UPDATE_CAMPAIGN_FULFILLED: (state, action) => {
    state.updateCampaign.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.updateCampaign.data = _.get(action, 'payload.data');
  },
  UPDATE_CAMPAIGN_PENDING: (state) => {
    state.updateCampaign.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  UPDATE_CAMPAIGN_REJECTED: (state, action) => {
    state.updateCampaign.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.error'),
      done: false,
    };
  },
  CREATE_CAMPAIGN_FULFILLED: (state, action) => {
    state.createCampaign.status = {
      loading: false,
      error: false,
      done: true,
    };
    state.createCampaign.data = _.get(action, 'payload.data');
  },
  CREATE_CAMPAIGN_PENDING: (state) => {
    state.createCampaign.status = {
      loading: true,
      error: false,
      done: false,
    };
  },
  CREATE_CAMPAIGN_REJECTED: (state, action) => {
    state.createCampaign.status = {
      loading: false,
      error: _.get(action, 'payload.response.data.detail'),
      done: false,
    };
  },
});

export default campaignReducer;
